import {MemberResponse} from "../model/response/MemberResponse";
import {isIterableArray} from "./common";
import {LatLng} from "../model/LatLng";
import {geocodeByAddress, getLatLng} from "react-google-places-autocomplete";


export const getMemberData = (userId: string, projectMembers: MemberResponse[]): MemberResponse => {
    const member = projectMembers.filter(member => member.userId === userId)
    if (isIterableArray(member)) {
        return member[0]
    } else {
        return {
            userId: '',
            name: '',
            phone: '',
            userType: ''
        }
    }
}

export const getAddressLatLng = async (address: string): Promise<LatLng | null | undefined> => {
    try {
        const result = await geocodeByAddress(address)
        const latLng = await getLatLng(result[0])
        return {latitude: latLng.lat, longitude: latLng.lng}
    } catch (err) {
        if (err === 'ZERO_RESULTS' || err === 'INVALID_REQUEST') {
            return null
        }
    }
}
