import React, {useState} from "react"
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {useRecoilState} from "recoil";
import {projectState} from "../atoms";
import {addProjectMember} from "../../../repository/ProjectRepository";
import {notificationAlert} from "../../../util/alert";
import classNames from "classnames";
import Loader from "../../common/loader/Loader";
import {loaderState} from "../../common/loader/atoms";

const ProjectMemberAddDialog: React.FC = () => {

    const [state, setState] = useRecoilState(projectState)
    const [loader, setLoader] = useRecoilState(loaderState)
    const [userPhoneNum, setUserPhoneNum] = useState<string>('')
    const [userAuthority, setUserAuthority] = useState<string>('작업자')

    const handleClose = () => {
        if (!loader.addProjectMember)
            setState(old => ({...old, showAddMemberDialog: !old.showAddMemberDialog}))
    }

    const handleChangePhoneNum = (phoneNum: string) => {
        setUserPhoneNum(phoneNum)
    }

    const handleAddProjectMember = async () => {
        try {
            setLoader(old => ({...old, addProjectMember: true}))

            const result = await addProjectMember({
                projectId: state.projectId,
                phone: userPhoneNum,
                authority: userAuthority
            })
            if (result) {
                setState(old => ({...old, showAddMemberDialog: false}))
                setLoader(old => ({...old, addProjectMember: false}))
                notificationAlert('알림', '프로젝트 멤버가 추가되었습니다.')
            }

        } catch (err: any) {
            if (err) {
                setState(old => ({...old, showAddMemberDialog: false}))
                setLoader(old => ({...old, addProjectMember: false}))
                if (err.message) {
                    notificationAlert('오류', err.message)
                } else {
                    notificationAlert('오류', '프로젝트 멤버 추가에 문제가 발생하였습니다.')
                }

            }
        }
    }


    return (
        <Dialog onClose={handleClose} open={state.showAddMemberDialog}>
            <DialogTitle>프로젝트 멤버 추가</DialogTitle>
            <div className={'add_member_dialog_main'}>
                <div className={'dialog_content'}>
                    <div className={'flex_row_between member_authority_list'}>
                        <span
                            onClick={() => setUserAuthority('관리자')}
                            className={classNames({
                                'active': userAuthority === '관리자'
                            })}>관리자</span>
                        <span
                            onClick={() => setUserAuthority('방제대장')}
                            className={classNames({
                                'active': userAuthority === '방제대장'
                            })}>방제대장</span>
                        <span
                            onClick={() => setUserAuthority('작업자')}
                            className={classNames({
                                'active': userAuthority === '작업자'
                            })}>작업자</span>
                        <span
                            onClick={() => setUserAuthority('관전자')}
                            className={classNames({
                                'active': userAuthority === '관전자'
                            })}>관전자</span>
                    </div>
                    <input
                        placeholder={'추가하실 멤버 전화번호를 입럭하여 주세요.'}
                        onChange={event => handleChangePhoneNum(event.target.value)}/>
                </div>
                <div className={'dialog_button_main'}>
                    {loader.addProjectMember ?
                        <Loader height={45}/>
                        :
                        <>
                            <button onClick={handleAddProjectMember}>추가</button>
                            <button onClick={handleClose}>취소</button>
                        </>
                    }

                </div>
            </div>
        </Dialog>
    )
}


export default ProjectMemberAddDialog