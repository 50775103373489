import {atom, selector} from 'recoil'
import {ProjectResponse} from "../../model/response/ProjectResponse";
import {defaultWorkStatus} from "../../model/WorkStatus";
import {Member} from "../../model/response/Member";
import {Timestamp} from "firebase/firestore";
import {WorkResponse} from "../../model/response/WorkResponse";
import {MemberResponse} from "../../model/response/MemberResponse";
import {v1} from "uuid";
import {CreateWorkRequest} from "../../model/request/CreateWorkRequest";
import {LatLng} from "../../model/LatLng";

interface IChangeMemberAuthority {
    id: string,
    authority: string
}

export interface IWorkerLatLng {
    [index: string]: LatLng | null | undefined
}

interface IAssignWork {
    containWorks: WorkResponse[],
    totalArea: number,
    count: number
}

export const defaultProjectDetail = {
    id: '',
    name: '',
    adminName: '',
    status: '',
    workStatus: defaultWorkStatus,
    memberIds: [],
    members: new Map<string, Member>(),
    created: Timestamp.now(),
    enabled: false,
    addressList: new Map<string, string>()
}

interface IState {
    projectList: ProjectResponse[],
    projectId: string,
    showCreateDialog: boolean,
    showMemberChangeAuthDialog: boolean,
    showAddMemberDialog: boolean,
    showProjectSettingDialog: boolean,
    showAddExcelDialog: boolean,
    showAddProjectWorkDialog: boolean,
    showExcelResultDialog: boolean,
    showAssignWorkDialog: boolean,
    showWorkerInfoDialog: boolean,
    selectWorkerInfo: MemberResponse,
    assignForm: IAssignWork,
    projectSettingType: 'name' | 'status',
    projectDetail: ProjectResponse,
    projectWorks: WorkResponse[],
    filterProjectWorks: WorkResponse[],
    projectMembers: MemberResponse[],
    memberChangeAuthority: IChangeMemberAuthority,
    createWorkForm: CreateWorkRequest,
    workerLatLng: IWorkerLatLng
}

export const projectState = atom<IState>({
    key: `projectState/${v1()}`,
    default: {
        projectList: [],
        projectId: '',
        showCreateDialog: false,
        showMemberChangeAuthDialog: false,
        showAddMemberDialog: false,
        showProjectSettingDialog: false,
        showAddExcelDialog: false,
        showAddProjectWorkDialog: false,
        showExcelResultDialog: false,
        showAssignWorkDialog: false,
        showWorkerInfoDialog: false,
        selectWorkerInfo: {
            userId: '',
            name: '',
            phone: '',
            userType: ''
        },
        assignForm: {
            containWorks: [],
            totalArea: 0,
            count: 0
        },
        projectSettingType: 'name',
        projectDetail: defaultProjectDetail,
        projectWorks: [],
        filterProjectWorks: [],
        projectMembers: [],
        memberChangeAuthority: {
            id: '',
            authority: ''
        },
        createWorkForm: {
            projectId: '',
            address: '',
            farmerName: '',
            farmerPhone: '',
            workType: '드론',
            workStatus: '예정',
            enabled: true,
            uploadFiles: [],
            request: '',
            created: Timestamp.now(),
            cropName: '',
            cropCode: '',
            pnu: '',
        },
        workerLatLng: {}

    }
})


export const projectWorkCountState = selector({
    key: `projectWorkCountState/${v1()}`,
    get: ({get}) => {
        const projectStat = get(projectState).projectDetail.workStatus
        const expectedCount = projectStat.expected.cnt
        const progressCount = projectStat.progress.cnt
        const completeCount = projectStat.complete.cnt
        const stopCount = projectStat.stop.cnt
        const exceptCount = projectStat.except.cnt
        const reworkCount = projectStat.rework.cnt
        const totalCount = expectedCount +
            progressCount +
            completeCount +
            stopCount +
            exceptCount +
            reworkCount

        const expectedArea = projectStat.expected.area
        const progressArea = projectStat.progress.area
        const completeArea = projectStat.complete.area
        const stopArea = projectStat.stop.area
        const exceptArea = projectStat.except.area
        const reworkArea = projectStat.rework.area
        const totalArea = expectedArea + progressArea + completeArea + stopArea + exceptArea + reworkArea


        return {
            totalCount,
            expectedCount,
            progressCount,
            completeCount,
            stopCount,
            exceptCount,
            reworkCount,
            totalArea,
            projectStat
        }
    }
})