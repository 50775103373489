export const isIterableArray = (array: any[]) => Array.isArray(array) && !!array.length;

export const objectToMap = (obj: any) => {
    const keys = Object.keys(obj)
    const map = new Map()
    for(let i = 0; i < keys.length; i++){
        map.set(keys[i], obj[keys[i]])
    }
    return map
};

export const getStatusColor = (status: string) => {
    switch (status) {
        case "예정":
            return "#0cfaec"
        case "진행":
            return "#3886ff"
        case "중단":
            return "#FF4949"
        case "완료":
            return "#75D353"
        case "제외":
            return "#585858"
        case "재작업요청":
            return "#FBD82F"
        case "휴식":
            return "#E052FF"

        default:
            return "#0cfaec"
    }
}

export const getWorkStatusToKor = (status: string) => {
    switch (status) {
        case "expected":
            return "예정"
        case "progress":
            return "진행"
        case "stop":
            return "중단"
        case "except":
            return "제외"
        case "rework":
            return "재작업요청"
        case "complete":
            return "완료"
        default:
            return "예정"
    }
}


export const colorSetList = [
    "#c7d943",
    "#7b1cfd",
    "#ca948a",
    "#7e2f2b",
    "#9a8441",
    "#3182cc",
    "#7ee553",
    "#fc7770",
    "#fd02be",
    "#aef5e6",
    "#68bdc2",
    "#9da3bb",
    "#d48aa8",
    "#1114c4",
    "#62abac",
    "#db993a",
    "#809d85",
    "#6899de",
    "#8dfb5e",
    "#dfb415",
    "#cfc814",
    "#41ccc8",
    "#4caad7",
    "#9ef4a3",
    "#ad8cfd",
    "#2963ce",
    "#e6dd33",
    "#9a1532",
    "#5c69fb",
    "#604788",
    "#1a363a",
    "#e0bc72",
    "#7a8f16",
    "#203d19",
    "#5f7e99",
    "#3fb7d4",
    "#cf5c6c",
    "#cf551",
    "#9b9528",
    "#741c85",
    "#6573a2",
    "#f775ca",
    "#39627d",
    "#bd6240",
    "#49651d",
    "#9828ec",
    "#6f8ddd",
    "#29e5",
    "#1db7c8",
    "#9bc82",
    "#132c4",
    "#46932e",
    "#e88d58",
    "#d2518a",
    "#7ead3a",
    "#5b35b",
    "#f813ea",
    "#44cb97",
    "#bfcffb",
    "#942dd8",
    "#a98318",
    "#ca0153",
    "#b543f2",
    "#442856",
    "#594008",
    "#1eb6ab",
    "#64cbcf",
    "#b08ec8",
    "#c02c3",
    "#f0e6ab",
    "#1a255",
    "#72cd46",
    "#52bfd3",
    "#125d9a",
    "#88752f",
    "#7b835",
    "#ce8e43",
    "#86271f",
    "#7d0e33",
    "#92146a",
    "#2cee27",
    "#66bf53",
    "#7788f2",
    "#469e86",
    "#fd0d22",
    "#c38dcc",
    "#1c2a34",
    "#282cf9",
    "#76ac0a",
    "#c9bd62",
    "#a18cfb"
];


export function extractPercentValue(inputString: string): string | null {
    // 정규식을 사용하여 괄호 안의 내용을 추출
    const regexMatch = inputString.match(/\(([^)]+)\)/);

    if (regexMatch) {
        // 정규식으로 찾은 괄호 안의 내용을 출력
        const extractedValue = regexMatch[1];
        return extractedValue;
    } else {
        return null;
    }
}


export function getCropCode(cropName: string): string  {
    switch (cropName) {
        case "감자":
            return "05010000";
        case "고구마":
            return "05020000";
        case "고추(단고추류포함)":
            return "09060000";
        case "고추(장고추류포함)":
            return "01010000";
        case "벼":
            return "01010000";
        case "기계이앙벼":
            return "01010001";
        case "기계이양벼(어린모)":
            return "01010002";
        case "기계이양벼(어린모)(초중기)":
            return "01010003";
        case "담수직파벼":
            return "01010004";
        case "담수직파벼(표면산파)":
            return "01010005";
        case "벼담수직파":
            return "01010006";
        case "마늘":
            return "12090000";
        case "무":
            return "11010000";
        case "배추":
            return "10010000";
        case "소나무":
            return "30041200";
        case "시금치":
            return "10080000";
        case "양파":
            return "12010000";
        case "옥수수":
            return "04010000";
        case "잣나무":
            return "30041500";
        case "잣나무(비식용)":
            return "30041501";
        case "쪽파":
            return "12030000";
        case "콩":
            return "03010000";
        case "파":
            return "12030000";
        case "파(쪽파포함)":
            return "12030001";
        default:
            return '';
    }
}
