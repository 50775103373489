import React, {useEffect, useState} from "react"
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {useRecoilState} from "recoil";
import {addProjectMember, updateWorkInfo} from "../../../repository/ProjectRepository";
import {notificationAlert} from "../../../util/alert";
import classNames from "classnames";
import {sidebarState} from "../atoms";
import Input from "../Input";
import {loaderState} from "../../common/loader/atoms";
import Loader from "../../common/loader/Loader";

const WorkModifiedDialog: React.FC = () => {

    const [state, setState] = useRecoilState(sidebarState)
    const [loader, setLoader] = useRecoilState(loaderState)

    useEffect(() => {
        setState(old => ({
            ...old,
            updateWorkInfoForm: {
                workId: state.drawerPolygonData.workId,
                farmerName: state.drawerPolygonData.farmerName,
                farmerPhone: state.drawerPolygonData.farmerPhone,
                request: state.drawerPolygonData.request ? state.drawerPolygonData.request : ''
            }
        }))
    }, [state.drawerPolygonData])

    const handleClose = () => {
        if (!loader.workInfoChange)
            setState(old => ({...old, showWorkModifiedDialog: !old.showWorkModifiedDialog}))
    }


    const handleModifyWork = async () => {
        try {
            setLoader(old => ({...old, workInfoChange: true}))
            const result = await updateWorkInfo(state.updateWorkInfoForm)
            if (result) {
                setState(old => ({...old, showWorkModifiedDialog: false, showRightPolygonDetail: false}))
                setLoader(old => ({...old, workInfoChange: false}))
                notificationAlert('알림', '작업이 수정되었습니다.')
            }

        } catch (err: any) {
            if (err) {
                setState(old => ({...old, showWorkModifiedDialog: false, showRightPolygonDetail: false}))
                setLoader(old => ({...old, workInfoChange: false}))
                if (err.message) {
                    notificationAlert('오류', err.message)
                } else {
                    notificationAlert('오류', '작업 수정에 문제가 발생하였습니다.')
                }

            }
        }
    }


    return (
        <Dialog onClose={handleClose} open={state.showWorkModifiedDialog}>
            <DialogTitle>작업 수정</DialogTitle>
            <div className={'work_modify_dialog_main'}>
                <div className={'dialog_content'}>
                    <div className={'work_form'}>
                        <label>주소</label>
                        <Input disable={true} type="text" name="" placeholder=""
                               value={state.drawerPolygonData.address}/>
                    </div>
                    <div className={'work_form'}>
                        <label>농주 이름</label>
                        <Input type="text" name="updateWorkInfoForm.farmerName" placeholder="농주 이름을 입력하여 주세요."
                               value={state.updateWorkInfoForm.farmerName}/>
                    </div>
                    <div className={'work_form'}>
                        <label>농주 전화번호</label>
                        <Input type="text" name="updateWorkInfoForm.farmerPhone" placeholder="농주 전화번호를 입력하여 주세요."
                               value={state.updateWorkInfoForm.farmerPhone}/>
                    </div>
                    <div className={'work_form'}>
                        <label>비고</label>
                        <Input type="text" name="updateWorkInfoForm.request" placeholder="추가 사항 입력"
                               value={state.updateWorkInfoForm.request}/>
                    </div>


                </div>
                <div className={'dialog_button_main'}>
                    {loader.workInfoChange ? <Loader height={45}/> :
                        <>
                            <button onClick={handleModifyWork}>수정</button>
                            <button onClick={handleClose}>취소</button>
                        </>
                    }
                </div>
            </div>
        </Dialog>
    )
}


export default WorkModifiedDialog