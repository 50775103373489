import React, {useEffect, useRef} from "react"
import {Marker} from '@react-google-maps/api';
import workerIcon from '../../../assets/icon/workerIcon.svg'
import {Unsubscribe} from "firebase/firestore";
import {getProjectMemberLocation, LocationResponse} from "../../../repository/ProjectRepository";
import {useRecoilState} from "recoil";
import {IWorkerLatLng, projectState} from "../atoms";
import {isIterableArray} from "../../../util/common";
import {notificationAlert} from "../../../util/alert";
import {sidebarState} from "../../sidebar/atoms";

const WorkersMarker: React.FC = () => {


    const unsub = useRef<Unsubscribe[]>()
    const [state, setState] = useRecoilState(projectState)
    const [sidebar, setSidebar] = useRecoilState(sidebarState)
    useEffect(() => {
        if (isIterableArray(state.projectDetail.memberIds))
            getWorkerLatLng(state.projectDetail.memberIds)
    }, [state.projectDetail])

    useEffect(() => {
        if (sidebar.tabNum === 1) {
            unsub.current?.forEach(sub => sub())
        }

        return () => {
            unsub.current?.forEach(sub => sub())
        }

    }, [sidebar.tabNum])

    const getUpdateWorkerLatLng = (workerLatLng: IWorkerLatLng, locations: LocationResponse) => {
        let updateWorkerLatLng = {...workerLatLng}

        updateWorkerLatLng[locations.id] = locations.latLng
        return {...updateWorkerLatLng}
    }

    const getWorkerLatLng = (memberIds: string[]) => {
        try {
            const unsubscribes = getProjectMemberLocation(memberIds, locations => {
                if (locations.latLng) {

                    setState(old => ({
                        ...old,
                        workerLatLng: getUpdateWorkerLatLng(old.workerLatLng, locations)
                    }))
                }
            })
            unsub.current = unsubscribes


        } catch (err) {
            notificationAlert('오류', '프로젝트 정보를 가져오는데 문제가 발생하였습니다.')
        }
    }


    const handleClickWorkerIcon = (workerId: string) => {
        const workerInfo = state.projectMembers.filter(worker => worker.userId === workerId)

        if (isIterableArray(workerInfo)) {
            setState(old => ({...old, showWorkerInfoDialog: true, selectWorkerInfo: workerInfo[0]}))
        } else {
            notificationAlert('알림', '존재하지 않는 작업자 입니다.')
        }
    }

    return (
        <>
            {Object.keys(state.workerLatLng).map((workerId, index) => {
                if (state.workerLatLng[workerId]) {
                    if (state.workerLatLng[workerId]?.latitude) {
                        return (
                            <Marker
                                key={index}
                                icon={{
                                    url: workerIcon,
                                }}
                                onClick={() => handleClickWorkerIcon(workerId)}
                                position={new google.maps.LatLng({
                                    lat: state.workerLatLng[workerId]!.latitude,
                                    lng: state.workerLatLng[workerId]!.longitude
                                })}/>
                        )
                    }
                }
            })}
        </>
    )
}


export default WorkersMarker