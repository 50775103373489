import React from "react"
import ImageUploading, {ImageListType, ImageType} from "react-images-uploading";
import {isIterableArray} from "../../../util/common";


interface IProps {
    handleChangeImages: (images: File[]) => void
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

const ImageUploader: React.FC<IProps> = ({handleChangeImages}) => {
    const [images, setImages] = React.useState([]);
    const maxNumber = 3;

    const onChange = (
        imageList: ImageListType,
        addUpdateIndex: number[] | undefined
    ) => {

        setImages(imageList as never[]);
        const imageFiles: File[] = imageList.filter(img => notEmpty(img.file)).map(img => img.file) as File[]

        handleChangeImages(imageFiles)
    };

    return (
        <div className="work_status_image_uploader_main">
            <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
            >
                {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps
                  }) => (
                    // write your building UI
                    <div className="upload_image_form">
                        <div className={'upload_img_list'}>
                            {imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                    <img src={image.dataURL} alt="" width="100"/>
                                    <div className="image_item_btn_main">
                                        <button onClick={() => onImageUpdate(index)}>수정</button>
                                        <button onClick={() => onImageRemove(index)}>삭제</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <button
                            className={'add_btn'}
                            onClick={onImageUpload}
                            {...dragProps}
                        >
                            이미지 추가
                        </button>
                    </div>
                )}
            </ImageUploading>
        </div>
    )
}

export default ImageUploader