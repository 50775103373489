import React from "react"
import {useRecoilState, useRecoilValue} from "recoil";
import {sidebarState} from "../sidebar/atoms";
import SpaceBase from "../sidebar/SpaceBase";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import {getStatusColor, isIterableArray} from "../../util/common";
import {projectState} from "./atoms";
import {loaderState} from "../common/loader/atoms";
import Loader from "../common/loader/Loader";

const ProjectList: React.FC = () => {

    const [state, setState] = useRecoilState(sidebarState)
    const loader = useRecoilValue(loaderState)
    const [projectId, setProjectId] = useRecoilState(projectState)

    const handleClickProject = (projectId: string) => {
        setProjectId(old => ({...old, projectId: projectId}))
    }

    const handleOpenCreateProjectDialog = () => {
        setProjectId(old => ({...old, showCreateDialog: !old.showCreateDialog}))
    }

    return (
        <SpaceBase useSearchBar={false} title={'프로젝트 목록'}>
            {loader.projectList ? <Loader height={500}/> :
                <div className={'position-relative'}>
                    <div className={'project_create_btn'}>
                        <button onClick={handleOpenCreateProjectDialog}>프로젝트 생성</button>
                    </div>
                    {isIterableArray(state.projectList) ?
                        <ul className={'project_list_main'}>
                            {state.projectList.map((project, index) => {
                                return (
                                    <li onClick={() => handleClickProject(project.id)} key={index}>
                                        <div
                                            style={{borderLeft: `3px solid ${getStatusColor(project.status)}`}}
                                            className={'project_item'}>
                                            <div>
                                            <span
                                                style={{color: getStatusColor(project.status)}}>[{project.status}]</span>
                                                <span className={'fw-bold'}>{project.name}</span>
                                            </div>
                                            <div className={'project_sub_info'}>
                                                <div>
                                                    <small>
                                                        {project.adminName}
                                                    </small>
                                                </div>
                                                <div>
                                                    <small>
                                                        <PermIdentityIcon/>
                                                        {project.memberIds.length}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                        :
                        <div className={'not_exist_project_list'}>
                            <span>생성된 프로젝트가 존재하지 않습니다.</span>
                        </div>
                    }
                </div>
            }
        </SpaceBase>
    )
}

export default ProjectList