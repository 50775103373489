import React from "react"
import {CircularProgress} from "@mui/material"


export const MapLoader: React.FC = () => {

    return (
        <div className='map_loader_wrapper'>
            <CircularProgress/>
        </div>
    )
}
