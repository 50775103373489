import {firestore, storage} from "../firebase";
import {
    addDoc,
    arrayRemove,
    arrayUnion,
    collection,
    deleteField,
    doc,
    documentId,
    FieldValue,
    getDoc,
    getDocs,
    increment,
    onSnapshot,
    orderBy,
    query,
    Timestamp,
    Unsubscribe,
    updateDoc,
    where,
    writeBatch
} from "firebase/firestore";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {ProjectResponse} from "../model/response/ProjectResponse";
import {User} from "../model/User";
import {defaultWorkStatus} from "../model/WorkStatus";
import {defaultMember, Member} from "model/response/Member";
import {MemberResponse} from "../model/response/MemberResponse";
import {WorkResponse} from "../model/response/WorkResponse";
import {AddMemberRequest} from "../model/request/AddMemberRequest";
import {DeleteMemberRequest} from "../model/request/DeleteMemberRequest";
import {UpdateMemberRequest} from "../model/request/UpdateMemberRequest";
import {WorkRecordResponse} from "../model/response/WorkRecordResponse";
import {CreateWorkRequest} from "../model/request/CreateWorkRequest";
import {polygonToAddress} from "./FunctionRepository";
import {LatLng} from "../model/LatLng";
import {UpdateWorkInfoRequest} from "../model/request/UpdateWorkInfoRequest";
import {UpdateWorkStatusRequest} from "../model/request/UpdateWorkStatusRequest";
import {extractPercentValue, isIterableArray, objectToMap} from "../util/common";
import axios from 'axios'
import {Medicine} from "../model/Medicine";
import {NewMedicine} from "../model/NewMedicine";
import xmlParser from 'xml-parser';
import {SearchMedicine, SearchMedicineResponse} from "../model/response/SearchMedicineResponse";
import {UserDataResponse} from "../model/response/UserDataResponse"; // 'xml-parser'를 'xmlParser'로 이름을 변경

const parse = xmlParser; // 'parse' 함수를 'xmlParser'로 별칭 지정

const USERS_COLLECTION_NAME = "users"
const PROJECT_COLLECTION_NAME = "projects"
const WORK_COLLECTION_NAME = "works"
const RECORD_COLLECTION_NAME = "records"

const getAllPromise = <T>(promises: Promise<T>[]) => Promise.all(promises);


export const getClickAreaAddress = async (lat: number, lng: number) => {
    return await axios.get(`https://dapi.kakao.com/v2/local/geo/coord2address?y=${lat}&x=${lng}`, {
        headers: {
            "Content-Type": 'application/json',
            "Accept": "*/*",
            "Authorization": "KakaoAK b78930a9c4b0868d4fdbd64412323db8"
        }
    });

}


export const getProjectList = (
    userId: string,
    onSuccessListener: (project: ProjectResponse[]) => void
): Unsubscribe => {
    const q = query(
        collection(firestore, PROJECT_COLLECTION_NAME),
        where("enabled", "==", true),
        where("memberIds", "array-contains", userId),
        orderBy('created', 'desc')
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let projects: ProjectResponse[] = [];
        querySnapshot.forEach((doc) => {
            let projectData: ProjectResponse = {id: doc.id, ...doc.data()} as ProjectResponse
            if (objectToMap(projectData.members).get(userId).authority === "관리자") {
                projects.push(projectData)
            }
        });

        onSuccessListener(projects)
    }, (error) => {
        throw new Error('프로젝트 리스트를 가져오는데 문제가 발생하였습니다.')
    });
    return unsubscribe;
}

export const getProjectData = async (projectId: string): Promise<ProjectResponse> => {
    const docRef = doc(firestore, PROJECT_COLLECTION_NAME, projectId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.data() as ProjectResponse
    } else {
        throw new Error('프로젝트 정보를 가져오는데 문제가 발생하였습니다.')
    }
}

export const getProjectWorkDatas = async (projectId: string): Promise<WorkResponse[]> => {
    const q = query(
        collection(firestore, WORK_COLLECTION_NAME),
        where("projectId", "==", projectId),
    );
    const querySnapshot = await getDocs(q);
    let works: WorkResponse[] = [];
    querySnapshot.forEach((doc) => {
        works.push({workId: doc.id, ...doc.data()} as WorkResponse)
    });
    return works
}

export const getProjectDetail = (
    projectId: string,
    onSuccessListener: (project: ProjectResponse) => void
): Unsubscribe => {
    const q = query(
        collection(firestore, PROJECT_COLLECTION_NAME),
        where(documentId(), "==", projectId),
        where("enabled", "==", true),
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let projects: ProjectResponse[] = [];
        querySnapshot.forEach((doc) => {
            projects.push(doc.data() as ProjectResponse)
        });
        onSuccessListener(projects[0])
    }, (error) => {
        throw new Error('프로젝트 리스트를 가져오는데 문제가 발생하였습니다.')
    });
    return unsubscribe;
}

export const checkProjectAdmin = async (
    projectId: string,
    userId: string
): Promise<boolean> =>  {
    const projectCollection = doc(firestore, PROJECT_COLLECTION_NAME, projectId);
    const projectDoc = await getDoc(projectCollection)
    if (projectDoc.exists()) {
        // console.log(projectDoc.data());
        const project = {id: projectDoc.id, ...projectDoc.data()} as ProjectResponse
        if(objectToMap(project.members).get(userId)?.authority === "관리자") {
            return true;
        }else {
            return false;
        }
        // const user = {userId: userDoc.id, ...userDoc.data()} as UserDataResponse
        // return user
    } else {
        throw Error('유저 정보를 가져오지 못했습니다.')
    }
}

export const getProjectWorks = (
    projectId: string,
    onSuccessListener: (works: WorkResponse[]) => void
): Unsubscribe => {
    const q = query(
        collection(firestore, WORK_COLLECTION_NAME),
        where("projectId", "==", projectId),
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let works: WorkResponse[] = [];
        querySnapshot.forEach((doc) => {
            const work: WorkResponse = {
                workId: doc.id,
                ...doc.data()
            } as WorkResponse
            works.push(work)
        });
        onSuccessListener(works)
    }, (error) => {
        throw new Error('작업 목록를 가져오는데 문제가 발생하였습니다.')
    });

    return unsubscribe;
}

const getProjectMember = async (memberId: string): Promise<MemberResponse | null | undefined> => {
    const userSnap = await getDoc(doc(firestore, USERS_COLLECTION_NAME, memberId))
    if (userSnap.exists()) {
        const user = userSnap.data() as User
        return {
            name: user.name,
            phone: user.phone,
            userId: userSnap.id,
            userType: user.userType
        }
    }
}

export const getProjectMembers = async (
    memberIds: string[]
): Promise<MemberResponse[]> => {
    const memberResponse: MemberResponse[] = []

    const memberDatas = await getAllPromise(memberIds.map(id => getProjectMember(id)))
    memberDatas.forEach(row => row && memberResponse.push(row))

    return memberResponse;
}


export const createProject = async (
    projectName: string,
    userId: string
): Promise<boolean> => {

    const userRef = doc(firestore, USERS_COLLECTION_NAME, userId)
    const userSnap = await getDoc(userRef)

    if (userSnap.exists()) {

        const user = {id: userSnap.id, ...userSnap.data()} as User

        const members = new Map<string, Member>()
        members.set(userId, defaultMember('관리자'))

        await addDoc(collection(firestore, PROJECT_COLLECTION_NAME), {
            name: projectName,
            adminName: user.name,
            enabled: true,
            status: '진행',
            memberIds: [user.id],
            members: Object.fromEntries(members),
            workStatus: defaultWorkStatus,
            created: Timestamp.now(),
            addressList: {}
        });

        return true;
    } else {
        throw Error('존재하지 않는 유저입니다.')
    }
}

export const deleteProject = async (
    projectId: string
): Promise<boolean> => {
    const projectRef = doc(firestore, PROJECT_COLLECTION_NAME, projectId)
    await updateDoc(projectRef, {
        enabled: false
    });
    return true;
}


export const updateProjectStatus = async (
    projectId: string,
    status: string
): Promise<boolean> => {
    const projectRef = doc(firestore, PROJECT_COLLECTION_NAME, projectId)
    await updateDoc(projectRef, {
        status: status
    });
    return true
}

export const updateProjectName = async (
    projectId: string,
    name: string
): Promise<boolean> => {
    const projectRef = doc(firestore, PROJECT_COLLECTION_NAME, projectId)
    await updateDoc(projectRef, {
        name: name
    });
    return true
}


export const addProjectMember = async (
    request: AddMemberRequest
): Promise<boolean> => {
    const userQuery = query(collection(firestore, USERS_COLLECTION_NAME), where('phone', '==', request.phone))
    const projectRef = doc(firestore, PROJECT_COLLECTION_NAME, request.projectId)
    const projectDoc = await getDoc(projectRef)
    const userSnap = await getDocs(userQuery)
    const userDoc = userSnap.docs[0]

    if (!isIterableArray(userSnap.docs)) {
        throw Error('존재하지 않는 유저입니다.')
    }

    if (!projectDoc.exists()) {
        throw Error('존재하지 않는 프로젝트입니다.')
    }

    const user: User = {
        id: userDoc.id,
        ...userDoc.data()
    } as User
    const project = projectDoc.data() as ProjectResponse

    if (project.members.hasOwnProperty(user.id)) {
        throw Error('이미 프로젝트 멤버로 등록되어있습니다.')
    }

    let memberObj: { [key in string]: Member } = {};
    memberObj[`members.${user.id}`] = defaultMember(request.authority)

    await updateDoc(projectRef, {
        ...memberObj,
        "memberIds": arrayUnion(user.id)
    });

    return true;
}


export const deleteProjectMember = async (
    request: DeleteMemberRequest
): Promise<boolean> => {
    const batch = writeBatch(firestore);
    const projectRef = doc(firestore, PROJECT_COLLECTION_NAME, request.projectId)
    const projectDoc = await getDoc(projectRef)

    if (!projectDoc.exists()) {
        throw Error('존재하지 않는 프로젝트입니다.')
    }

    const project = projectDoc.data() as ProjectResponse

    const workQuery = query(
        collection(firestore, WORK_COLLECTION_NAME),
        where('projectId', '==', request.projectId),
        where('workerId', '==', request.userId)
    )
    const workDocs = await getDocs(workQuery)
    const works: WorkResponse[] = workDocs.docs.map(doc => {
        return {
            workId: doc.id,
            ...doc.data()
        } as WorkResponse
    })

    if (!project.members.hasOwnProperty(request.userId)) {
        throw Error('존재하지 않는 유저입니다.')
    }

    for (const work of works) {
        const workRef = doc(firestore, "works", work.workId);
        if (work.workStatus === '진행') {
            batch.update(workRef, {
                'worker': null,
                'workerId': null,
                'workStatus': '예정',
                'workType': '드론',
                'uploadFiles': []
            })

            batch.update(projectRef, {
                'workStatus.progress.cnt': increment(-1),
                'workStatus.progress.area': increment(work.area * -1),
                'workStatus.expected.cnt': increment(1),
                'workStatus.expected.area': increment(work.area)
            })

        } else {
            batch.update(workRef, {
                "workerId": null
            })
        }
    }


    let memberObj: { [key in string]: FieldValue } = {};
    memberObj[`members.${request.userId}`] = deleteField()
    batch.update(projectRef, {
        ...memberObj,
        "memberIds": arrayRemove(request.userId)
    })

    await batch.commit()

    return true
}

export const updateMemberAuthority = async (
    request: UpdateMemberRequest
): Promise<boolean> => {
    const projectRef = doc(firestore, PROJECT_COLLECTION_NAME, request.projectId)
    const projectDoc = await getDoc(projectRef)

    if (!projectDoc.exists()) {
        throw Error('존재하지 않는 프로젝트입니다.')
    }

    let memberObj: { [key in string]: string } = {};
    memberObj[`members.${request.userId}.authority`] = request.authority
    await updateDoc(projectRef, {
        ...memberObj,
    });

    return true
}


export const getWorkRecord = async (
    workId: string
): Promise<WorkRecordResponse[]> => {
    const recordCollection = collection(firestore, WORK_COLLECTION_NAME, workId, RECORD_COLLECTION_NAME)
    const recordQuery = query(recordCollection, orderBy('createTime', 'desc'))
    const recordDocs = await getDocs(recordQuery)
    const records: WorkRecordResponse[] = recordDocs.docs.map(doc => {
        return {
            recordId: doc.id,
            ...doc.data()
        } as WorkRecordResponse
    })

    for (const record of records) {
        let fileUrls = []
        for (const imageName of record.imageList) {
            const storageRef = ref(storage, `work/${imageName}`)
            const url = await getDownloadURL(storageRef)
            fileUrls.push(url)
        }
        record.imageList = fileUrls
    }

    return records
}


export const createWork = async (
    request: CreateWorkRequest
): Promise<boolean> => {
    const batch = writeBatch(firestore)
    const polygonResult = await polygonToAddress(request.address, request.latLng)
    const worksQuery = query(
        collection(firestore, WORK_COLLECTION_NAME),
        where('projectId', '==', request.projectId),
        where('latLng', '==', polygonResult.latLng)
    )
    const workDocs = await getDocs(worksQuery)

    if (!workDocs.empty) {
        throw Error('이미 등록된 주소입니다.')
    }

    const polygonList: LatLng[] = []
    const areaPolygon: google.maps.LatLng[] = []

    for (const polygon of polygonResult.polygon) {
        areaPolygon.push(new google.maps.LatLng({lat: polygon[1], lng: polygon[0]}))
        polygonList.push({latitude: polygon[1], longitude: polygon[0]})
    }

    let area = window.google.maps.geometry.spherical.computeArea(areaPolygon)
    area = area * 0.3025

    const fixedArea = parseFloat(area.toFixed(2))

    const storeRequest = {
        projectId: request.projectId,
        address: request.address,
        farmerName: request.farmerName,
        farmerPhone: request.farmerPhone,
        workerId: null,
        worker: null,
        workType: "드론",
        workStatus: "예정",
        request: request.request,
        latLng: polygonResult.latLng,
        area: fixedArea,
        enabled: true,
        polygon: polygonList,
        uploadFiles: [],
        created: request.created,
        cropCode: request.cropCode,
        cropName: request.cropName,
        pnu: polygonResult.pnu
    }

    const workRef = doc(collection(firestore, WORK_COLLECTION_NAME))
    batch.set(workRef, storeRequest)

    let memberObj: { [key in string]: string } = {}
    memberObj[`addressList.${workRef.id}`] = request.address.replace(/ /g, "")

    batch.update(doc(firestore, PROJECT_COLLECTION_NAME, request.projectId), {
        ...memberObj,
        "workStatus.expected.area": increment(fixedArea),
        "workStatus.expected.cnt": increment(1)
    })
    await batch.commit()

    return true
}

export const deleteWork = async (
    projectId: string,
    work: WorkResponse
): Promise<boolean> => {
    const batch = writeBatch(firestore)
    const projectRef = doc(firestore, PROJECT_COLLECTION_NAME, projectId)
    const workRef = doc(firestore, WORK_COLLECTION_NAME, work.workId)
    const recordRef = doc(collection(firestore, WORK_COLLECTION_NAME, work.workId, RECORD_COLLECTION_NAME))

    const area = work.area * -1
    const status = workStatusToEng(work.workStatus)

    if (work.workerId) {
        let workerObj: { [key in string]: FieldValue } = {}
        workerObj[`members.${work.workerId}.status.${status}.area`] = increment(area)
        workerObj[`members.${work.workerId}.status.${status}.cnt`] = increment(-1)
        batch.update(projectRef, {
            ...workerObj
        })
    }


    let memberObj: { [key in string]: FieldValue } = {}
    memberObj[`workStatus.${status}.cnt`] = increment(-1)
    memberObj[`workStatus.${status}.area`] = increment(area)
    memberObj[`addressList.${work.workId}`] = deleteField()

    batch.update(projectRef, {
        ...memberObj
    })
    batch.delete(recordRef)
    batch.delete(workRef)

    await batch.commit()

    return true

}

export const updateWorkInfo = async (
    request: UpdateWorkInfoRequest
): Promise<boolean> => {
    const workRef = doc(firestore, WORK_COLLECTION_NAME, request.workId)

    if (!request.request) {
        request.request = null
    }

    await updateDoc(workRef, {
        'farmerName': request.farmerName,
        'farmerPhone': request.farmerPhone,
        'request': request.request
    })

    return true
}

export const updateWorkStatus = async (
    request: UpdateWorkStatusRequest
): Promise<boolean> => {
    const batch = writeBatch(firestore)
    const projectRef = doc(firestore, PROJECT_COLLECTION_NAME, request.projectId)
    const projectRecordRef = doc(collection(firestore, PROJECT_COLLECTION_NAME, request.projectId, RECORD_COLLECTION_NAME))
    const workRef = doc(firestore, WORK_COLLECTION_NAME, request.workId)
    const recordRef = doc(collection(firestore, RECORD_COLLECTION_NAME))
    const workRecordRef = doc(collection(workRef, RECORD_COLLECTION_NAME));

    const preProjectSnap = await getDoc(projectRef)
    if (!preProjectSnap.exists()) throw Error('존재하지 않는 프로젝트입니다.')
    const preProject = preProjectSnap.data() as ProjectResponse

    const preWorkSnap = await getDoc(workRef)
    if (!preWorkSnap.exists()) throw Error('존재하지 않는 작업입니다.')
    const preWork = preWorkSnap.data() as WorkResponse

    const preArea = preWork.area

    const preStatus = workStatusToEng(preWork.workStatus)
    const newStatus = workStatusToEng(request.workStatus)

    if (preStatus === newStatus && request.workStatus === "예정") {
        throw Error('이미 예정된 작업입니다.')
    }

    const uploadFiles: string[] = []

    for (const image of request.images) {
        const fileName = `${request.workId}-${Date.now()}`
        const storageRef = ref(storage, fileName)
        uploadFiles.push(fileName)
        await uploadBytes(storageRef, image)
    }


    if (!isNullOrEmpty(preWork.workerId)) {
        if (preWork.workerId) {
            if (preProject.memberIds.includes(preWork.workerId)) {
                let checkObj: { [key in string]: FieldValue } = {}
                checkObj[`members.${preWork.workerId}.status.${preStatus}.cnt`] = increment(-1)
                checkObj[`members.${preWork.workerId}.status.${preStatus}.area`] = increment(preArea * -1)
                batch.update(projectRef, {...checkObj})
            }
        }
    }

    if (request.workStatus === "예정") {
        batch.update(workRef, {
            worker: null,
            workerId: null,
            workStatus: "예정",
            workType: "드론",
            uploadFiles: [],
            updated: null
        })
    } else {
        let projectObj: { [key in string]: FieldValue } = {}
        projectObj[`members.${request.workerId}.status.${newStatus}.cnt`] = increment(1)
        projectObj[`members.${request.workerId}.status.${newStatus}.area`] = increment(preArea)
        batch.update(projectRef, {...projectObj})
        batch.update(workRef, {
            worker: request.worker,
            workerId: request.workerId,
            workStatus: request.workStatus,
            workType: request.workType,
            uploadFiles: uploadFiles,
            updated: Timestamp.now()
        })
    }

    let projectUpdateObj: { [key in string]: FieldValue } = {}
    projectUpdateObj[`workStatus.${newStatus}.cnt`] = increment(1)
    projectUpdateObj[`workStatus.${newStatus}.area`] = increment(preArea)
    projectUpdateObj[`workStatus.${preStatus}.cnt`] = increment(-1)
    projectUpdateObj[`workStatus.${preStatus}.area`] = increment(preArea * -1)
    batch.update(projectRef, {...projectUpdateObj})

    const projectRecord = {
        address: preWork.address,
        area: preArea,
        farmerName: preWork.farmerName,
        farmerPhone: preWork.farmerPhone,
        worker: request.worker,
        workType: request.workType,
        status: request.workStatus,
        request: request.reason,
        images: uploadFiles
    }

    batch.set(projectRecordRef, projectRecord)
    batch.set(recordRef, {
        workerId: request.workerId,
        workStatus: request.workStatus,
        workType: request.workType,
        reason: request.reason,
        worker: request.worker,
        imageList: uploadFiles,
        createTime: Timestamp.now()
    })

    const result = await batch.set(workRecordRef, {
        workerId: request.workerId,
        workStatus: request.workStatus,
        workType: request.workType,
        reason: request.reason,
        worker: request.worker,
        imageList: uploadFiles,
        createTime: Timestamp.now()
    });

    await batch.commit();


    return true
}

export const updateWorkStatusAssignment = async (
    userId: string,
    worker: string,
    projectId: string,
    works: WorkResponse[]
): Promise<boolean> => {
    const batch = writeBatch(firestore)
    const projectRef = doc(firestore, PROJECT_COLLECTION_NAME, projectId)

    for (const work of works) {
        const workRef = doc(firestore, WORK_COLLECTION_NAME, work.workId)
        if (work.workStatus === "예정") {
            batch.update(workRef, {
                'worker': worker,
                'workerId': userId
            })

        } else {
            let workerObj: { [key in string]: FieldValue } = {}
            workerObj[`members.${userId}.status.expected.area`] = increment(work.area)
            workerObj[`members.${userId}.status.expected.cnt`] = increment(1)
            workerObj[`members.${userId}.status.${workStatusToEng(work.workStatus)}.cnt`] = increment(-1)
            workerObj[`members.${userId}.status.${workStatusToEng(work.workStatus)}.area`] = increment(work.area * -1)

            workerObj[`workStatus.expected.cnt`] = increment(1)
            workerObj[`workStatus.expected.area`] = increment(work.area)
            workerObj[`workStatus.${workStatusToEng(work.workStatus)}.cnt`] = increment(-1)
            workerObj[`workStatus.${workStatusToEng(work.workStatus)}.area`] = increment(work.area * -1)

            batch.update(projectRef, {
                ...workerObj
            })

            batch.update(workRef, {
                'workStatus': '예정',
                'workerId': userId,
                'worker': worker
            })
        }


    }

    await batch.commit()

    return true
}


export const getProjectMemberLocation = (
    memberIds: string[],
    onUpdateListener: (locations: LocationResponse) => void
): Unsubscribe[] => {
    const unsubscribes: Unsubscribe[] = []

    for (const memberId of memberIds) {
        const q = query(
            collection(firestore, USERS_COLLECTION_NAME),
            where(documentId(), "==", memberId),
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                onUpdateListener({id: memberId, latLng: doc.data().latLng as LatLng})
            });
        }, (error) => {
            throw new Error('유저 위치를 가져오는데 문제가 발생하였습니다.')
        });

        unsubscribes.push(unsubscribe)
    }

    return unsubscribes

}

export const checkProjectMedicines = async (
    projectId: string,
    uuid: string,
): Promise<boolean> => {

    const projectRef = doc(firestore, PROJECT_COLLECTION_NAME, projectId)
    const projectSnapshot = await getDoc(projectRef)
    const project = projectSnapshot.data()
    if (!project) return false;
    if (project.enabled === false) return false;

    const medicineRef = doc(firestore, "medicine", "medicineUUID");
    const medicineUUID = await getDoc(medicineRef)
    const medicine = medicineUUID.data()
    if (medicine?.uuid !== uuid) return false;

    return true;
}

export const getMedicineUUID = async (): Promise<string> => {
    const medicineRef = doc(firestore, "medicine", "medicineUUID");
    const medicineUUID = await getDoc(medicineRef)
    const medicine = medicineUUID.data()
    return medicine?.uuid || '';

}

export const getProjectNewMedicines = async (
    projectId: string
): Promise<NewMedicine[]> => {
    const projectRef = doc(firestore, PROJECT_COLLECTION_NAME, projectId)
    const projectSnapshot = await getDoc(projectRef)
    const project = projectSnapshot.data()
    const medicines = project?.newMedicines as NewMedicine[]
    return medicines;
}

export const createProjectNewMedicine = async (
    projectId: string,
    amount: number,
    bottle: number,
    medicine: SearchMedicine
): Promise<boolean> => {
    const insertData = {
        code: medicine.pestiCode + '-' + medicine.diseaseUseSeq,
        name: medicine.pestiKorName,
        amount: amount,
        bottle: bottle,
        reg_cpnt: extractPercentValue(medicine.engName),
    }
    const projectRef = doc(firestore, PROJECT_COLLECTION_NAME, projectId)
    const projectSnapshot = await getDoc(projectRef)
    const project = projectSnapshot.data()
    const medicines = project?.newMedicines as NewMedicine[]
    const isExist = medicines?.find(m => m.code === insertData.code)
    if (isExist) {
        throw new Error('이미 등록된 농약입니다.')
    }

    await updateDoc(projectRef, {
        newMedicines: arrayUnion(insertData)
    });
    return true;
}

export const updateProjectNewMedicine = async (
    projectId: string,
    medicines: NewMedicine
): Promise<boolean> => {
    const projectRef = doc(firestore, PROJECT_COLLECTION_NAME, projectId)
    await updateDoc(projectRef, {
        newMedicines: medicines
    });
    return true;
}

export const deleteProjectNewMedicine = async (
    projectId: string,
    medicine: NewMedicine
): Promise<boolean> => {
    const projectRef = doc(firestore, PROJECT_COLLECTION_NAME, projectId)
    await updateDoc(projectRef, {
        newMedicines: arrayRemove(medicine)
    });
    return true;
}

export const searchMedicine = async (
    page: number,
    name?: string
): Promise<SearchMedicineResponse> => {

    const apiKey = "20231019UFA5N7WSE2CUBQOM6H5E7W";
    const displayCount = 50; // 한 페이지에 보여질 결과 수
    const startPoint = (page - 1) * displayCount + 1; // 시작 위치 계산

    const response = await axios.get(`https://proxy2.datacredit.kr/openApi/service.do?apiKey=${apiKey}&serviceCode=SVC01&serviceType=Ajax&pestiBrandName=${name}&startPoint=${startPoint}&displayCount=${displayCount}&diseaseWeedName=무인항공&similarFlag=Y`, {
        headers: {
            // "Content-Type": 'text/xml; charset=UTF-8',
            // "Accept": "*/*",
        }
    });

    const xmlData = response.data;
    const parsedData = parse(xmlData);
    const items: SearchMedicine[] = [];

    const totalElements = parsedData.root.children.filter((child) => child.name === "totalCount")
    const itemElements = parsedData.root.children.filter((child) => child.name === 'list');
    itemElements.forEach((itemElement) => {
        const item: { [key: string]: string } = {};

        itemElement.children.forEach((child) => {
            child.children.forEach((child2) => {
                if (child2.content) {
                    item[child2.name] = child2.content;
                }
            });
            items.push(item as SearchMedicine);
        });
    });


    return {
        total: Number(totalElements[0].content),
        list: items,
    }

}


// export const createProjectMedicine = async (
//     projectId: string,
//     medicine: Medicine
// ): Promise<boolean> => {
//     const projectRef = doc(firestore, PROJECT_COLLECTION_NAME, projectId)
//     await updateDoc(projectRef, {
//         medicines: arrayUnion(medicine)
//     })
//     return true
// }
//
// export const updateProjectMedicine = async (
//     projectId: string,
//     medicines: Medicine[]
// ): Promise<boolean> => {
//     const projectRef = doc(firestore, PROJECT_COLLECTION_NAME, projectId)
//     await updateDoc(projectRef, {
//         medicines: medicines,
//     })
//     return true
// }
//
// export const deleteProjectMedicine = async (
//     projectId: string,
//     medicine: Medicine
// ): Promise<boolean> => {
//     const projectRef = doc(firestore, PROJECT_COLLECTION_NAME, projectId)
//     await updateDoc(projectRef, {
//         medicines: arrayRemove(medicine)
//     })
//     return true
// }


export type LocationResponse = {
    id: string
    latLng: LatLng
}


const isNullOrEmpty = (str?: string): boolean => {
    let returnValue = false
    if (!str) returnValue = true
    if (str === null) returnValue = true
    if (str === undefined) returnValue = true
    return returnValue
}

const workStatusToEng = (status?: string): string => {
    switch (status) {
        case "진행" :
            return "progress"
        case "예정" :
            return "expected"
        case "중단" :
            return "stop"
        case "완료" :
            return "complete"
        case "제외" :
            return "except"
        case "재작업요청" :
            return "rework"
        case null :
            return "expected"
        case undefined:
            return "expected"
        default:
            throw Error('상태를 확인해주세요.')
    }
}


export const sendAgreePwd = async (post: any) => {
    return await axios.post(`https://asia-northeast3-bluesky-d6001.cloudfunctions.net/payment2`, post, {
        headers: {
            "Content-Type": 'application/json',
            "Accept": "*/*"
        }
    });

}
