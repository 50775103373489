import React, {useCallback, useEffect} from "react";
import {Marker, MarkerClusterer, Polygon,} from '@react-google-maps/api';
import {colorSetList, getStatusColor, isIterableArray} from "../../../util/common";
import {useRecoilState} from "recoil";
import {projectState} from "../atoms";
import {WorkResponse} from "../../../model/response/WorkResponse";
import {sidebarState} from "../../sidebar/atoms";
import {Clusterer} from "@react-google-maps/marker-clusterer";
import {mapState} from "../../main/atoms";


const WorksPolygon = () => {

    const [project, setProject] = useRecoilState(projectState)
    const [sidebar, setSidebar] = useRecoilState(sidebarState)
    const [map, setMap] = useRecoilState(mapState)

    const handleDrawer = (work: WorkResponse) => {
        setSidebar(old => ({
            ...old,
            showRightPolygonDetail: true,
            showRightPolygonSearch: false,
            drawerPolygonData: work
        }))
        setMap(old => ({
            ...old,
            center: {lat: work.latLng.latitude, lng: work.latLng.longitude},
            zoom: 18
        }))
    }

    return (
        <>
            {isIterableArray(project.projectWorks) &&
                project.filterProjectWorks.map((work, index) => {
                    let colorIndex = -1
                    const workPath = work.polygon.map(userPath => ({lat: userPath.latitude, lng: userPath.longitude}))
                    colorIndex = project.projectMembers.findIndex(user => user.userId === work.workerId);

                    return (
                        <Polygon
                            key={index}
                            onClick={() => handleDrawer(work)}
                            path={workPath}
                            options={
                                {
                                    fillColor: sidebar.activeWorkMode ? (colorIndex === -1 ? 'red' : colorSetList[colorIndex]) : getStatusColor(work.workStatus),
                                    strokeColor: sidebar.activeWorkMode ? 'white' : (work.workStatus === '예정' ? getStatusColor(work.workStatus) : 'white'),
                                    strokeWeight: (sidebar.drawerPolygonData.latLng === work.latLng) ? 7 : 3,
                                    fillOpacity: sidebar.activeWorkMode ? 1 : (work.workStatus === '예정' ? 0 : (sidebar.drawerPolygonData.latLng === work.latLng) ? 1 : 0.7)
                                }
                            }/>
                    )
                })

            }
            {/*{isIterableArray(project.filterProjectWorks) &&*/}
            {/*    <MarkerClusterer*/}
            {/*        maxZoom={13}>*/}
            {/*        {(markerCluster) => ClusterMarkers(project.filterProjectWorks, markerCluster)*/}
            {/*        }*/}
            {/*    </MarkerClusterer>*/}
            {/*}*/}
            {isIterableArray(sidebar.searchPolygonData.polygon) &&
                <Polygon
                    // onClick={() => handleDrawer(work)}
                    path={sidebar.searchPolygonData.polygon.map(py => ({lat: py.latitude, lng: py.longitude}))}
                    options={
                        {
                            fillColor: '#0cfaec',
                            strokeColor: 'white',
                            strokeWeight: 5,
                            fillOpacity: 1
                        }
                    }/>
            }
        </>
    )
}


const ClusterMarkers = (works: WorkResponse[], cluster: Clusterer): JSX.Element => {

    return <div>{
        works.map((work, index) =>
            <Marker

                key={index}
                clusterer={cluster}
                position={{lat: work.latLng.latitude, lng: work.latLng.longitude}}
                visible={false}/>
        )
    }
    </div>
}

export default React.memo(WorksPolygon)



