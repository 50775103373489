import React, {useEffect} from "react"
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {useRecoilState, useRecoilValue} from "recoil";
import {notificationAlert} from "../../../util/alert";
import {projectState} from "../atoms";
import {Debounce} from "../Debounce";
import {geocodeByAddress, getLatLng} from 'react-google-places-autocomplete';
import {LatLng} from "../../../model/LatLng";
import Input from "../Input";
import {createWork} from "../../../repository/ProjectRepository";
import {getAddressLatLng} from "../../../util/project";
import {sidebarState} from "../../sidebar/atoms";
import {loaderState} from "../../common/loader/atoms";
import Loader from "../../common/loader/Loader";
import {Select} from "@mui/material";
import {getCropCode} from "../../../util/common";


const ProjectWorkAddDialog: React.FC = () => {

    const [state, setState] = useRecoilState(projectState)
    const [loader, setLoader] = useRecoilState(loaderState)
    const sidebar = useRecoilValue(sidebarState)


    useEffect(() => {
        if (state.showAddProjectWorkDialog && !sidebar.showRightPolygonSearch) {
            setState(old => ({
                ...old,
                createWorkForm: {
                    ...old.createWorkForm,
                    projectId: state.projectId,
                    address: '',
                    farmerPhone: '',
                    farmerName: '',
                    request: '',
                    workStatus: ''
                }
            }))
        } else {
            setState(old => ({
                ...old,
                createWorkForm: {
                    ...old.createWorkForm,
                    projectId: state.projectId,
                }
            }))
        }
    }, [state.showAddProjectWorkDialog])

    const handleClose = () => {
        if (!loader.workAdd)
            setState(old => ({...old, showAddProjectWorkDialog: !old.showAddProjectWorkDialog}))
    }


    const handleSearchAddress = async (address: string) => {
        const latLng = await getAddressLatLng(address)
        if (latLng) {
            setState(old => ({...old, createWorkForm: {...old.createWorkForm, address: address, latLng: latLng}}))
        }

        return true
    }

    const handleCreateProjectWork = async () => {
        try {
            setLoader(old => ({...old, workAdd: true}))
            const result = await createWork(state.createWorkForm)
            if (result) {
                setState(old => ({...old, showAddProjectWorkDialog: false}))
                setLoader(old => ({...old, workAdd: false}))
                notificationAlert('알림', '작업이 추가되었습니다.')
            }
        } catch (err: any) {
            if (err) {

                setState(old => ({...old, showAddProjectWorkDialog: false}))
                setLoader(old => ({...old, workAdd: false}))
                if (err.message) {
                    notificationAlert('알림', err.message)
                } else {
                    notificationAlert('알림', '작업 추가에 문제가 발생하였습니다.')
                }
            }


        }
    }


    return (
        <Dialog onClose={handleClose} open={state.showAddProjectWorkDialog}>
            <DialogTitle>작업 추가</DialogTitle>
            <div className={'work_add_dialog_main'}>
                <div className={'dialog_content'}>
                    <div>
                        <label>작업 주소</label>
                        <Debounce type={'form'}
                                  handleSearchLocation={handleSearchAddress}/>
                    </div>
                    <div className={'work_form'}>
                        <label>농주 이름</label>
                        <Input type="text" name="createWorkForm.farmerName" placeholder="농주 이름을 입력하여 주세요."
                               value={state.createWorkForm.farmerName}/>
                    </div>
                    <div className={'work_form'}>
                        <label>농주 전화번호</label>
                        <Input type="text" name="createWorkForm.farmerPhone" placeholder="농주 전화번호를 입력하여 주세요."
                               value={state.createWorkForm.farmerPhone}/>
                    </div>
                    <div className={'work_form'}>
                        <label>농작물명</label>
                        <select value={state.createWorkForm.cropName} onChange={e => {
                            if (e.target.value !== '')
                                setState({
                                    ...state, createWorkForm: {
                                        ...state.createWorkForm,
                                        cropName: e.target.value,
                                        cropCode: getCropCode(e.target.value)
                                    }
                                })
                        }} id="cropSelect">
                            <option value={''}>농작물을 선택하여 주세요.</option>
                            <option value="감자">감자</option>
                            <option value="고구마">고구마</option>
                            <option value="고추(단고추류포함)">고추(단고추류포함)</option>
                            <option value="고추(장고추류포함)">고추(장고추류포함)</option>
                            <option value="벼">벼</option>
                            <option value="기계이앙벼">기계이앙벼</option>
                            <option value="기계이양벼(어린모)">기계이양벼(어린모)</option>
                            <option value="기계이양벼(어린모)(초중기)">기계이양벼(어린모)(초중기)</option>
                            <option value="담수직파벼">담수직파벼</option>
                            <option value="담수직파벼(표면산파)">담수직파벼(표면산파)</option>
                            <option value="벼담수직파">벼담수직파</option>
                            <option value="마늘">마늘</option>
                            <option value="무">무</option>
                            <option value="배추">배추</option>
                            <option value="소나무">소나무</option>
                            <option value="시금치">시금치</option>
                            <option value="양파">양파</option>
                            <option value="옥수수">옥수수</option>
                            <option value="잣나무">잣나무</option>
                            <option value="잣나무(비식용)">잣나무(비식용)</option>
                            <option value="쪽파">쪽파</option>
                            <option value="콩">콩</option>
                            <option value="파">파</option>
                            <option value="파(쪽파포함)">파(쪽파포함)</option>
                        </select>
                    </div>
                    <div className={'work_form'}>
                        <label>요청사항</label>
                        <Input type="text" name="createWorkForm.request" placeholder="요청사항을 입력하여 주세요."
                               value={state.createWorkForm.request}/>
                    </div>


                </div>
                <div className={'dialog_button_main'}>
                    {loader.workAdd ?
                        <Loader height={45}/>
                        :
                        <>
                            <button onClick={handleCreateProjectWork}>추가</button>
                            <button onClick={handleClose}>취소</button>
                        </>
                    }
                </div>
            </div>
        </Dialog>
    )
}


export default ProjectWorkAddDialog