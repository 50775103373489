import React from "react"
import CircularProgress from '@mui/material/CircularProgress';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Circle from 'react-circle';


interface IProps {
    percentage: number
}

const ProjectStatCircle: React.FC<IProps> = ({percentage}) => {

    return (
        <div className={'project_stat_circle_main'}>
            <Box sx={{position: 'relative', display: 'inline-flex'}}>
                <Circle
                    animate={true} // Boolean: Animated/Static progress
                    animationDuration="1s" // String: Length of animation
                    responsive={false} // Boolean: Make SVG adapt to parent size
                    size="150" // String: Defines the size of the circle.
                    lineWidth="25" // String: Defines the thickness of the circle's stroke.
                    progress={percentage} // String: Update to change the progress and percentage.
                    progressColor="rgb(76, 154, 255)" // String: Color of "progress" portion of circle.
                    bgColor="#ecedf0" // String: Color of "empty" portion of circle.
                    textColor="#6b778c" // String: Color of percentage text color.
                    textStyle={{
                        font: 'bold 4rem Helvetica, Arial, sans-serif' // CSSProperties: Custom styling for percentage.
                    }}
                    percentSpacing={10} // Number: Adjust spacing of "%" symbol and number.
                    roundedStroke={false} // Boolean: Rounded/Flat line ends
                    showPercentage={true} // Boolean: Show/hide percentage.
                    showPercentageSymbol={true} // Boolean: Show/hide only the "%" symbol.
                />
            </Box>
        </div>
    )
}


export default ProjectStatCircle

