import React from "react"
import {useRecoilState} from "recoil";
import {registerState, UserType} from "./atoms";
import classNames from "classnames";
import Input from "./Input";

const RegisterUserType: React.FC = () => {

    const [state, setState] = useRecoilState(registerState)

    const handleClickSetUserType = (type: UserType) => {
        setState(old => ({...old, userType: type}))
    }

    return (
        <div className={'register_user_type'}>
            <span className={'fw-bold'}>고객 유형 선택</span>
            <div className='user_type_list_main'>
                {state.accountInfo.costs.type.map((cost, index) => {
                    return (
                        <div
                            onClick={() => handleClickSetUserType(cost.name as UserType)}
                            className={classNames({
                                'user_type_item': true,
                                'select': state.userType === cost.name
                            })}>
                            <span>{cost.name} </span>
                            <div className={'type_info'}>
                                <small>월회비:</small>
                                <span>{cost.cost.toLocaleString()} 원</span>
                                {cost.cost !== 0 &&
                                    <small>(부가세 포함)</small>
                                }
                            </div>
                        </div>
                    )
                })}

                {/*<div*/}
                {/*    onClick={() => handleClickSetUserType(UserType.drone)}*/}
                {/*    className={classNames({*/}
                {/*        'user_type_item': true,*/}
                {/*        'select': state.userType === UserType.drone*/}
                {/*    })}>*/}
                {/*    <span>(사)대한 초경량 비행장치 조종자협회 정회원</span>*/}
                {/*    <div className={'type_info'}>*/}
                {/*        <small>월회비:</small>*/}
                {/*        <span>33,000 원</span>*/}
                {/*        <small>(부가세 포함)</small>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div*/}
                {/*    onClick={() => handleClickSetUserType(UserType.nh)}*/}
                {/*    className={classNames({*/}
                {/*        'user_type_item': true,*/}
                {/*        'select': state.userType === UserType.nh*/}
                {/*    })}>*/}
                {/*    <span>농협 관리자</span>*/}
                {/*    <div className={'type_info'}>*/}
                {/*        <small>월회비:</small>*/}
                {/*        <span>0 원</span>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            {state.userType === UserType.nh && (
                <div className={'nh_info_main mt-2'}>
                    <span className={'fw-bold'}>추가정보</span>
                    <Input type={'text'} name={'nhForm.departmentName'} placeholder={'농협 지점명'}/>
                    <Input type={'text'} name={'nhForm.departmentPhone'} placeholder={'직책'}/>
                    <Input type={'text'} name={'nhForm.nhname'} placeholder={'부서명'}/>
                    <Input type={'text'} name={'nhForm.nhposition'} placeholder={'부서 전화번호'}/>
                </div>
            )}
            <div className={'account_info_main'}>
                <span className={'fw-bold'}>입금정보</span>
                {state.accountInfo.banks.info.map((bank, index) => {
                    return (
                        <div key={index} className={'account_info_item'}>
                            <span>은행 : </span>{bank.bank} <br/>
                            <span>계좌번호 : </span>{bank.accountNumber}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default RegisterUserType