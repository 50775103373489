import React, {useState} from "react"
import {useRecoilState, useResetRecoilState} from "recoil";
import {projectState} from "../atoms";
import {getStatusColor} from "../../../util/common";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {notificationAlert} from "../../../util/alert";
import {deleteProject, deleteProjectMember} from "../../../repository/ProjectRepository";
import Swal from "sweetalert2";
import {sidebarState} from "../../sidebar/atoms";
import {downloadExcel} from "../../../repository/FunctionRepository";
import {loaderState} from "../../common/loader/atoms";
import Dialog from "@mui/material/Dialog";
import Medicine from "../../medicine/Medicine";
import MedicinePage from "../../medicine/Medicine";
import CloseIcon from "@mui/icons-material/Close";

const ProjectSettingTab: React.FC = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [state, setState] = useRecoilState(projectState)
    const [sidebar, setSidebar] = useRecoilState(sidebarState)
    const [loader, setLoader] = useRecoilState(loaderState)
    const resetProjectState = useResetRecoilState(projectState)
    const handleClickSetting = (type: 'name' | 'status') => {
        setState(old => ({...old, showProjectSettingDialog: !old.showProjectSettingDialog, projectSettingType: type}))
    }

    const handleDeleteProject = async () => {
        Swal.fire({
            title: '정말 프로젝트를 삭제 하시겠습니까?',
            text: '프로젝트 삭제시 프로젝트에 대한 모든 정보가 삭제됩니다.',
            confirmButtonText: '삭제',
            showCancelButton: true,
            cancelButtonText: '취소'
        }).then(async result => {
            if (result.isConfirmed) {
                const delResult = await deleteProject(state.projectId)

                if (delResult) {
                    resetProjectState()
                    setSidebar(old => ({...old, tabNum: 1}))
                    notificationAlert('알림', '프로젝트가 삭제되었습니다.')
                }
            }
        }).catch(err => {
            if (err) {
                if (err.message) {
                    notificationAlert('오류', err.message)
                } else {
                    notificationAlert('오류', '프로젝트 삭제에 문제가 발생하였습니다.')
                }
            }
        })
    }

    const handleDownLoadWorkExcel = async () => {
        try {
            setLoader(old => ({...old, screenLoad: true}))
            const result = await downloadExcel(state.projectDetail, state.projectWorks, state.projectMembers)
            if (result) {
                setLoader(old => ({...old, screenLoad: false}))
                notificationAlert('알림', '엑셀파일 다운로드가 완료되었습니다.')
            }
        } catch (err) {
            setLoader(old => ({...old, screenLoad: false}))
            notificationAlert('알림', '엑셀파일 다운로드에 문제가 발생하였습니다..')
        }
    }


    return (
        <div className={'project_setting_tab_main project_tab_main'}>
            <h4>프로젝트 정보 수정</h4>
            <div style={{marginTop: '1.5rem'}}>
                <div
                    onClick={() => handleClickSetting('name')}
                    className={'project_setting_info flex_row_between'}>
                    <div>
                        <label>프로젝트 이름</label>
                        <span>{state.projectDetail.name}</span>
                    </div>
                    <ArrowForwardIosIcon/>
                </div>
                <hr/>
                <div
                    onClick={() => handleClickSetting('status')}
                    className={'project_setting_info flex_row_between'}>
                    <div>
                        <label>프로젝트 상태</label>
                        <span
                            style={{color: getStatusColor(state.projectDetail.status)}}>{state.projectDetail.status}</span>
                    </div>
                    <ArrowForwardIosIcon/>
                </div>
                <hr/>
                <div className={'project_setting_btn_main'}>
                    <button
                        onClick={() => setOpen(true)}>
                        약품 관리
                    </button>
                    <button
                        onClick={handleDownLoadWorkExcel}>
                        작업 목록 엑셀로 내보내기
                    </button>
                    <button onClick={handleDeleteProject}>프로젝트 삭제</button>
                </div>
            </div>
            <Dialog fullWidth maxWidth={'md'} open={open}>
                <div className={'dialog_close_btn'}>
                    <button onClick={() => setOpen(false)}>
                        <CloseIcon/>
                    </button>
                </div>
                <MedicinePage adminProjectId={state.projectId} adminUUID={'wq'}/>
            </Dialog>
        </div>
    )
}

export default ProjectSettingTab