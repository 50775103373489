import React from "react"
import {WorkResponse} from "../../../model/response/WorkResponse";
import {useRecoilState} from "recoil";
import {sidebarState} from "../../sidebar/atoms";
import {mapState} from "../../main/atoms";
import classNames from "classnames";


interface IProps {
    work: WorkResponse
}


const ProjectWorkBox: React.FC<IProps> = ({work}) => {

    const [state, setState] = useRecoilState(sidebarState)
    const [map, setMap] = useRecoilState(mapState)
    const handleDrawer = () => {
        setMap(old => ({
            ...old,
            center: {lat: work.latLng.latitude, lng: work.latLng.longitude},
            zoom: 18
        }))
        setState(old => ({
            ...old,
            showRightPolygonDetail: true,
            showRightPolygonSearch: false,
            drawerPolygonData: work
        }))
    }

    return (
        <div onClick={handleDrawer} className={classNames({
            'project_work_box_main': true,
            'active': work.latLng === state.drawerPolygonData.latLng
        })}>
            <div className={'work_info'}>
                <label>주소</label>
                <span>{work.address}</span>
            </div>
            <div className={'flex_row_between'}>
                <div className={'work_info'}>
                    <label>농주 이름</label>
                    <span>{work.farmerName}</span>
                </div>
                <div className={'work_info'}>
                    <label>농주 전화번호</label>
                    <span>{work.farmerPhone}</span>
                </div>
                <div className={'work_info'}>
                    <label>면적</label>
                    <span>{work.area}평</span>
                </div>
            </div>
            <div className={'flex_row_between'}>
                <div className={'work_info'}>
                    <label>작업자</label>
                    <span>{work.worker ? work.worker : '미정'}</span>
                </div>
                <div className={'work_info'}>
                    <label>선호작업기종</label>
                    <span>{work.workType}</span>
                </div>
            </div>
            <div className={'work_info'}>
                <label>작업 상태</label>
                <span>{work.workStatus}</span>
            </div>

        </div>
    )
}

export default ProjectWorkBox