import React, {useEffect, useState} from "react"
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {useRecoilState, useRecoilValue} from "recoil";
import {sidebarState} from "../atoms";
import classNames from "classnames";
import ConsoleSelect from "../../common/input/ConsoleSelect";
import {UpdateWorkStatusRequest} from "../../../model/request/UpdateWorkStatusRequest";
import {projectState} from "../../project/atoms";
import Input from "../Input";
import ImageUploader from "./ImageUploader";
import {notificationAlert} from "../../../util/alert";
import {isIterableArray, objectToMap} from "../../../util/common";
import {getWorkRecord, updateWorkStatus} from "../../../repository/ProjectRepository";
import {loaderState} from "../../common/loader/atoms";
import Loader from "../../common/loader/Loader";
import {Timestamp} from "firebase/firestore";


const WorkStatusChangeDialog: React.FC = () => {

    const [state, setState] = useRecoilState(sidebarState)
    const [loader, setLoader] = useRecoilState(loaderState)
    const project = useRecoilValue(projectState)
    const [projectStatus, setProjectStatus] = useState<string>('진행')
    const [updateRequest, setUpdateRequest] = useState<UpdateWorkStatusRequest>({
        projectId: '',
        workId: '',
        workerId: '',
        workStatus: '',
        workType: '',
        reason: '',
        worker: '',
        images: []
    })

    useEffect(() => {

        let requestForm = {
            projectId: '',
            workId: '',
            workerId: '',
            workStatus: '',
            workType: '',
            reason: '',
            worker: '',
            images: []
        }
        if (state.drawerPolygonData.workStatus) {
            setProjectStatus(state.drawerPolygonData.workStatus)
            requestForm.workStatus = state.drawerPolygonData.workStatus
        }

        if (state.drawerPolygonData.worker && state.drawerPolygonData.workerId) {
            requestForm.worker = state.drawerPolygonData.worker
            requestForm.workerId = state.drawerPolygonData.workerId
        }

        if (state.drawerPolygonData.workType) {
            requestForm.workType = state.drawerPolygonData.workType
        }

        setUpdateRequest({...requestForm})
    }, [state.drawerPolygonData])

    const handleClose = () => {
        if (!loader.workStatusChange)
            setState(old => ({...old, showWorkStatusChangeDialog: !old.showWorkStatusChangeDialog}))
    }

    const getWorkRecords = async (workId: string) => {
        try {
            setLoader(old => ({...old, rightDetailSidebar: true}))
            const getResult = await getWorkRecord(workId)
            if (getResult) {
                setState(old => ({...old, projectWorkRecords: getResult}))
                setLoader(old => ({...old, rightDetailSidebar: false}))
            }

        } catch (err) {
            if (err) {
                notificationAlert('오류', '작업 기록을 가져오는데 문제가 발생하였습니다.')
            }
        }
    }


    const handleChangeWorkStatus = async () => {
        try {
            let requestForm = {...updateRequest}
            if (projectStatus === requestForm.workStatus) {
                notificationAlert('알림', '이미 지정된 상태 입니다.');
                return;
            }
            if ((projectStatus !== '예정') && (updateRequest.workType === '')) {
                notificationAlert('알림', '작업 기종을 선택하여 주세요.');
                return
            }
            if ((projectStatus !== '예정') && (updateRequest.worker === '')) {
                notificationAlert('알림', '작업자를 선택하여 주세요.')
                return
            }
            setLoader(old => ({...old, workStatusChange: true}))
            requestForm.projectId = project.projectId
            requestForm.workId = state.drawerPolygonData.workId
            requestForm.workStatus = projectStatus

            const updateResult = await updateWorkStatus(requestForm)

            if (updateResult) {
                setLoader(old => ({...old, workStatusChange: false, showWorkStatusChangeDialog: false}))
                notificationAlert('알림', '상태가 변경되었습니다.');
                await getWorkRecords(requestForm.workId);
                handleClose();
            }
        } catch (err: any) {
            if (err) {
                setLoader(old => ({...old, workStatusChange: false}))
                setState(old => ({
                    ...old,
                    showRightPolygonDetail: !old.showRightPolygonDetail,
                    showWorkStatusChangeDialog: !old.showWorkStatusChangeDialog
                }))
                if (err.message) {
                    notificationAlert('오류', err.message)
                } else {
                    notificationAlert('오류', '상태 변경에 문제가 발생하였습니다.')
                }
            }
        }
    }

    const handleChangeWorkType = (type: string) => {
        setUpdateRequest(old => ({...old, workType: type}))
    }

    const handleChangeWorker = (workerId: string) => {
        const workerInfo = project.projectMembers.filter(member => member.userId === workerId)
        if (isIterableArray(workerInfo))
            setUpdateRequest(old => ({
                ...old,
                worker: `${workerInfo[0].name}(${workerInfo[0].phone})`,
                workerId: workerId
            }))
    }

    const handleChangeReason = (reason: string) => {
        setUpdateRequest(old => ({...old, reason: reason}))
    }

    const handleChangeImages = (images: File[]) => {
        setUpdateRequest(old => ({...old, images: images}))
    }

    return (
        <Dialog onClose={handleClose} open={state.showWorkStatusChangeDialog} maxWidth={false}>
            <DialogTitle>작업 상태 변경</DialogTitle>
            <div className={'change_work_status_dialog_main'}>
                <div className={'dialog_content'}>
                    <div className={'flex_row_between work_status_list'}>
                        <span
                            onClick={() => setProjectStatus('예정')}
                            className={classNames({
                                'active': projectStatus === '예정'
                            })}>예정</span>
                        <span
                            onClick={() => setProjectStatus('진행')}
                            className={classNames({
                                'active': projectStatus === '진행'
                            })}>진행</span>
                        <span
                            onClick={() => setProjectStatus('중단')}
                            className={classNames({
                                'active': projectStatus === '중단'
                            })}>중단</span>
                        <span
                            onClick={() => setProjectStatus('완료')}
                            className={classNames({
                                'active': projectStatus === '완료'
                            })}>완료</span>
                        <span
                            onClick={() => setProjectStatus('제외')}
                            className={classNames({
                                'active': projectStatus === '제외'
                            })}>제외</span>
                        <span
                            onClick={() => setProjectStatus('재작업요청')}
                            className={classNames({
                                'active': projectStatus === '재작업요청'
                            })}>재작업요청</span>
                    </div>
                    {projectStatus !== '예정' &&
                        <div>
                            <div className={'work_status_change_form_main'}>
                                <ConsoleSelect require={true} label={'작업 기종'} width={100}>
                                    <select value={updateRequest.workType}
                                            onChange={event => handleChangeWorkType(event.target.value)}>
                                        <option value={''}>작업 기종을 선택하여 주세요.</option>
                                        <option value={'드론'}>드론</option>
                                        <option value={'광역방제기'}>광역방제기</option>
                                        <option value={'무인헬기'}>무인헬기</option>
                                        <option value={'보트'}>보트</option>
                                        <option value={'기타'}>기타</option>
                                    </select>
                                </ConsoleSelect>
                                <ConsoleSelect require={true} label={'작업자 지정'} width={100}>
                                    <select value={updateRequest.workerId}
                                            onChange={event => handleChangeWorker(event.target.value)}>
                                        <option value={''}>작업자를 선택하여 주세요.</option>
                                        {project.projectMembers.map((member, index) => {
                                            return (
                                                <option key={index} value={member.userId}>
                                                    {`${member.name}(${member.phone})`}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </ConsoleSelect>
                                {projectStatus !== '진행' &&
                                    <input
                                        className={'reason_input'}
                                        onChange={event => handleChangeReason(event.target.value)}
                                        placeholder={'사유를 입력해주세요.'}/>
                                }
                            </div>
                            {projectStatus !== '진행' &&
                                <ImageUploader handleChangeImages={handleChangeImages}/>
                            }
                        </div>
                    }
                </div>
                <div className={'dialog_button_main'}>
                    {loader.workStatusChange ? <Loader height={45}/> :
                        <>
                            <button onClick={handleChangeWorkStatus}>상태 변경</button>
                            <button onClick={handleClose}>취소</button>
                        </>
                    }
                </div>
            </div>
        </Dialog>
    )
}

export default WorkStatusChangeDialog