import React from "react";
import {CircularProgress} from "@mui/material";


export const MapLoader = () => {

    return (
        <div className='map_loader_wrapper'>
            <CircularProgress />
        </div>
    )
}
