import React, {useRef} from 'react'
import {isIterableArray} from "../../../util/common";

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    state: any
    setState: any
}

const Input: React.FC<Props> = ({state, setState, ...props}: Props) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const error = state[`${props.name}Error`]
    return (
        <div style={{textAlign: 'left'}}>
            <input
                {...props}
                ref={inputRef}
                title={error}
                data-testid={props.name}
                readOnly
                onFocus={e => {
                    e.target.readOnly = false
                }}
                onChange={e => {
                    if (e.target.name.includes('.')) {
                        const splitName = e.target.name.split('.');
                        if (isIterableArray(splitName)) {
                            setState({
                                ...state,
                                [splitName[0]]: {
                                    ...state[splitName[0]],
                                    [splitName[1]]: e.target.value
                                }
                            })
                        }
                    } else {
                        setState({...state, [e.target.name]: e.target.value})
                    }
                }}
            />
            <div style={{padding: '0 0.3rem'}}>
                <small style={{color: 'red'}}>{error}</small>
            </div>
        </div>
    )
}

export default Input
