import React, {useEffect, useState} from "react";
import {Col, Modal, ModalBody, Row} from "reactstrap";
import {collection, getDocs, orderBy, query, where} from "firebase/firestore";
import {firestore} from "../../../firebase";
import {CircularProgress} from "@mui/material";
import {isIterableArray} from "../../../util/common";
import {CloseIcon} from "../../icon/CloseIcon";


export const ProjectWorkRecordModal = ({toggle, modal, project}) => {
    const [filterList, setFilterList] = useState([]);
    const [searchType, setSearchType] = useState('address');
    const [filterTxt, setFilterTxt] = useState('');
    const [loader, setLoader] = useState(false);
    const [workRecords, setWorkRecords] = useState([{}]);
    const [searchLoader, setSearchLoader] = useState(false);
    const [userStatic, setUserStatic] = useState({
        complete: {
            area: 0,
            cnt: 0
        },
        progress: {
            area: 0,
            cnt: 0
        },
        rework: {
            area: 0,
            cnt: 0
        },
        stop: {
            area: 0,
            cnt: 0
        },
        except: {
            area: 0,
            cnt: 0
        },
        expected: {
            area: 0,
            cnt: 0
        }
    })
    useEffect(() => {
        setLoader(true);
        setSearchType('address')
        if (!project) {
            return;
        }
        if (Object.keys(project).length === 0) {
            return;
        }
        getUserWorkRecord().then(result => {
            if (isIterableArray(result)) {
                setWorkRecords(result);
                setFilterList(result);
                setUserStatic(project.workStatus);
            } else {
                setWorkRecords([]);
                setFilterList([]);
            }
            setLoader(false);
        }).catch(err => {
            setLoader(false);
            setWorkRecords([]);
            setFilterList([]);
        })

        return () => {
            setSearchLoader(false);
            setLoader(false);
        }
    }, [project])

    const getUserWorkRecord = async () => {
        const q = query(collection(firestore, "works"), where("projectId", "==", project.id));
        const userDocs = await getDocs(q);
        return userDocs.docs.map(doc => ({id: doc.id, ...doc.data()}));
    }
    const handleSearchUser = () => {
        setSearchLoader(true);
        if (filterTxt.length === 0) {
            setFilterList(workRecords);
            setSearchLoader(false);
            return;
        }
        const newInstanceList = workRecords.filter((instance) =>
            instance[searchType].toLowerCase().includes(filterTxt));
        setFilterList(newInstanceList);
        setTimeout(() => {
            setSearchLoader(false);
        }, 500)

    }

    return (
        <Modal centered isOpen={modal} toggle={() => toggle({})} className={'user_manage_modal'}>
            <ModalBody className={'work_record_modal_body'}>
                <div className='work_record_admin_list_wrapper'>
                    <div className={'custom_modal_header'}>
                        <p className={'fw-bold'}><span className={'home_color'}>{project.name}</span> 프로젝트 작업 내역</p>
                        <button onClick={() => toggle({})}>
                            <CloseIcon width={20} height={20} fill={'black'}/>
                        </button>
                    </div>
                    {loader ?
                        <div style={{
                            minHeight: '300px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <CircularProgress/>
                        </div>
                        :
                        <>
                            {isIterableArray(workRecords) ?
                                <div>
                                    <span>전체 통계</span>
                                    <div className='admin_user_record_statistics'>
                                        <div className='around_space'>
                                            <span>예정</span>
                                            <span>{userStatic.expected.area.toFixed(2)}</span>
                                        </div>
                                        <div className='around_space'>
                                            <span>완료</span>
                                            <span>{userStatic.complete.area.toFixed(2)}</span>
                                        </div>
                                        <div className='around_space'>
                                            <span>진행</span>
                                            <span>{userStatic.progress.area.toFixed(2)}</span>
                                        </div>
                                        <div className='around_space'>
                                            <span>재작업요청</span>
                                            <span>{userStatic.rework.area.toFixed(2)}</span>
                                        </div>
                                        <div className='around_space'>
                                            <span>중단</span>
                                            <span>{userStatic.stop.area.toFixed(2)}</span>
                                        </div>
                                        <div className='around_space'>
                                            <span>제외</span>
                                            <span>{userStatic.except.area.toFixed(2)}</span>
                                        </div>
                                    </div>
                                    <Row className='search_box' style={{padding: '1rem 0'}}>
                                        <Col className='p-0' xs={3}>
                                            <select onChange={event => setSearchType(event.target.value)}>
                                                <option value={'address'}>주소</option>
                                                <option value={'farmerName'}>이름</option>
                                                <option value={'farmerPhone'}>전화번호</option>
                                            </select>
                                        </Col>
                                        <Col className='p-0' xs={6}>
                                            <input
                                                style={{padding: '0 0.4rem'}}
                                                onChange={event => setFilterTxt(event.target.value)}
                                                type={'text'}/>
                                        </Col>
                                        <Col className='p-0' xs={3}>
                                            <button onClick={handleSearchUser}>
                                                {searchLoader ?
                                                    <CircularProgress/>
                                                    :
                                                    <p className={'m-0'}>검색</p>
                                                }

                                            </button>
                                        </Col>
                                    </Row>
                                    <div>
                                        {!isIterableArray(filterList) && <span>검색 내용이 존재하지 않습니다.</span>}
                                    </div>
                                    {filterList.map((workData, index) => {
                                        return (
                                            <Row key={index} className={'admin_user_work_record_item'}>
                                                <Col xs={12}>
                                                    <span>주소</span>
                                                    <p>{workData.address}</p>
                                                </Col>

                                                <Col xs={6}>
                                                    <span>농주 이름</span>
                                                    <p>{workData.farmerName}</p>
                                                </Col>
                                                <Col xs={6}>
                                                    <span>농주 전화번호</span>
                                                    <p>{workData.farmerPhone}</p>
                                                </Col>
                                                <Col xs={6}>
                                                    <span>면적</span>
                                                    <p>{workData.area ? workData.area.toFixed(2) : 0}</p>
                                                </Col>
                                                <Col xs={6}>
                                                    <span>작업상태</span>
                                                    <p>{workData.workStatus}</p>
                                                </Col>
                                                <Col xs={6}>
                                                    <span>선호작업기종</span>
                                                    <p>{workData.workType}</p>
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                </div>
                                :
                                <div>
                                    <span>작업 내역이 존재하지 않습니다.</span>
                                </div>
                            }
                        </>
                    }
                </div>
            </ModalBody>
        </Modal>
    )
}

