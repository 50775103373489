import {atom} from 'recoil'

interface IState {
    phone: string,
}

export const findState = atom<IState>({
    key: 'findState',
    default: {
        phone: '',
    }
})