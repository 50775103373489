import React, {useEffect} from "react"
import {Checkbox, FormControlLabel} from "@mui/material";
import AuthPhoneForm from "./AuthPhoneForm";
import RegisterUserType from "./RegisterUserType";
import {useRecoilState, useResetRecoilState} from "recoil";
import {registerState, UserType} from "./atoms";
import {getAccount, register} from "../../repository/AuthRepository";
import Input from "./Input";
import {notificationAlert} from "../../util/alert";
import {useNavigate} from "react-router-dom";
import {loaderState} from "../common/loader/atoms";
import Loader from "../common/loader/Loader";


const Register: React.FC = () => {

    const [state, setState] = useRecoilState(registerState)
    const [loader, setLoader] = useRecoilState(loaderState)
    const navigator = useNavigate()
    const resetState = useResetRecoilState(registerState)
    useEffect(() => {
        resetState()
        getAccountData()
    }, [])

    const getAccountData = async () => {
        try {
            setLoader(old => ({...old, getAccountInfo: true}))
            const result = await getAccount()

            if (result) {
                setState(old => ({...old, accountInfo: result}))
                setLoader(old => ({...old, getAccountInfo: false}))
            }
        } catch (err) {
            if (err) {
                setLoader(old => ({...old, getAccountInfo: false}))
                notificationAlert('오류', '계좌/은행 정보를 가져오는데 문제가 발생하였습니다.')
            }
        }
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault()
        try {

            if (state.userType === UserType.nh) {
                if (Object.values(state.nhForm).some(form => form === '')) {
                    notificationAlert('알림', '농협 고객에 경우 추가정보를 모두 입력하여 주세요.')
                    return
                }
            }
            setLoader(old => ({...old, register: true}))
            const result = await register(state.phone, state.name, state.userType)

            if (result) {
                notificationAlert('알림', '회원가입이 완료되었습니다.')
                setLoader(old => ({...old, register: false}))
                setTimeout(() => {
                    navigator('/')
                }, 1000)
            }

        } catch (err: any) {
            if (err) {
                setLoader(old => ({...old, register: false}))
                if (err.message) {
                    notificationAlert('오류', err.message)
                } else {
                    notificationAlert('오류', '회원가입에 문제가 발생하였습니다.')
                }
            }
        }

    }

    const handleAgreePolicy = (checked: boolean, type: string) => {
        if (type === 'useP') {
            setState(old => ({...old, usePolicyAg: checked}))
        } else if (type === 'prvP') {
            setState(old => ({...old, privacyAg: checked}))
        } else {
            setState(old => ({...old, locationAg: checked}))
        }
    }

    return (
        <div className={'login_main'}>
            <div className='login_box'>
                <h3 id={'box_title'}>회원가입</h3>
                {loader.getAccountInfo ? <Loader height={500}/> :
                        <form onSubmit={handleSubmit}>
                            <div style={{padding: '1rem 0'}}>
                                <Input type={'text'} name={'name'} placeholder={'이름을 입력하여 주세요.'}/>
                            </div>
                            <AuthPhoneForm/>
                            <RegisterUserType/>
                            <div className={'register_policy_main'}>
                                <FormControlLabel className='support_agree_policy_form' control={<Checkbox
                                    onChange={event => handleAgreePolicy(event.target.checked, 'useP')}/>}
                                                  label={'이용약관 동의 (필수)'}/>
                                <FormControlLabel className='support_agree_policy_form' control={<Checkbox
                                    onChange={event => handleAgreePolicy(event.target.checked, 'prvP')}/>}
                                                  label={'개인정보처리방침 동의(필수)'}/>
                                <FormControlLabel className='support_agree_policy_form' control={<Checkbox
                                    onChange={event => handleAgreePolicy(event.target.checked, 'locP')}/>}
                                                  label={'위치기반서비스 이용약관 동의(필수)'}/>
                            </div>
                            <div className={'form_btn_wrapper'}>
                                <button
                                    disabled={(!state.privacyAg || !state.usePolicyAg || !state.locationAg || (state.name === '') || !state.isSuccessAuthPhone)}
                                    type={"submit"} id={'login_submit_btn'}>
                                    {loader.register ? <Loader height={25}/> : '회원가입'}
                                </button>
                            </div>
                        </form>
                }

            </div>
        </div>
    )
}


export default Register