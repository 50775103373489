import React, {useEffect, useState} from "react"
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {useRecoilState} from "recoil";
import classNames from "classnames";
import {projectState} from "../../atoms";
import {MemberResponse} from "../../../../model/response/MemberResponse";
import {isIterableArray} from "../../../../util/common";
import {updateWorkStatusAssignment} from "../../../../repository/ProjectRepository";
import {notificationAlert} from "../../../../util/alert";
import {loaderState} from "../../../common/loader/atoms";
import Loader from "../../../common/loader/Loader";
import {sidebarState} from "../../../sidebar/atoms";

const AssignWorkDialog: React.FC = () => {

    const [state, setState] = useRecoilState(projectState)
    const [sidebar, setSidebar] = useRecoilState(sidebarState)
    const [worker, setWorker] = useState<MemberResponse>({userId: '', phone: '', name: '', userType: ''})
    const [loader, setLoader] = useRecoilState(loaderState)


    useEffect(() => {
        if (state.showAssignWorkDialog) {
            setWorker({userId: '', phone: '', name: '',userType: ''})
        }

    }, [state.showAssignWorkDialog])

    const handleClose = () => {
        if (!loader.assignWorks)
            setState(old => ({...old, showAssignWorkDialog: !old.showAssignWorkDialog}))
    }

    const handleSetWorker = (workerId: string) => {
        if (workerId !== '') {
            const filterWorker = state.projectMembers.filter(member => member.userId === workerId)
            if (isIterableArray(filterWorker))
                setWorker(filterWorker[0])
        }
    }

    const handleAssignWorker = async () => {
        try {
            setLoader(old => ({...old, assignWorks: true}))
            const result = await updateWorkStatusAssignment(worker.userId, `${worker.name} (${worker.phone})`, state.projectId, state.assignForm.containWorks)
            if (result) {
                setState(old => ({...old, showAssignWorkDialog: false}))

                setLoader(old => ({...old, assignWorks: false}))
                setSidebar(old => ({...old, activeAssign: false}))
                notificationAlert('알림', '작업이 할당되었습니다.')
            }

        } catch (err: any) {
            if (err) {
                setState(old => ({...old, showAssignWorkDialog: false}))
                setLoader(old => ({...old, assignWorks: false}))
                if (err.message) {
                    notificationAlert('오류', err.message)
                } else {
                    notificationAlert('오류', '작업 할당에 문제가 발생하였습니다.')
                }

            }
        }
    }


    return (
        <Dialog onClose={handleClose} open={state.showAssignWorkDialog}>
            <DialogTitle>작업 할당</DialogTitle>
            <div className={'assign_worker_dialog_main'}>
                <div className={'dialog_content'}>
                    <div>
                        <span className={'fw-bold'}>지정된 작업 정보 </span>
                        <div className={'flex_row_between'} style={{marginTop: '1rem'}}>
                            <span>지정된 작업 개수</span>
                            <span className={'fw-bold'}>{state.assignForm.count}개</span>
                        </div>
                        <div className={'flex_row_between'}>
                            <span>지정된 작업 평수</span>
                            <span className={'fw-bold'}>{state.assignForm.totalArea.toFixed(2)}평</span>
                        </div>
                    </div>
                    <hr/>
                    <div className={'assign_worker_form'}>
                        <span className={'fw-bold'}>작업자 할당 </span>
                        <select value={worker.userId} onChange={event => handleSetWorker(event.target.value)}>
                            <option value={''}>작업자를 지정하여 주세요.</option>
                            {state.projectMembers.map((member, index) => {
                                return (
                                    <option key={index} value={member.userId}>
                                        {`${member.name}(${member.phone})`}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <div className={'dialog_button_main'}>
                    {loader.assignWorks ?
                        <Loader height={45}/>
                        :
                        <>
                            <button onClick={handleAssignWorker}>할당</button>
                            <button onClick={handleClose}>취소</button>
                        </>
                    }
                </div>
            </div>
        </Dialog>
    )
}


export default AssignWorkDialog