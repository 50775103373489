import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useRecoilState, useResetRecoilState} from "recoil";
import {loginState} from "./atoms";
import Input from "./Input";
import {login} from "../../repository/AuthRepository";
import {notificationAlert} from "../../util/alert";
import {loaderState} from "../common/loader/atoms";
import Loader from "../common/loader/Loader";


const Login: React.FC = () => {
    const resetLoginState = useResetRecoilState(loginState)
    const [loader, setLoader] = useRecoilState(loaderState)
    const [state, setState] = useRecoilState(loginState)
    const navigate = useNavigate()
    useEffect(() => {
        resetLoginState()
    }, [])

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault()
        try {
            setLoader(old => ({...old, login: true}))
            const result = await login(state.phone, state.password)
            if (result) {
                setLoader(old => ({...old, login: false}))
                localStorage.setItem('bsKy', result)
                window.location.reload()
            }
        } catch (err: any) {
            if (err) {
                setLoader(old => ({...old, login: false}))
                if (err.message) {
                    notificationAlert('오류', err.message)
                } else {
                    notificationAlert('오류', '로그인에 문제가 발생하였습니다.')
                }
            }
        }

    }


    return (
        <div className={'login_main'}>
            <div className='login_box'>
                <h3 id={'box_title'}>로그인</h3>
                <form onSubmit={handleSubmit}>
                    <div className='form_group'>
                        <Input type="text" name="phone" placeholder="핸드폰 번호"/>
                    </div>
                    <div className=''>
                        <Input type="password" name="password" placeholder="비밀번호"/>
                    </div>
                    <div className={'forget_pwd_wrapper'}>
                        <Link to={'/register'}>
                            <span>회원가입</span>
                        </Link>
                        <Link to={'/findPassword'}>
                            <span>비밀번호를 잊어버리셨나요?</span>
                        </Link>
                    </div>
                    <div className={'form_btn_wrapper'}>
                        <button type={"submit"} id={'login_submit_btn'}>
                            {loader.login ? <Loader height={25}/> : '로그인'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login;
