import React, {useEffect} from "react"
import SpaceBase from "../sidebar/SpaceBase";
import {useRecoilState} from "recoil";
import {mypageState} from "./atoms";
import Input from "./Input";
import {notificationAlert} from "../../util/alert";
import {getUser, updateName, updatePassword} from "../../repository/AuthRepository";
import {getUserId} from "../../util/auth";
import {loaderState} from "../common/loader/atoms";
import Loader from "../common/loader/Loader";


const MyPageProfile: React.FC = () => {

    const [state, setState] = useRecoilState(mypageState)
    const [loader, setLoader] = useRecoilState(loaderState)

    useEffect(() => {
        handleGetUser()
    }, [])

    const handleGetUser = async () => {
        try {
            const userId = getUserId()
            if (userId) {
                setLoader(old => ({...old, myPageDetail: true}))
                const result = await getUser(userId)
                if (result) {
                    setState(old => ({...old, user: result, newName: result.name}))
                    setLoader(old => ({...old, myPageDetail: false}))
                }
            }
        } catch (err) {
            if (err) {
                setLoader(old => ({...old, myPageDetail: false}))
                notificationAlert('오류', '유저 정보를 가져오는데 문제가 발생하였습니다.')
            }
        }
    }

    const handleChangeName = async () => {
        try {
            if (loader.changeUserName) {
                return
            }
            const userId = getUserId()
            if (userId) {
                setLoader(old => ({...old, changeUserName: true}))
                const result = await updateName(userId, state.newName)
                if (result) {
                    setState(old => ({...old, user: {...old.user, name: state.newName}}))
                    setLoader(old => ({...old, changeUserName: false}))
                    notificationAlert('알림', '이름이 변경되었습니다.')
                }
            }
        } catch (err: any) {
            if (err) {
                setLoader(old => ({...old, changeUserName: false}))
                if (err.message) {
                    notificationAlert('오류', err.message)
                } else {
                    notificationAlert('오류', '이름 변경에 문제가 발생하였습니다.')
                }

            }
        }
    }

    const handleChangePassword = async () => {
        try {
            if (loader.changeUserPassword) {
                return
            }
            if (state.newPassword !== state.confirmNewPassword) {
                notificationAlert('알림', '비밀번호 확인이 잘못되었습니다. 다시 확인하여 주세요.')
                return
            }
            const userId = getUserId()
            if (userId) {
                setLoader(old => ({...old, changeUserPassword: true}))
                const result = await updatePassword(userId, state.oldPassword, state.newPassword)
                if (result) {
                    notificationAlert('알림', '비밀번호가 변경되었습니다.')
                    setState(old => ({...old, oldPassword: '', newPassword: '', confirmNewPassword: ''}))
                    setLoader(old => ({...old, changeUserPassword: false}))
                }
            }
        } catch (err: any) {
            if (err) {
                setLoader(old => ({...old, changeUserPassword: false}))
                if (err.message) {
                    notificationAlert('오류', err.message)
                } else {
                    notificationAlert('오류', '비밀번호 변경에 문제가 발생하였습니다.')
                }

            }
        }
    }

    return (
        <SpaceBase useSearchBar={false} title={'마이페이지'}>
            {loader.myPageDetail ? <Loader height={500}/>
                :
                <div className={'mypage_main'}>
                    <div className={'profile_info_main'}>
                        <span className={'fw-bold'}>{state.user.name}님의 프로필</span>
                        <div className={'form_group'}>
                            <Input value={state.user.phone} disable={true} type={'text'} name={'user.phone'}
                                   placeholder={'변경하실 이름을 입력하여 주세요.'}/>
                            <Input value={state.newName} type={'text'} name={'newName'}
                                   placeholder={'변경하실 이름을 입력하여 주세요.'}/>
                            <button
                                onClick={handleChangeName}
                                disabled={(state.newName === '')}>
                                {loader.changeUserName ? <Loader height={25}/> : '이름 변경'}
                            </button>
                        </div>
                    </div>
                    <div className={'pwd_change_main'}>
                        <span className={'fw-bold'}>비밀번호 변경</span>
                        <div className={'form_group'}>
                            <Input value={state.oldPassword} type={'password'} name={'oldPassword'}
                                   placeholder={'이전 비밀번호를 입력하여 주세요.'}/>
                            <Input value={state.newPassword} type={'password'} name={'newPassword'}
                                   placeholder={'변경하실 비밀번호를 입력하여 주세요.'}/>
                            <Input value={state.confirmNewPassword} type={'password'} name={'confirmNewPassword'}
                                   placeholder={'비밀번호 확인'}/>
                            <button
                                disabled={(state.oldPassword === '') || (state.newPassword === '') || (state.confirmNewPassword === '')}
                                onClick={handleChangePassword}>
                                {loader.changeUserPassword ? <Loader height={25}/> : '비밀번호 변경'}
                            </button>
                        </div>
                    </div>
                </div>
            }
        </SpaceBase>
    )
}

export default MyPageProfile