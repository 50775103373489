import React, {MutableRefObject, useEffect, useRef} from "react"
import {DrawingManager} from '@react-google-maps/api';
import {useRecoilState} from "recoil";
import {projectState} from "../project/atoms";
import {sidebarState} from "../sidebar/atoms";
import {isIterableArray} from "../../util/common";
import {WorkResponse} from "../../model/response/WorkResponse";


const WorkAssignMode: React.FC = () => {
    const [project, setProject] = useRecoilState(projectState)
    const [sidebar, setSidebar] = useRecoilState(sidebarState)
    const drawPolygonRef = useRef<google.maps.Polygon | null>(null);
    const onPolygonComplete = (polygon: google.maps.Polygon) => {
        drawPolygonRef.current = polygon
        const includePolygons: WorkResponse[] = []
        project.filterProjectWorks.forEach(work => {
            if (isIterableArray(work.polygon)) {
                work.polygon.forEach(latLng => {
                    const coordinate = new window.google.maps.LatLng(latLng.latitude, latLng.longitude)
                    if (window.google.maps.geometry.poly.containsLocation(coordinate, polygon)) {
                        if (includePolygons.filter(iWork => iWork.workId === work.workId).length === 0) {
                            includePolygons.push(work);
                        }

                    }
                })
            }

        })

        const totalArea: number = includePolygons.map(row => row.area).reduce((acc, cur) => {
            return acc += cur;
        }, 0);

        setProject(old => ({
            ...old,
            assignForm: {
                containWorks: includePolygons,
                totalArea: totalArea,
                count: includePolygons.length
            },
            showAssignWorkDialog: true
        }))
    }

    useEffect(() => {
        if (!sidebar.activeAssign || !sidebar.activeWorkMode) {
            if (drawPolygonRef.current) {
                drawPolygonRef.current.setMap(null);
                drawPolygonRef.current = null
            }
        }
        if (!sidebar.activeWorkMode) {
            setSidebar(old => ({...old, activeAssign: false}))
        }
    }, [sidebar.activeAssign, sidebar.activeWorkMode])


    return (
        <>
            {(sidebar.activeWorkMode && sidebar.activeAssign) &&
                <DrawingManager
                    drawingMode={google.maps.drawing.OverlayType.POLYGON}
                    onPolygonComplete={onPolygonComplete}
                    options={{
                        drawingControl: false,
                        polygonOptions: {
                            draggable: false,
                            fillColor: 'red',
                            strokeColor: 'red',
                            zIndex: 999
                        }
                    }
                    }
                />
            }
        </>
    )
}


export default WorkAssignMode