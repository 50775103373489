import React, {useEffect, useState} from "react"
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {useRecoilState} from "recoil";
import {loaderState} from "../../common/loader/atoms";
import {projectState} from "../atoms";
import {sidebarState} from "../../sidebar/atoms";


const WorkerInfoDialog: React.FC = () => {

    const [state, setState] = useRecoilState(projectState)
    const [loader, setLoader] = useRecoilState(loaderState)


    useEffect(() => {
        if (state.showWorkerInfoDialog) {

        }
    }, [state.showWorkerInfoDialog])

    const handleClose = () => {
        if (!loader.assignWorks)
            setState(old => ({...old, showWorkerInfoDialog: !old.showWorkerInfoDialog}))
    }


    return (
        <Dialog onClose={handleClose} open={state.showWorkerInfoDialog}>
            <DialogTitle>작업자 정보</DialogTitle>
            <div className={'assign_worker_dialog_main worker_info_dialog_main'}>
                <div className={'dialog_content'}>
                    <div>
                        <div className={'flex_row_between'} style={{marginTop: '1rem'}}>
                            <span>작업자 이름</span>
                            <span className={'fw-bold'}>{state.selectWorkerInfo.name}</span>
                        </div>
                        <div className={'flex_row_between'}>
                            <span>작업자 전화번호</span>
                            <span className={'fw-bold'}>{state.selectWorkerInfo.phone}</span>
                        </div>
                    </div>
                </div>
                <div className={'close_btn_main'}>
                    <button onClick={handleClose}>닫기</button>
                </div>
            </div>
        </Dialog>
    )
}


export default WorkerInfoDialog