import {atom} from 'recoil'
import {NHAdmin} from "../../model/NHAdmin";
import {AccountBanks, AccountCosts} from "../../model/response/AccountBank";

export enum UserType {
    common = '일반',
    drone = '(사)대한 초경량 비행장치 조종자협회 정회원',
    nh = '농협 관리자'

}

interface IState {
    name: string,
    password: string,
    phone: string,
    accountInfo: {
        banks: AccountBanks,
        costs: AccountCosts
    }
    userAuthNum: string,
    isAuthPhone: boolean,
    isSuccessAuthPhone: boolean,
    userType: UserType,
    locationAg: boolean,
    usePolicyAg: boolean,
    privacyAg: boolean,
    nhForm: NHAdmin
}

export const registerState = atom<IState>({
    key: 'registerState',
    default: {
        name: '',
        password: '',
        phone: '',
        accountInfo: {
            banks: {
                info: []
            },
            costs: {
                type: []
            }
        },
        userAuthNum: '',
        isAuthPhone: false,
        isSuccessAuthPhone: false,
        userType: UserType.common,
        locationAg: false,
        usePolicyAg: false,
        privacyAg: false,
        nhForm: {
            departmentName: '',
            departmentPhone: '',
            nhname: '',
            nhposition: ''
        }
    }
})