import React, {useCallback, useEffect, useRef, useState} from "react"
import {GoogleMap} from '@react-google-maps/api';
import LeftSideBar from "../sidebar/LeftSideBar";
import RightPolygonDetailSideBar from "../sidebar/right/RightPolygonDetailSideBar";
import RightPolygonSearchSideBar from "../sidebar/right/RightPolygonSearchSideBar";
import ProjectCreateDialog from "../project/ProjectCreateDialog";
import MemberAuthorityChangeDialog from "../project/detail/MemberAuthorityChangeDialog";
import ProjectMemberAddDialog from "../project/detail/ProjectMemberAddDialog";
import ProjectSettingDialog from "../project/detail/ProjectSettingDialog";
import ProjectAddExcelDialog from "../project/excel/ProjectAddExcelDialog";
import WorkStatusChangeDialog from "../sidebar/right/WorkStatusChangeDialog";
import WorkModifiedDialog from "../sidebar/right/WorkModifiedDialog";
import WorksPolygon from "../project/polygon/WorksPolygon";
import ProjectWorkAddDialog from "../project/detail/ProjectWorkAddDialog";
import {useRecoilState, useRecoilValue} from "recoil";
import {mapState} from "./atoms";
import WorkAssignMode from "./WorkAssignMode";
import AssignWorkDialog from "../project/detail/mapBox/AssignWorkDialog";
import {loaderState} from "../common/loader/atoms";
import CircularProgress from '@mui/material/CircularProgress';
import WorkersMarker from "../project/polygon/WorkersMarker";
import WorkerInfoDialog from "../project/detail/WorkerInfoDialog";
import {sidebarState} from "../sidebar/atoms";
import {getClickAreaAddress} from "../../repository/ProjectRepository";
import {notificationAlert} from "../../util/alert";
import {isIterableArray} from "../../util/common";
import {polygonToAddress} from "../../repository/FunctionRepository";
import {
    TransformWrapper,
    TransformComponent,
    useControls,
} from "react-zoom-pan-pinch";

const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();

    return (
        <div style={{position: 'fixed', right: '50px', top: '100px'}}>
            <button onClick={() => zoomIn()}>+</button>
            <button onClick={() => zoomOut()}>-</button>
            <button onClick={() => resetTransform()}>x</button>
        </div>
    );
};


const MainConsole: React.FC = () => {
    const [map, setMap] = useState<google.maps.Map>()
    const [state, setState] = useRecoilState(mapState)
    const [sidebar, setSidebar] = useRecoilState(sidebarState)
    const [loader, setLoader] = useRecoilState(loaderState)
    const [mapType, setMapType] = useState('satellite')
    const mapRef = useRef();
    const tileCacheRef = useRef<any>({});

    const onMapLoad = useCallback((map: any) => {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        map.setZoom(9)
        setMap(map)

        mapRef.current = map;

        // Custom tile overlay for satellite images
        map.overlayMapTypes.insertAt(
            0,
            new window.google.maps.ImageMapType({
                getTileUrl: (tile, zoom) => {
                    const key = `${zoom}_${tile.x}_${tile.y}`;
                    const url = `https://mt1.google.com/vt/lyrs=s&x=${tile.x}&y=${tile.y}&z=${zoom}`;

                    // Cache the tile
                    if (!tileCacheRef.current[key]) {
                        tileCacheRef.current[key] = url;
                    }

                    const img = new Image();
                    img.src = url;
                    img.onload = () => {
                        tileCacheRef.current[key] = url;
                    };
                    img.onerror = () => {
                        if (tileCacheRef.current[key]) {
                            img.src = tileCacheRef.current[key];
                        }
                    };

                    return img.src;
                },
                tileSize: new window.google.maps.Size(256, 256),
                maxZoom: 20,
                minZoom: 0,
                name: "Satellite Tiles",
            })
        );
    }, []);



    useEffect(() => {
        if (map) {
            map.setCenter(state.center)
            map.setZoom(state.zoom)

        }
    }, [state.center, state.zoom])

    const center = {
        lat: 37.575987740229,
        lng: 126.97677991349349
    };

    const onLoad = React.useCallback(function callback(map: google.maps.Map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        map.setZoom(9)
        setMap(map)
    }, [])


    const handleClickPolygon = useCallback(async (event: google.maps.MapMouseEvent) => {
        try {
            if (sidebar.activeClickPolygon) {
                if (event.latLng) {
                    setLoader(old => ({...old, screenLoad: true}))
                    const addressResult = await getClickAreaAddress(event.latLng.lat(), event.latLng.lng())
                    if (addressResult.status === 200) {
                        const addressDocs = addressResult.data.documents
                        if (isIterableArray(addressDocs)) {
                            const lastAddress = addressDocs[0].address.address_name
                            if (lastAddress) {
                                const polygonResult = await polygonToAddress(lastAddress, {
                                    latitude: event.latLng.lat(),
                                    longitude: event.latLng.lng()
                                })
                                if (polygonResult.success) {
                                    setSidebar(old => ({
                                        ...old,
                                        showRightPolygonSearch: true,
                                        searchPolygonData: {
                                            address: lastAddress,
                                            polygon: polygonResult.polygon.map(poly => ({
                                                latitude: poly[1],
                                                longitude: poly[0]
                                            })),
                                            latLng: polygonResult.latLng
                                        }
                                    }))
                                    setLoader(old => ({...old, screenLoad: false}))
                                    setState(old => ({
                                        ...old,
                                        center: {
                                            lat: polygonResult.latLng.latitude,
                                            lng: polygonResult.latLng.longitude,
                                        },
                                        zoom: 18
                                    }))
                                }
                            }
                        } else {
                            notificationAlert('알림', '등록 불가능한 주소 입니다.')
                            setLoader(old => ({...old, screenLoad: false}))
                        }
                    } else {
                        notificationAlert('알림', '등록 불가능한 주소 입니다.')
                        setLoader(old => ({...old, screenLoad: false}))
                    }
                } else {
                    notificationAlert('알림', '등록 불가능한 주소 입니다.')
                    setLoader(old => ({...old, screenLoad: false}))
                }
            }
        } catch (err) {
            notificationAlert('알림', '등록 불가능한 주소 입니다.')
            setLoader(old => ({...old, screenLoad: false}))
        }
        // setFocusMap(prevState => !prevState);
        // if (isActiveWorkAddMode && !showPolygon) {
        //     getClickAreaAddress(event.latLng.lat(), event.latLng.lng()).then(result => {
        //         if (isIterableArray(result.data.documents)) {
        //             handleSearchLocation(result.data.documents[0].address?.address_name);
        //         }
        //     }).catch(err => {
        //         dispatch(showSnackBarDialog(true, '등록이 불가능한 주소 입니다.'));
        //     })
        // }
    }, [sidebar.activeClickPolygon])


    return (
        <div className={'map_console_main'}>
            {loader.screenLoad &&
                <div className={'screen_loader_main'}>
                    <div className={'loader_box'}>
                        <CircularProgress/>
                    </div>
                </div>}
            <LeftSideBar/>
            <RightPolygonDetailSideBar/>
            <RightPolygonSearchSideBar/>
            <ProjectCreateDialog/>
            <MemberAuthorityChangeDialog/>
            <ProjectMemberAddDialog/>
            <ProjectSettingDialog/>
            <ProjectAddExcelDialog/>
            <WorkStatusChangeDialog/>
            <WorkModifiedDialog/>
            <ProjectWorkAddDialog/>
            <AssignWorkDialog/>
            <WorkerInfoDialog/>
            <TransformWrapper
                initialScale={1}
                initialPositionX={200}
                initialPositionY={100}
            >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <>
                        <Controls />
                        <TransformComponent>

                        </TransformComponent>
                    </>
                )}
            </TransformWrapper>
            <GoogleMap
                onClick={handleClickPolygon}
                onLoad={onMapLoad}
                // onLoad={onLoad}
                onZoomChanged={() => {
                  if(map) {
                      console.log('//zom', map?.getZoom());
                      const newZoom = map.getZoom() ?? 0;
                      if (newZoom > 15) {
                          map.setMapTypeId('satellite');
                      } else {
                          map.setMapTypeId('satellite');
                      }
                  }
                }}
                options={
                    {
                        zoom: state.zoom,
                        minZoom: 9,
                        maxZoom: 20,
                        mapTypeId: 'satellite',
                        mapTypeControl: false,
                        fullscreenControl: false,
                        streetViewControl: false,
                        zoomControl: true,
                    }
                }
                mapContainerStyle={{
                    width: '100%',
                    height: '100vh'
                }}
            >
                <WorkAssignMode/>
                <WorksPolygon/>
                <WorkersMarker/>
            </GoogleMap>
        </div>
    )
}


export default MainConsole