import React, {useEffect, useState} from "react"
import {useRecoilState, useRecoilValue} from "recoil";
import {projectState} from "../atoms";
import {Member} from "../../../model/response/Member";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {getMemberData} from "../../../util/project";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const ProjectMemberTab: React.FC = () => {

    const [state, setState] = useRecoilState(projectState)
    const [sortingMembers, setSortingMembers] = useState<Map<string, Member>>(new Map<string, Member>())

    const getAuthNum = (authority: string) => {
        switch (authority) {
            case '관리자':
                return 4
            case '감독자':
                return 3
            case '방제단장':
                return 3
            case '작업자':
                return 2
            case '관전자':
                return 1
            default:
                return 1
        }
    }

    const handleClickMember = (id: string, authority: string) => {
        setState(old => ({
            ...old,
            showMemberChangeAuthDialog: !old.showMemberChangeAuthDialog,
            memberChangeAuthority: {id, authority}
        }))
    }

    useEffect(() => {

        let arrayMembers = Object.entries(state.projectDetail.members)
        arrayMembers = arrayMembers.sort((a, b) => {
            return getAuthNum(b[1].authority) - getAuthNum(a[1].authority)
        })

        setSortingMembers(new Map([...arrayMembers]))


    }, [state.projectDetail.members])

    const handleClickOpenMemberAdd = () => {
        setState(old => ({...old, showAddMemberDialog: !old.showAddMemberDialog}))
    }

    return (
        <div className={'project_member_tab_main project_tab_main'}>
            <div style={{padding: '0.5rem 0'}} className={'flex_row_between'}>
                <small>프로젝트 참여자: 총 <strong>{state.projectDetail.memberIds.length}명</strong></small>
                <button onClick={handleClickOpenMemberAdd} className={'add_project_member_btn'}>멤버 추가</button>
                {/*<button className={'project_out_btn'}>프로젝트 탈퇴</button>*/}

            </div>
            <ul style={{padding: '0.5rem 0', marginBottom: '3rem'}}>
                {[...sortingMembers.entries()].map(([key, value]) => {

                    const memberData: Member = value as Member
                    const userData = getMemberData(key, state.projectMembers)
                    return (
                        <li onClick={() => handleClickMember(key, memberData.authority)} key={key}>
                            <div className={'project_member_box flex_row_between'}>
                                <div className={'member_info'}>
                                    <AccountCircleIcon/>
                                    <div>
                                        <span className={'d-block'}>{userData.name}</span>
                                        <small>{userData.phone}</small>
                                    </div>
                                </div>
                                <div className={'d-flex'}>
                                    <span>{memberData.authority}</span>
                                    <ArrowDropDownIcon/>
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}


export default ProjectMemberTab