import {atom} from 'recoil'
import {UserDataResponse} from "../../model/response/UserDataResponse";
import {v1} from "uuid";

interface IState {
    user: UserDataResponse,
    oldPassword: string,
    newPassword: string,
    confirmNewPassword: string,
    newName: string
}

export const mypageState = atom<IState>({
    key: `mypageState/${v1()}`,
    default: {
        user: {
            userId: '',
            name: '',
            phone: '',
            userType: '',
            enabled: true
        },
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        newName: ''
    }
})
