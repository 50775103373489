import {useEffect, useRef, useState} from "react";
import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import {isIterableArray} from "../../util/common";
import {LatLng} from "../../model/LatLng";
import {useRecoilState, useRecoilValue} from "recoil";
import {sidebarState} from "../sidebar/atoms";
import {loaderState} from "../common/loader/atoms";


interface IProps {
    handleSearchLocation: (address: string) => Promise<boolean | null | undefined>,
    type: 'searchBar' | 'form',
}

export const Debounce: React.FC<IProps> = ({handleSearchLocation, type}) => {
    const {
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = useGoogle({
        apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
        options: {
            types: ['geocode'],
            componentRestrictions: {
                country: 'kr'
            },
            input: ''
        },
        language: 'kr'
    });
    const [value, setValue] = useState("");
    const [show, setShow] = useState(false);
    const searchBarRef = useRef(null);
    const sidebar = useRecoilValue(sidebarState)
    const [loader, setLoader] = useRecoilState(loaderState)

    return (
        <div className='google_search_bar_wrapper no_drag' style={{width: "100%"}}>
            {type === 'searchBar' ?
                <Paper
                    component="form"
                    sx={{p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%'}}
                >
                    <InputBase
                        onFocus={() => setShow(true)}
                        // onBlur={() => setShow(false)}
                        type={'search'}
                        inputRef={searchBarRef}
                        onKeyPress={event => {
                            if (event.key === "Enter") {
                                event.preventDefault()
                            }
                            if (event.code === 'Enter') {
                                event.preventDefault()
                            }
                            if (event.keyCode === 13) {
                                event.preventDefault()
                            }
                        }}
                        value={value}
                        sx={{ml: 1, flex: 1}}
                        placeholder="주소를 입력하여 주세요."
                        onChange={(evt) => {
                            if (!show) setShow(true);
                            if (evt.target.value.length === 0) setShow(false);
                            getPlacePredictions({input: evt.target.value});
                            setValue(evt.target.value);
                        }}
                        inputProps={{'aria-label': 'search google maps'}}
                    />
                    <div style={{padding: '10px'}}>
                        <SearchIcon/>
                    </div>
                </Paper>
                :
                <InputBase
                    onFocus={() => setShow(true)}
                    // onBlur={() => setShow(false)}
                    type={'search'}
                    inputRef={searchBarRef}
                    onKeyPress={event => {
                        if (event.key === "Enter") {
                            event.preventDefault()
                        }
                        if (event.code === 'Enter') {
                            event.preventDefault()
                        }
                        if (event.keyCode === 13) {
                            event.preventDefault()
                        }
                    }}
                    value={sidebar.showRightPolygonSearch ? sidebar.searchPolygonData.address : value}
                    disabled={sidebar.showRightPolygonSearch}
                    sx={{width: 100}}
                    placeholder="주소를 입력하여 주세요."
                    onChange={(evt) => {
                        if (!show) setShow(true);
                        if (evt.target.value.length === 0) setShow(false);
                        getPlacePredictions({input: evt.target.value});
                        setValue(evt.target.value);
                    }}
                    inputProps={{'aria-label': 'search google maps'}}
                />
            }

            <div
                className='search_result_wrapper'
                style={{

                    backgroundColor: 'white',
                    marginTop: "5px",
                    width: "100%",
                    display: "flex",
                    flex: "1",
                    flexDirection: "column",
                }}
            >
                {(!isPlacePredictionsLoading && show) && <div>
                    <ul className={isIterableArray(placePredictions) ? 'place_list_wrapper show_border' : 'place_list_wrapper'}>
                        {value !== "" &&
                            <li
                                onClick={async () => {
                                    setLoader(old => ({...old, screenLoad: true}))
                                    setValue(value);
                                    const searchResult = await handleSearchLocation(value);
                                    if (searchResult)
                                        setShow(false);
                                    setLoader(old => ({...old, screenLoad: false}))
                                }}
                            >
                                <h4>{value}로 검색하기</h4>
                            </li>
                        }
                        {placePredictions.map((place: { description: string; }, index: React.Key | null | undefined) =>
                            <li
                                key={index}
                                onClick={async () => {
                                    setLoader(old => ({...old, screenLoad: true}))
                                    const address = place.description?.replace("대한민국 ", '')
                                    setValue(address);
                                    const searchResult = await handleSearchLocation(address);

                                    if (searchResult)
                                        setShow(false);
                                    setLoader(old => ({...old, screenLoad: false}))
                                }}>
                                <h4>{place.description?.replace("대한민국 ", '')}</h4>
                            </li>)}
                    </ul>

                </div>
                }

            </div>


        </div>
    );
};
