import React from "react";
import {Modal, ModalBody} from "react-bootstrap";
import {ExcelInfo, UploadExcel} from "../../../model/UploadExcel";
import Dialog from "@mui/material/Dialog";
import {useRecoilState} from "recoil";
import {projectState} from "../atoms";


interface IProps {
    excel: ExcelInfo
}


export const WorkExcelModal: React.FC<IProps> = ({excel}) => {


    const [state, setState] = useRecoilState(projectState)

    const handleClose = () => {
        setState(old => ({...old, showExcelResultDialog: !old.showExcelResultDialog}))
    }

    return (
        <Dialog onClose={handleClose} open={state.showExcelResultDialog} className={'work_excel_modal'}>
            <div className='work_excel_modal_content'>
                <div className='work_info_wrapper'>
                    <span className={'fw-bold'}>주소</span>
                    <span> {excel.address}</span>
                </div>
                <div className='work_info_wrapper'>
                    <span className={'fw-bold'}>농장주 이름</span>
                    <span>{excel.farmerName}</span>
                </div>
                <div className='work_info_wrapper'>
                    <span className={'fw-bold'}>농장주 전화번호</span>
                    <span>{excel.farmerPhone}</span>
                </div>
                <div className='work_info_wrapper'>
                    <span className={'fw-bold'}>요청사항</span>
                    <span>{excel.request}</span>
                </div>
                <div className='work_info_wrapper'>
                    <span className={'fw-bold'}>상태</span>
                    <span
                        className={excel.status === '성공' ? 'success' : excel.status === '실패' ? 'failed' : 'suspend'}>{excel.status}</span>
                </div>
                {excel.errorMsg &&
                    <div className='work_info_wrapper'>
                        <span className={'fw-bold'}>실패 사유</span>
                        <span style={{color: 'red'}}>{excel.errorMsg}</span>
                    </div>
                }
                <button onClick={handleClose} className='work_excel_modal_close'>닫기</button>
            </div>
        </Dialog>
    )
}

