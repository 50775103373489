import React from "react"
import {colorSetList, getStatusColor, getWorkStatusToKor} from "../../../../util/common";
import {useRecoilState} from "recoil";
import {sidebarState} from "../../../sidebar/atoms";
import classNames from "classnames";
import {projectState} from "../../atoms";


const DetailMapBox: React.FC = () => {
    const statusList = ['expected', 'progress', 'complete', 'stop', 'except', 'rework']
    const [state, setState] = useRecoilState(sidebarState)
    const [project, setProject] = useRecoilState(projectState)
    const handleToggleFilter = () => {
        setState(old => ({...old, showFilter: !old.showFilter}))
    }

    const handleToggleWorkMode = () => {
        setState(old => ({...old, activeWorkMode: !old.activeWorkMode}))
    }

    const handleClickAssign = () => {
        setState(old => ({...old, activeAssign: !old.activeAssign}))
    }

    return (
        <div className={'detail_map_box_main'}>
            {state.activeWorkMode ?
                <div className={'work_mode_worker_color_list'}>
                    <div className='flex_center color_worker_item'>
                        <div
                            style={{backgroundColor: 'red'}}
                            id={'status_color_circle'}/>
                        미정
                    </div>
                    {project.projectMembers.map((member, index) => {
                        return (
                            <div key={index} className={classNames({
                                'flex_center': true,
                                'color_worker_item': true,
                                'active': state.drawerPolygonData.workerId === member.userId
                            })}>
                                <div
                                    style={{backgroundColor: colorSetList[index]}}
                                    id={'status_color_circle'}/>
                                {member.name}
                            </div>
                        )
                    })}
                </div>
                :
                <div className={'work_status_color_list'}>
                    {statusList.map((status, index) => {
                        return (
                            <div key={index} className='flex_center color_worker_item'>
                                <div
                                    style={{backgroundColor: getStatusColor(getWorkStatusToKor(status))}}
                                    id={'status_color_circle'}/>
                                {getWorkStatusToKor(status)}
                            </div>
                        )
                    })}
                </div>
            }
            <div className={'map_box_btn_main'}>
                <button onClick={handleToggleFilter}>{state.showFilter ? '필터 숨기기' : '필터 보이기'}</button>
                <button
                    className={classNames({
                        'active': state.activeWorkMode
                    })}
                    onClick={handleToggleWorkMode}>{state.activeWorkMode ? '작업 할당모드 끄기' : '작업 할당모드 켜기'}</button>
            </div>
            {state.activeWorkMode && (
                <div className={'assign_btn_main'}>
                    <button
                        className={classNames({
                            'active': state.activeAssign
                        })}
                        onClick={handleClickAssign}> {state.activeAssign ? '영역 지정취소' : '영역 지정하기'}</button>
                </div>)}
        </div>
    )
}

export default DetailMapBox