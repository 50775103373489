import React, {useEffect, useState} from "react"
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {useRecoilState} from "recoil";
import {projectState} from "../atoms";
import {notificationAlert} from "../../../util/alert";
import {updateProjectName, updateProjectStatus} from "../../../repository/ProjectRepository";
import classNames from "classnames";
import Loader from "../../common/loader/Loader";
import {loaderState} from "../../common/loader/atoms";

const ProjectSettingDialog: React.FC = () => {

    const [state, setState] = useRecoilState(projectState)
    const [projectName, setProjectName] = useState<string>('')
    const [projectStatus, setProjectStatus] = useState<string>('진행')
    const [loader, setLoader] = useRecoilState(loaderState)

    useEffect(() => {
        setProjectName(state.projectDetail.name)
        setProjectStatus(state.projectDetail.status)
    }, [state.projectDetail])

    const handleClose = () => {
        if ((!loader.changeProjectName) && (!loader.changeProjectStatus))
            setState(old => ({...old, showProjectSettingDialog: !old.showProjectSettingDialog}))
    }

    const handleOnChangeProjectName = (projectName: string) => {
        setProjectName(projectName)
    }

    const handleChangeProjectName = async () => {
        try {
            setLoader(old => ({...old, changeProjectName: true}))
            const result = await updateProjectName(state.projectId, projectName)
            if (result) {
                setState(old => ({...old, showProjectSettingDialog: false}))
                setLoader(old => ({...old, changeProjectName: false}))
                notificationAlert('알림', '프로젝트 이름이 변경되었습니다.')
            }
        } catch (err) {
            setState(old => ({...old, showProjectSettingDialog: false}))
            setLoader(old => ({...old, changeProjectName: false}))
            notificationAlert('알림', '프로젝트 이름 변경에 문제가 발생하였습니다.')
        }
    }

    const handleChangeProjectStatus = async () => {
        try {
            setLoader(old => ({...old, changeProjectStatus: true}))
            const result = await updateProjectStatus(state.projectId, projectStatus)
            if (result) {
                setState(old => ({...old, showProjectSettingDialog: false}))
                setLoader(old => ({...old, changeProjectStatus: false}))
                notificationAlert('알림', '프로젝트 상태가 변경되었습니다.')
            }
        } catch (err) {
            setState(old => ({...old, showProjectSettingDialog: false}))
            setLoader(old => ({...old, changeProjectStatus: false}))
            notificationAlert('알림', '프로젝트 상태 변경에 문제가 발생하였습니다.')
        }
    }

    return (
        <Dialog onClose={handleClose} open={state.showProjectSettingDialog}>
            {state.projectSettingType === 'name' ?
                <>
                    <DialogTitle>프로젝트 이름 변경</DialogTitle>
                    <div className={'create_project_dialog_main'}>
                        <div className={'dialog_content'}>
                            <input
                                value={projectName}
                                placeholder={'변경하실 프로젝트 이름을 입력하여 주세요.'}
                                onChange={event => handleOnChangeProjectName(event.target.value)}/>
                        </div>
                        <div className={'dialog_button_main'}>
                            {loader.changeProjectName ?
                                <Loader height={45}/>
                                :
                                <>
                                    <button onClick={handleChangeProjectName}>이름 변경</button>
                                    <button onClick={handleClose}>취소</button>
                                </>
                            }
                        </div>
                    </div>
                </>
                :
                <>
                    <DialogTitle>프로젝트 상태 변경</DialogTitle>
                    <div className={'create_project_dialog_main'}>
                        <div className={'dialog_content'}>
                            <div className={'flex_row_between project_status_list'}>
                        <span
                            onClick={() => setProjectStatus('진행')}
                            className={classNames({
                                'active': projectStatus === '진행'
                            })}>진행</span>
                                <span
                                    onClick={() => setProjectStatus('중단')}
                                    className={classNames({
                                        'active': projectStatus === '중단'
                                    })}>중단</span>
                                <span
                                    onClick={() => setProjectStatus('휴식')}
                                    className={classNames({
                                        'active': projectStatus === '휴식'
                                    })}>휴식</span>
                                <span
                                    onClick={() => setProjectStatus('완료')}
                                    className={classNames({
                                        'active': projectStatus === '완료'
                                    })}>완료</span>
                            </div>
                        </div>
                        <div className={'dialog_button_main'}>
                            {loader.changeProjectStatus ?
                                <Loader height={45}/>
                                :
                                <>
                                    <button onClick={handleChangeProjectStatus}>상태 변경</button>
                                    <button onClick={handleClose}>취소</button>
                                </>
                            }
                        </div>
                    </div>
                </>
            }
        </Dialog>
    )
}


export default ProjectSettingDialog