import React, {useEffect, useState} from "react";
import {Col, Modal, ModalBody, Row} from "reactstrap";
import {collection, getDocs, orderBy, query, where} from "firebase/firestore";
import {firestore} from "../../../firebase";
import {CircularProgress} from "@mui/material";
import {notificationAlert} from "../../../util/alert";
import {isIterableArray} from "../../../util/common";
import {CloseIcon} from "../../icon/CloseIcon";


export const UserWorkRecordModal = ({toggle, modal, user}) => {

    const [loader, setLoader] = useState(false);
    const [workRecords, setWorkRecords] = useState([{}]);
    const [userProjects, setUserProjects] = useState([{}]);
    const [userStatic, setUserStatic] = useState([{
        complete: {
            area: 0,
            cnt: 0
        },
        progress: {
            area: 0,
            cnt: 0
        },
        rework: {
            area: 0,
            cnt: 0
        },
        stop: {
            area: 0,
            cnt: 0
        },
        except: {
            area: 0,
            cnt: 0
        }
    }])
    useEffect(() => {
        setLoader(true);
        if (!user) {
            return;
        }
        if (Object.keys(user).length === 0) {
            return;
        }
        getUserProject().then(projects => {
            if (projects) {
                getUserWorkRecord().then(workRecords => {
                    if (isIterableArray(projects)) {
                        const projectIds = projects.map(project => project.id)
                        const enableWorks = workRecords.filter(work => {
                            if (projectIds.includes(work.projectId)) {
                                return work
                            }

                        })
                        projects.forEach(project => {
                            const userStatic = project?.members[user.id]?.status;
                            if (userStatic) {
                                setUserStatic(prevState => [...prevState, userStatic])
                            }
                        })
                        setUserProjects(projects)
                        setWorkRecords(enableWorks);

                        setLoader(false);
                    }


                }).catch(err => {
                    if (err) {
                        notificationAlert('오류','유저 작업 목록을 가져오는데 문제가 발생하였습니다.')
                        setLoader(false);
                    }
                })
            }
        }).catch(err => {
            if (err) {
                notificationAlert('오류','유저 작업 목록을 가져오는데 문제가 발생하였습니다.')
                setLoader(false);
            }
        })

    }, [user])

    const getUserWorkRecord = async () => {
        const q = query(collection(firestore, "works"), where("workerId", "==", user.id));
        const userDocs = await getDocs(q);
        return userDocs.docs.map(doc => ({id: doc.id, ...doc.data()}));
    }

    const getUserProject = async () => {
        const q = query(collection(firestore, "projects"),
            where("enabled", "==", true)
            , where("memberIds", "array-contains", user.id));
        const userDocs = await getDocs(q);
        return userDocs.docs.map(doc => ({id: doc.id, ...doc.data()}));
    }



    return (
        <Modal centered isOpen={modal} toggle={() => toggle({})} className={'user_manage_modal'}>
            <ModalBody className={'work_record_modal_body'}>
                <div className='work_record_admin_list_wrapper'>
                    <div className={'custom_modal_header'}>
                        <p className={'fw-bold'}><span className={'home_color'}>{user.name}</span> 고객 작업 내역</p>
                        <button onClick={() => toggle({})}>
                            <CloseIcon width={20} height={20} fill={'black'}/>
                        </button>
                    </div>
                    {loader ?
                        <div style={{
                            minHeight: '300px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <CircularProgress/>
                        </div>
                        :
                        <>
                            {isIterableArray(workRecords) ?
                                <div>
                                    <span>전체 통계</span>
                                    <div className='admin_user_record_statistics'>
                                        <div className='around_space'>
                                            <span>완료</span>
                                            <span>{userStatic.reduce((acc, cur, idx) => {
                                                return acc += cur.complete.area;
                                            }, 0).toFixed(2)}</span>
                                        </div>
                                        <div className='around_space'>
                                            <span>진행</span>
                                            <span>{userStatic.reduce((acc, cur, idx) => {
                                                return acc += cur.progress.area;
                                            }, 0).toFixed(2)}</span>
                                        </div>
                                        <div className='around_space'>
                                            <span>재작업요청</span>
                                            <span>{userStatic.reduce((acc, cur, idx) => {
                                                return acc += cur.rework.area;
                                            }, 0).toFixed(2)}</span>
                                        </div>
                                        <div className='around_space'>
                                            <span>중단</span>
                                            <span>{userStatic.reduce((acc, cur, idx) => {
                                                return acc += cur.stop.area;
                                            }, 0).toFixed(2)}</span>
                                        </div>
                                        <div className='around_space'>
                                            <span>제외</span>
                                            <span>{userStatic.reduce((acc, cur, idx) => {
                                                return acc += cur.except.area;
                                            }, 0).toFixed(2)}</span>
                                        </div>
                                    </div>
                                    {workRecords.map((workData, index) => {
                                        const projectData = userProjects.filter(project => project.id === workData.projectId);
                                        return (
                                            <Row key={index} className={'admin_user_work_record_item'}>
                                                <Col xs={12}>
                                                    <span>주소</span>
                                                    <p>{workData.address}</p>
                                                </Col>
                                                <Col xs={6}>
                                                    <span>프로젝트 이름</span>
                                                    {isIterableArray(projectData) &&
                                                        <p>{projectData[0].name}</p>
                                                    }

                                                </Col>
                                                <Col xs={6}>
                                                    <span>농주 이름</span>
                                                    <p>{workData.farmerName}</p>
                                                </Col>
                                                <Col xs={6}>
                                                    <span>농주 전화번호</span>
                                                    <p>{workData.farmerPhone}</p>
                                                </Col>
                                                <Col xs={6}>
                                                    <span>면적</span>
                                                    <p>{workData.area ? workData.area.toFixed(2) : 0}</p>
                                                </Col>
                                                <Col xs={6}>
                                                    <span>작업상태</span>
                                                    <p>{workData.workStatus}</p>
                                                </Col>
                                                <Col xs={6}>
                                                    <span>선호작업기종</span>
                                                    <p>{workData.workType}</p>
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                </div>
                                :
                                <div>
                                    <span>작업 내역이 존재하지 않습니다.</span>
                                </div>
                            }
                        </>
                    }
                </div>
            </ModalBody>
        </Modal>
    )
}

