import {atom} from 'recoil'
import {v1} from 'uuid'

interface IState {
    screenLoad: boolean,
    login: boolean,
    register: boolean,
    getAccountInfo: boolean,
    addProject: boolean,
    projectList: boolean,
    projectDetail: boolean,
    rightDetailSidebar: boolean,
    workStatusChange: boolean,
    workInfoChange: boolean,
    workAdd: boolean,
    addProjectMember: boolean,
    memberAuthorityChange: boolean,
    changeProjectName: boolean,
    changeProjectStatus: boolean,
    myPageDetail: boolean,
    changeUserName: boolean,
    changeUserPassword: boolean,
    assignWorks: boolean
}

export const loaderState = atom<IState>({
    key: `loaderState/${v1()}`,
    default: {
        screenLoad: false,
        login: false,
        register: false,
        getAccountInfo: false,
        addProject: false,
        projectList: false,
        projectDetail: false,
        rightDetailSidebar: false,
        workStatusChange: false,
        workInfoChange: false,
        workAdd: false,
        addProjectMember: false,
        memberAuthorityChange: false,
        changeProjectName: false,
        changeProjectStatus: false,
        myPageDetail: false,
        changeUserName: false,
        changeUserPassword: false,
        assignWorks: false
    }
})