import {firestore, functions} from "../firebase";
import {addDoc, collection, doc, getDoc, Timestamp, updateDoc} from "firebase/firestore";
import {NHAdmin} from "../model/NHAdmin";
import {AccountBanks, AccountCosts, AccountResponse} from "../model/response/AccountBank";
import {BasicResponse} from "../model/response/BasicResponse";
import {httpsCallable} from "firebase/functions";
import {FirebaseError} from "firebase/app";
import {LoginResponse} from "../model/response/LoginResponse";
import {UserDataResponse} from "../model/response/UserDataResponse";

const USERS_COLLECTION_NAME = "users"


export const getAccount = async (): Promise<AccountResponse> => {
    const bankRef = doc(firestore, 'payment', 'banks')
    const costRef = doc(firestore, 'payment', 'costs')

    const bankSnap = await getDoc(bankRef)
    const costSnap = await getDoc(costRef)

    if (bankSnap.exists() && costSnap.exists()) {
        const bank = bankSnap.data() as AccountBanks
        const cost = costSnap.data() as AccountCosts
        return {
            banks: bank,
            costs: cost
        }
    } else {
        throw Error('계좌 정보를 가져오지 못했습니다.')
    }

}

export const verifyPhone = async (phone: string, code: string): Promise<boolean> => {
    try {
        const verifyPhone = httpsCallable(functions, 'verifyPhone');
        const result = await verifyPhone({phone: phone, code: code})
        const response = result.data as BasicResponse
        if (response.success) {
            return true
        } else {
            throw Error(response.message)
        }
    } catch (err) {
        if (err instanceof FirebaseError) {
            if (err.code === 'functions/aborted') {
                throw Error('이미 가입된 번호입니다.')
            } else {
                throw Error('문자 서비스에 문제가 발생했습니다. 잠시 후 이용해주세요.')
            }
        } else {
            throw Error('서버에 문제가 발생했습니다. 잠시 후 이용해주세요.')
        }
    }
}

export const register = async (phone: string, name: string, type: string, nhAdmin?: NHAdmin): Promise<string> => {
    const docRef = await addDoc(collection(firestore, USERS_COLLECTION_NAME), {
        phone: phone,
        name: name,
        userType: type,
        enabled: true,
        created: Timestamp.now(),
        expireDate: null,
        fcmToken: null,
        nhAdmin: nhAdmin || null
    });

    if (docRef.id) {
        //TODO : 승인 요청 문자 보내기
        return docRef.id;
    } else {
        throw Error("회원가입에 문제가 발생했습니다.")
    }
};

export const login = async (phone: string, password: string): Promise<string> => {
    try {
        const verifyPhone = httpsCallable(functions, 'adminLogin');
        const result = await verifyPhone({phone: phone, password: password})
        const response = result.data as LoginResponse
        if (response.success) {
            return response.userId
        } else {
            throw Error(response.message)
        }
    } catch (err) {
        if (err instanceof FirebaseError) {
            if (err.code === 'functions/aborted') {
                throw Error('가입 대기 중입니다.')
            } else if (err.code === 'functions/unauthenticated') {
                throw Error('아이디 비밀번호를 확인해주세요.')
            } else if (err.code === 'functions/permission-denied') {
                throw Error('권한이 없어 접근이 불가능합니다.');
            } else {
                throw Error('서버에 문제가 발생했습니다. 잠시 후 이용해주세요.')
            }
        } else {
            throw Error('서버에 문제가 발생했습니다. 잠시 후 이용해주세요.')
        }
    }
}

export const getUser = async (
    userId: string
): Promise<UserDataResponse> => {
    const userRef = doc(firestore, USERS_COLLECTION_NAME, userId)
    const userDoc = await getDoc(userRef)
    if (userDoc.exists()) {
        const user = {userId: userDoc.id, ...userDoc.data()} as UserDataResponse
        return user
    } else {
        throw Error('유저 정보를 가져오지 못했습니다.')
    }
}

export const updateName = async (userId: string, newName: string): Promise<boolean> => {
    const userRef = doc(firestore, USERS_COLLECTION_NAME, userId)
    await updateDoc(userRef, {
        name: newName
    })
    return true
}

export const updatePassword = async (
    userId: string,
    oldPassword: string,
    newPassword: string
): Promise<boolean> => {
    try {
        const changePassword = httpsCallable(functions, 'password');
        const result = await changePassword({
            id: userId,
            oldPassword: oldPassword,
            newPassword: newPassword
        })
        const response = result.data as BasicResponse

        if (response.success) {
            return true
        } else {
            throw Error('서버에 문제가 발생했습니다. 잠시 후 이용해주세요.')
        }

    } catch (err) {
        if (err instanceof FirebaseError) {
            console.error('code', err.code)
            if (err.code === 'functions/not-found') {
                throw Error('존재하지 않는 유저입니다.')
            } else if (err.code === 'functions/unauthenticated') {
                throw Error('이전 비밀번호를 확인해주세요.')
            } else {
                throw Error('서버에 문제가 발생했습니다. 잠시 후 이용해주세요.')
            }
        } else {
            throw Error('서버에 문제가 발생했습니다. 잠시 후 이용해주세요.')
        }
    }
}

export const resetPassword = async (
    phone: string
): Promise<boolean> => {
    try {
        const resetPassword = httpsCallable(functions, 'resetPwd');
        const result = await resetPassword({
            phone: phone
        })
        const response = result.data as BasicResponse

        if (response.success) {
            return true
        } else {
            throw Error('서버에 문제가 발생했습니다. 잠시 후 이용해주세요.')
        }

    } catch (err) {
        if (err instanceof FirebaseError) {
            console.error('code', err.code)
            if (err.code === 'functions/invalid-argument') {
                throw Error('문자 서비스에 문제가 발생했습니다. 잠시 후 이용해주세요.')
            } else {
                throw Error('서버에 문제가 발생했습니다. 잠시 후 이용해주세요.')
            }
        } else {
            throw Error('서버에 문제가 발생했습니다. 잠시 후 이용해주세요.')
        }
    }

}
