import React, {useState} from "react"
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {useRecoilState} from "recoil";
import {projectState} from "./atoms";
import {createProject} from "../../repository/ProjectRepository";
import {getUserId} from "../../util/auth";
import {notificationAlert} from "../../util/alert";
import {loaderState} from "../common/loader/atoms";
import Loader from "../common/loader/Loader";

const ProjectCreateDialog: React.FC = () => {

    const [state, setState] = useRecoilState(projectState)
    const [loader, setLoader] = useRecoilState(loaderState)
    const [projectName, setProjectName] = useState<string>('')

    const handleClose = () => {
        if (!loader.addProject)
            setState(old => ({...old, showCreateDialog: !old.showCreateDialog}))
    }

    const handleChangeProjectName = (projectName: string) => {
        setProjectName(projectName)
    }

    const handleCreateProject = async () => {
        try {
            const userId = getUserId()
            if (userId) {
                setLoader(old => ({...old, addProject: true}))
                const result = await createProject(projectName, userId)
                if (result) {
                    setState(old => ({...old, showCreateDialog: false}))
                    setLoader(old => ({...old, addProject: false}))
                    notificationAlert('알림', '프로젝트가 생성되었습니다.')
                }
            }
        } catch (err) {
            setState(old => ({...old, showCreateDialog: false}))
            setLoader(old => ({...old, addProject: false}))
            notificationAlert('알림', '프로젝트 생성에 문제가 발생하였습니다.')
        }
    }


    return (
        <Dialog onClose={handleClose} open={state.showCreateDialog}>
            <DialogTitle>프로젝트 생성</DialogTitle>
            <div className={'create_project_dialog_main'}>
                <div className={'dialog_content'}>
                    <input
                        placeholder={'생성하실 프로젝트 이름을 입력하여 주세요.'}
                        onChange={event => handleChangeProjectName(event.target.value)}/>
                </div>
                <div className={'dialog_button_main'}>
                    {loader.addProject ?
                        <Loader height={45}/>
                        :
                        <>
                            <button onClick={handleCreateProject}>생성</button>
                            <button onClick={handleClose}>취소</button>
                        </>
                    }
                </div>
            </div>
        </Dialog>
    )
}


export default ProjectCreateDialog