import React, {useCallback, useEffect, useState} from "react";
import pesticideIcon from "../../assets/icon/pesticide.svg";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import {notificationAlert} from "../../util/alert";
import Swal from "sweetalert2";
import {
    checkProjectAdmin,
    checkProjectMedicines,
    createProjectNewMedicine,
    deleteProjectNewMedicine, getMedicineUUID, getProjectNewMedicines, searchMedicine,
    updateProjectNewMedicine
} from "../../repository/ProjectRepository";
import {Pagination} from "@mui/material";
import {Medicine} from "../../model/Medicine";
import {useLocation, useSearchParams} from "react-router-dom";
import {SearchMedicine} from "../../model/response/SearchMedicineResponse";
import {extractPercentValue, isIterableArray} from "../../util/common";
import {NewMedicine} from "../../model/NewMedicine";
import {downloadMedicineExcel} from "../../repository/FunctionRepository";
import {getUserId} from "../../util/auth";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    adminProjectId?: string
    adminUUID?: string
    userId?: string
    authority?: string
}

const MedicinePage: React.FC<IProps> = ({adminProjectId, adminUUID, userId, authority}) => {
    const [params, setParms] = useSearchParams();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [page, setPage] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [isProjectAdmin, setIsProjectAdmin] = useState<boolean>(false);
    const [mode, setMode] = useState<'create' | 'modify'>('create');
    const [name, setName] = useState<string>('');
    const [amount, setAmount] = useState<string>('');
    const [bottle, setBottle] = useState<string>('');
    const [selMedicine, setSelMedicine] = useState<SearchMedicine>({
        applyFirstRegDate: '',
        cmpaItmNm: '',
        compName: '',
        cropCd: '',
        cropLrclCd: '',
        cropLrclNm: '',
        cropName: '',
        dilutUnit: '',
        diseaseUseSeq: '',
        diseaseWeedName: '',
        engName: '',
        indictSymbl: '',
        pestiBrandName: '',
        pestiCode: '',
        pestiKorName: '',
        pestiUse: '',
        useName: '',
        useNum: '',
        useSuittime: '',
        wafindex: '',
    });
    const open = Boolean(anchorEl);
    const [access, setAccess] = useState<boolean>(false);
    const [projectMedicineList, setProjectMedicineList] = useState<NewMedicine[]>([]);

    const [searchResults, setSearchResults] = useState<SearchMedicine[]>([]);
    useEffect(() => {
        if (adminProjectId && adminUUID) {
            checkAdminWeb(adminProjectId);
        } else {
            checkWeb(params.get('projectId') || '', params.get('uuid') || '');
        }
    }, [params, adminProjectId, adminUUID])

    const checkAdminWeb = async (projectId: string) => {
        const uuid = await getMedicineUUID();
        checkWeb(projectId, uuid);
        params.set('projectId', projectId);
    }

    useEffect(() => {
        if (openDialog) {
            handleSearchMedicine(page + 1);
        }
    }, [page]);

    const checkWeb = async (projectId: string, uuid: string) => {
        const isOk = await checkProjectMedicines(projectId, uuid);
        if (isOk) {
            setAccess(true);
            await getProjectMedicines();
            const userId = getUserId();
            const isProjectAdmin = await checkProjectAdmin(projectId, userId || '');
            setIsProjectAdmin(isProjectAdmin);
        } else {
            notificationAlert('오류', '잘못된 접근 입니다.');
        }
    }

    const getProjectMedicines = async () => {
        try {
            const result = await getProjectNewMedicines(params.get('projectId') || '');

            if (result) {
                setProjectMedicineList(result);
            }

        } catch (err: any) {
            if (err.message) {
                notificationAlert('오류', err.message);
            } else {
                notificationAlert('오류', `약품 가져오기에 문제가 발생하였습니다.`);
            }
        }
    }

    const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
        setPage(page - 1);
    };
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [openDialog, setOpenDialog] = React.useState(false);

    const handleClickOpenDialog = (mode: 'create' | 'modify', modifyName?: string, modifyAmount?: number) => {

        if (isProjectAdmin) {
            setMode(mode);
            setOpenDialog(true);
            if (mode === 'modify') {
                setName(modifyName || '');
                setAmount(String(modifyAmount) || '')
            }
        } else {
            notificationAlert('오류', '프로젝트 관리자만 설정 가능합니다.');
        }


    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleDeleteMedicine = async (md: NewMedicine) => {

        if (isProjectAdmin) {
            try {
                Swal.fire({
                    title: '정말 해당 약품을 삭제 하시겠습니까?',
                    text: '약품 삭제시 해당 약품에 대한 모든 정보가 삭제됩니다.',
                    confirmButtonText: '삭제',
                    showCancelButton: true,
                    cancelButtonText: '취소'
                }).then(async result => {
                    if (result.isConfirmed) {
                        const result = await deleteProjectNewMedicine(params.get('projectId') || '', md);
                        if (result) {
                            notificationAlert('알림', '약품이 삭제되었습니다.');
                            const newList = projectMedicineList.filter(m => m.code !== md.code);
                            setProjectMedicineList([...newList]);
                            setAnchorEl(null);
                        }
                    }
                })
            } catch (err: any) {
                if (err) {
                    if (err.message) {
                        notificationAlert('오류', err.message)
                    } else {
                        notificationAlert('오류', '약품 삭제에 문제가 발생하였습니다.');
                    }
                }
            }
        } else {
            notificationAlert('오류', '프로젝트 관리자만 설정 가능합니다.');
        }
    }
    const createOrUpdateMedicine = async () => {
        try {
            if (amount === '') {
                notificationAlert('알림', '사용량을 입력하여 주세요.');
                return;
            }
            if (selMedicine.wafindex === '') {
                notificationAlert('알림', '약품을 선택하여 주세요.');
                return;
            }
            if (mode === 'create') {
                const result = await createProjectNewMedicine(params.get('projectId') || '', Number(amount), Number(bottle), selMedicine);
                if (result) {
                    notificationAlert('알림', '약품이 추가되었습니다.');
                    setOpenDialog(false);
                    setName('');
                    setAmount('');
                    setSelMedicine({
                        applyFirstRegDate: '',
                        cmpaItmNm: '',
                        compName: '',
                        cropCd: '',
                        cropLrclCd: '',
                        cropLrclNm: '',
                        cropName: '',
                        dilutUnit: '',
                        diseaseUseSeq: '',
                        diseaseWeedName: '',
                        engName: '',
                        indictSymbl: '',
                        pestiBrandName: '',
                        pestiCode: '',
                        pestiKorName: '',
                        pestiUse: '',
                        useName: '',
                        useNum: '',
                        useSuittime: '',
                        wafindex: '',
                    });
                    setSearchResults([]);
                    setProjectMedicineList([...projectMedicineList, {
                        code: selMedicine.pestiCode + '-' + selMedicine.diseaseUseSeq,
                        name: selMedicine.pestiKorName,
                        amount: Number(amount),
                        bottle: Number(bottle),
                        reg_cpnt: extractPercentValue(selMedicine.engName),
                    }])
                }
            }
        } catch (err: any) {
            if (err.message) {
                notificationAlert('오류', err.message)
            } else {
                notificationAlert('오류', `약품 ${mode === 'create' ? '생성' : '수정'}에 문제가 발생하였습니다.`);
            }
        }
    }


    const handleSearchMedicine = async (page?: number) => {
        try {

            if (name === '') {
                notificationAlert('알림', '상표명을 입력하여 주세요.');
                return;
            }
            const result = await searchMedicine(page || 1, name);

            if (result) {
                if (isIterableArray(result.list)) {
                    setTotalCount(result.total);
                    setSearchResults(result.list);
                } else {
                    notificationAlert('알림', '존재하는 약품이 없습니다.');
                }
            }

        } catch (err: any) {
            if (err.message) {
                notificationAlert('오류', err.message)
            } else {
                notificationAlert('오류', `약품 검색에 문제가 발생하였습니다.`);
            }
        }
    }


    const handleDownExcel = async () => {
        try {
            const {value: aircraft} = await Swal.fire({
                title: '항공기를 선택하여 주세요.',
                input: 'select',
                inputOptions: {
                    '17': '무인동력비행장치: 무인멀티콥터',
                    '16': '무인동력비행장치: 무인헬리콥터',
                    '15': '무인동력비행장치: 무인비행기',
                    '14': '경량항공기: 동력패러슈트',
                    '13': '경량항공기: 자이로플레인',
                    '12': '경량항공기: 헬리콥터',
                    '11': '경량항공기: 비행기',
                    '10': '항공기: 무인비행선',
                    '09': '항공기: 무인멀티콥터',
                    '08': '항공기: 무인헬리콥터',
                    '07': '항공기: 무인비행기',
                    '06': '항공기: 동력패러슈트',
                    '05': '항공기: 자이로플레인',
                    '04': '항공기: 활공기',
                    '03': '항공기: 비행선',
                    '02': '항공기: 헬리콥터',
                    '01': '항공기: 비행기',
                },
                confirmButtonText: '선택',
                confirmButtonColor: 'green',
                inputPlaceholder: '항공기를 선택하여 주세요.',
                showCancelButton: true,
            });

            if (aircraft) {
                // alert(`선택됨 : ${aircraft}`);
                const userId = params.get('userId') || undefined;
                await downloadMedicineExcel(params.get('projectId') as string, Number(aircraft), userId);
            }
        } catch (err: any) {
            if (err.message) {
                notificationAlert('오류', err.message)
            } else {
                notificationAlert('오류', `엑셀 다운에 문제가 발생하였습니다.`);
            }
        }
    }


    return (
        <div className={`medicine_wrapper ${!access && 'hidden'}`}>

            <Dialog
                fullScreen
                open={openDialog}
                onClose={handleCloseDialog}
                TransitionComponent={Transition}
            >
                <AppBar sx={{position: 'relative', backgroundColor: '#FFF', boxShadow: 'none'}}>
                    <Toolbar sx={{position: 'relative'}}>
                        <IconButton
                            className={'back_btn'}
                            edge="start"
                            onClick={handleCloseDialog}
                            aria-label="close"
                        >
                            <NavigateBeforeIcon/>
                        </IconButton>
                        <Typography sx={{color: 'black', textAlign: 'center', width: '100%'}} variant="h6"
                                    component="div">
                            약품추가
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className={'add_medicine_wrapper'}>
                    <div className={'form_wrap'}>
                        <label htmlFor={'medicine_amount'}>
                            약품 총 사용량
                            <div className={'form_search'}>
                                <input onChange={e => {
                                    const numericRegex: RegExp = /^[0-9]+$/;
                                    if (numericRegex.test(e.target.value)) {
                                        setAmount(e.target.value)
                                    } else {
                                        setAmount('')
                                    }
                                }} value={amount}
                                       placeholder={'L단위로 입력하여 주세요.'} type={'text'} id={'medicine_amount'}/>
                            </div>
                        </label>
                        <label htmlFor={'medicine_bottle'}>
                            약품 각병 당 용량
                            <div className={'form_search'}>
                                <input onChange={e => {
                                    const numericRegex: RegExp = /^[0-9]+$/;
                                    if (numericRegex.test(e.target.value)) {
                                        setBottle(e.target.value)
                                    } else {
                                        setBottle('');
                                    }
                                }} value={bottle}
                                       placeholder={'ml단위로 입력하여 주세요.'} type={'text'} id={'medicine_bottle'}/>
                            </div>
                        </label>
                    </div>
                    <div className={'form_wrap'}>
                        <label htmlFor={'medicine_name'}>
                            약품명
                            <div className={'form_search'}>
                                <input onChange={e => setName(e.target.value)}
                                       value={name}
                                       placeholder={'상표명을 입력하여 주세요.'} type={'text'} id={'medicine_name'}/>
                                <button type={'button'} onClick={() => handleSearchMedicine()}>검색</button>
                            </div>
                        </label>
                    </div>
                    {selMedicine.wafindex !== '' && <div className={'form_wrap'}>
                        <label htmlFor={'medicine_sel'}>
                            선택된 약품
                            <div className={'form_search'}>
                                <input
                                    disabled
                                    value={selMedicine.pestiKorName}
                                    type={'text'} id={'medicine_sel'}/>
                            </div>
                        </label>
                    </div>}
                </div>
                <List
                    className={'no_scroll'}
                    style={{
                        height: 'calc(100vh - 540px)',
                        overflowY: 'scroll'
                    }}>
                    <Divider/>
                    {searchResults.map(md => <>
                        <ListItem button>
                            <ListItemText
                                primary={md.pestiKorName}
                                secondary={<ul className={'medicine_info_list'}>
                                    <li>용도 : {md.useName}</li>
                                    <li>작물명 : {md.cropName}</li>
                                    <li>적용병해충 : {md.diseaseWeedName}</li>
                                    <li>주성분함량 : {md.engName}</li>
                                    <li>희석배수/사용량 : {md.dilutUnit}</li>
                                    <li>회사명 : {md.compName}</li>
                                </ul>}
                            />
                            <button onClick={() => setSelMedicine(md)}
                                    className={`select_medicine_btn `}>
                                선택
                            </button>
                        </ListItem>
                        <Divider/>
                    </>)}
                </List>
                <div className={'pagination_wrapper'}>
                    <Pagination onChange={handleChangePage} page={page + 1}
                                count={Math.ceil(totalCount / 50)}
                                shape="rounded"/>
                </div>
                <div className={'dialog_bottom_btn'}>
                    <button onClick={createOrUpdateMedicine}>약품추가</button>
                </div>
            </Dialog>

            <div className={'page_title_wrapper'}>
                <h2 id={'page_title'}>프로젝트 약품목록</h2>
                <div>
                    <button className={'add_medicine'} onClick={() => handleClickOpenDialog('create')}>약품추가</button>
                    <button className={'down_excel'} onClick={handleDownExcel}>엑셀다운</button>
                </div>
            </div>

            <ul style={{
                height: adminProjectId ? 'auto' : 'calc(100vh - 80px)'
            }} className={'medicine_list no_scroll'}>
                {projectMedicineList.map(md => <li className={'medicine_item'}>
                    <img className={'icon_img'} src={pesticideIcon} alt={''}/>
                    <div className={'medicine_info'}>
                        <h4>{md.name}</h4>
                    </div>
                    <div className={'medicine_use'}>
                        <p>사용량</p>
                        <p>{md.amount.toLocaleString()}L</p>
                    </div>
                    <div>
                        {/*<IconButton*/}
                        {/*    aria-label="more"*/}
                        {/*    id="long-button"*/}
                        {/*    aria-controls={open ? 'long-menu' : undefined}*/}
                        {/*    aria-expanded={open ? 'true' : undefined}*/}
                        {/*    aria-haspopup="true"*/}
                        {/*    onClick={handleClick}*/}
                        {/*>*/}
                        {/*    <MoreVertIcon/>*/}
                        {/*</IconButton>*/}
                        <button onClick={() => handleDeleteMedicine(md)}
                                style={{color: 'red', background: 'none', border: 'none'}}>
                            삭제
                        </button>
                        {/*<Menu*/}
                        {/*    id="long-menu"*/}
                        {/*    MenuListProps={{*/}
                        {/*        'aria-labelledby': 'long-button',*/}
                        {/*    }}*/}
                        {/*    anchorEl={anchorEl}*/}
                        {/*    open={open}*/}
                        {/*    onClose={handleClose}*/}
                        {/*>*/}
                        {/*    <MenuItem onClick={() => handleDeleteMedicine(md)}>*/}
                        {/*        <span style={{color: 'red'}}>*/}
                        {/*        삭제*/}
                        {/*        </span>*/}
                        {/*    </MenuItem>*/}
                        {/*</Menu>*/}
                    </div>
                </li>)}
            </ul>
        </div>
    )
}


export default MedicinePage;

