import {Timestamp} from "firebase/firestore";
import {defaultWorkStatus, WorkStatus} from "../WorkStatus";

export type Member = {
    authority: string
    registerTime: Timestamp
    status: WorkStatus
}

export function defaultMember(authority: string): Member {
    return {
        authority: authority,
        registerTime: Timestamp.now(),
        status: defaultWorkStatus
    }
}

