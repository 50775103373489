import React, {useEffect} from "react"
import Drawer from '@mui/material/Drawer';
import {useRecoilState, useRecoilValue} from "recoil";
import {defaultWorkModel, sidebarState} from "../atoms";
import {getStatusColor} from "../../../util/common";
import CloseIcon from '@mui/icons-material/Close';
import WorkLogList from "./WorkLogList";
import Swal from "sweetalert2";
import {deleteProject, deleteWork, getWorkRecord} from "../../../repository/ProjectRepository";
import {notificationAlert} from "../../../util/alert";
import {projectState} from "../../project/atoms";
import {loaderState} from "../../common/loader/atoms";
import Loader from "../../common/loader/Loader";

const RightPolygonDetailSideBar: React.FC = () => {

    const [state, setState] = useRecoilState(sidebarState)
    const project = useRecoilValue(projectState)
    const [loader, setLoader] = useRecoilState(loaderState)

    useEffect(() => {
        if (state.drawerPolygonData.workId.length !== 0)
            getWorkRecords(state.drawerPolygonData.workId)
    }, [state.drawerPolygonData.workId])

    const getWorkRecords = async (workId: string) => {
        try {
            setLoader(old => ({...old, rightDetailSidebar: true}))
            const getResult = await getWorkRecord(workId)
            if (getResult) {
                setState(old => ({...old, projectWorkRecords: getResult}))
                setLoader(old => ({...old, rightDetailSidebar: false}))
            }

        } catch (err) {
            if (err) {
                notificationAlert('오류', '작업 기록을 가져오는데 문제가 발생하였습니다.')
            }
        }
    }


    const handleDrawer = () => {
        setState(old => ({...old, showRightPolygonDetail: false, drawerPolygonData: defaultWorkModel}))
    }

    const handeOpenStatusChangeDialog = () => {
        setState(old => ({...old, showWorkStatusChangeDialog: !old.showWorkStatusChangeDialog}))
    }

    const handleOpenWorkModifyDialog = () => {
        setState(old => ({...old, showWorkModifiedDialog: !old.showWorkModifiedDialog}))
    }

    const handleDeleteWork = () => {
        setState(old => ({...old, showRightPolygonDetail: !old.showRightPolygonDetail}))
        Swal.fire({
            title: '정말 작업을 삭제 하시겠습니까?',
            text: '작업 삭제시 작업에 대한 모든 정보가 삭제됩니다.',
            confirmButtonText: '삭제',
            showCancelButton: true,
            cancelButtonText: '취소'
        }).then(async result => {
            if (result.isConfirmed) {
                const delResult = await deleteWork(project.projectId, state.drawerPolygonData)

                if (delResult) {
                    notificationAlert('알림', '작업이 삭제되었습니다.')
                }
            }
        }).catch(err => {
            if (err) {
                if (err.message) {
                    notificationAlert('오류', err.message)
                } else {
                    notificationAlert('오류', '작업 삭제에 문제가 발생하였습니다.')
                }
            }
        })
    }

    return (
        <Drawer
            variant={'persistent'}
            anchor={'right'}
            open={state.showRightPolygonDetail}
            onClose={handleDrawer}
        >
            <div className={'side_bar_content right_polygon_detail_side_bar'}>
                <button onClick={handleDrawer} className={'close_side_bar_btn'}>
                    <CloseIcon/>
                </button>
                {loader.rightDetailSidebar ? <Loader height={500}/> :
                    <div>
                        <div className={'side_bar_header'}>
                            <h4>{state.drawerPolygonData.address}</h4>
                            <span id={'work_status_info'}
                                  style={{color: getStatusColor(state.drawerPolygonData.workStatus)}}>{state.drawerPolygonData.workStatus}</span>
                        </div>
                        <hr/>
                        <div className={'side_bar_info'}>
                            <div className={'polygon_info'}>
                                <span>농주</span>
                                <span>{`${state.drawerPolygonData.farmerName}(${state.drawerPolygonData.farmerPhone})`}</span>
                            </div>
                            <div className={'polygon_info'}>
                                <span>면적</span>
                                <span> {state.drawerPolygonData.area.toFixed(2)}평</span>
                            </div>
                            <div className={'polygon_info'}>
                                <span>작업자</span>
                                <span> {state.drawerPolygonData.worker ? state.drawerPolygonData.worker : '미정'}</span>
                            </div>
                            <div className={'side_bar_button'}>
                                <button onClick={handeOpenStatusChangeDialog}>작업상태 변경</button>
                                <button onClick={handleOpenWorkModifyDialog}>작업 수정</button>
                            </div>
                            <div className={'delete_work_btn_main'}>
                                <button onClick={handleDeleteWork}>작업 삭제</button>
                            </div>
                        </div>
                        <WorkLogList/>
                    </div>
                }
            </div>
        </Drawer>
    )
}


export default RightPolygonDetailSideBar