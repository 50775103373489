import React, {useEffect} from "react"
import {useRecoilState} from "recoil";
import {projectState} from "../../project/atoms";
import {sidebarState} from "../atoms";
import {getWorkRecord} from "../../../repository/ProjectRepository";
import {notificationAlert} from "../../../util/alert";
import moment from "moment";
import {getStatusColor, isIterableArray} from "../../../util/common";
import {loaderState} from "../../common/loader/atoms";


const WorkLogList: React.FC = () => {

    const [state, setState] = useRecoilState(sidebarState)


    return (
        <div className={'work_log_list_main'}>
            <span className={'fw-bold'}>작업 현황</span>
            {isIterableArray(state.projectWorkRecords) ?
                <ul>
                    {state.projectWorkRecords.map((record, index) => {
                        return (
                            <li key={index} className={'flex_row_between work_record_item'}>
                                <div className={'flex_column w-100'}>
                                    <div style={{backgroundColor: getStatusColor(record.workStatus)}}
                                         className={'record_status_chip flex_center'}>
                                        <span>{record.workStatus}</span>
                                    </div>
                                    <div>
                                        <span className={'d-block'}>{record.worker}</span>
                                        <div className={'flex_row_between'}>
                                            <div className={'record_date_main'}>
                                                <small>
                                                    {moment.unix(record.createTime?.seconds).format('YYYY-MM-DD HH:mm:ss')}
                                                </small>
                                            </div>
                                            <small>{record.workType}</small>
                                        </div>
                                    </div>
                                    <div className={'work_record_image_list'}>
                                        {record.imageList.map((img, index) => {
                                            return (
                                                <img onClick={() => window.open(img)} key={index} src={img}
                                                     alt={'work_record_image'}/>
                                            )
                                        })}
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
                :
                <div className={'work_record_not_exist'}>
                    <span>작업 기록이 존재하지 않습니다.</span>
                </div>
            }
        </div>
    )
}

export default WorkLogList