import React, {useEffect, useState} from "react"
import Drawer from '@mui/material/Drawer';
import {useRecoilState} from "recoil";
import {sidebarState} from "../atoms";
import CloseIcon from "@mui/icons-material/Close";
import {projectState} from "../../project/atoms";

const RightPolygonSearchSideBar: React.FC = () => {

    const [state, setState] = useRecoilState(sidebarState)
    const [project, setProject] = useRecoilState(projectState)
    const [area, setArea] = useState<number>(0)

    useEffect(() => {
        if (state.showRightPolygonSearch) {
            const areaPolygon: google.maps.LatLng[] = []

            for (const polygon of state.searchPolygonData.polygon) {
                areaPolygon.push(new google.maps.LatLng({lat: polygon.latitude, lng: polygon.longitude}))
            }
            const area = window.google.maps.geometry.spherical.computeArea(areaPolygon);
            const fixedArea = parseFloat(area.toFixed(2))
            setArea(fixedArea)
        }

    }, [state.showRightPolygonSearch, state.searchPolygonData])

    const handleDrawer = () => {
        setState(old => ({
            ...old,
            showRightPolygonSearch: false,
            searchPolygonData: {
                address: '',
                polygon: [],
                latLng: {latitude: 0, longitude: 0}
            }
        }))
    }

    const handleAddProjectWork = () => {
        setProject(old => ({
            ...old,
            showAddProjectWorkDialog: true,
            createWorkForm: {
                ...old.createWorkForm,
                address: state.searchPolygonData.address,
                latLng: state.searchPolygonData.latLng
            }
        }))
    }

    return (
        <Drawer
            variant={'persistent'}
            anchor={'right'}
            open={state.showRightPolygonSearch}
            onClose={handleDrawer}
        >
            <div className={'right_polygon_search_side_bar side_bar_content'}>
                <button onClick={handleDrawer} className={'close_side_bar_btn'}>
                    <CloseIcon/>
                </button>
                <div>
                    <h4>주소 검색 결과</h4>
                </div>
                <div className={'side_bar_header'}>
                    <h4>{state.searchPolygonData.address}</h4>
                    <span>{(area * 0.3025).toFixed(2)}평</span>
                </div>
                <hr/>
                <div className={'add_work_btn_main'}>
                    <button onClick={handleAddProjectWork}>작업 추가</button>
                </div>
            </div>
        </Drawer>
    )
}


export default RightPolygonSearchSideBar