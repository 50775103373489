import React, {useEffect, useState} from "react"
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {useRecoilState} from "recoil";
import {projectState} from "../atoms";
import {deleteProjectMember, updateMemberAuthority} from "../../../repository/ProjectRepository";
import {notificationAlert} from "../../../util/alert";
import Swal from "sweetalert2";
import Loader from "../../common/loader/Loader";
import {loaderState} from "../../common/loader/atoms";


const MemberAuthorityChangeDialog: React.FC = () => {

    const [state, setState] = useRecoilState(projectState)
    const [authority, setAuthority] = useState<string>('')
    const [loader, setLoader] = useRecoilState(loaderState)

    useEffect(() => {
        setAuthority(state.memberChangeAuthority.authority)
    }, [state.memberChangeAuthority.authority])

    const handleClose = () => {
        if (!loader.memberAuthorityChange)
            setState(old => ({...old, showMemberChangeAuthDialog: !old.showMemberChangeAuthDialog}))
    }

    const handleChangeMemberAuthority = async () => {
        try {
            if (state.memberChangeAuthority.authority === authority) {
                setState(old => ({...old, showMemberChangeAuthDialog: !old.showMemberChangeAuthDialog}))
                return
            }
            const adminUser = Object.values(state.projectDetail.members).filter(member => member.authority === '관리자')
            if ((state.memberChangeAuthority.authority === '관리자') && (adminUser.length < 2)) {
                setState(old => ({...old, showMemberChangeAuthDialog: !old.showMemberChangeAuthDialog}))
                notificationAlert('알림', '최소 1명이상 관리자가 필요합니다.')
                return
            }
            setLoader(old => ({...old, memberAuthorityChange: true}))
            const result = await updateMemberAuthority({
                projectId: state.projectId,
                userId: state.memberChangeAuthority.id,
                authority: authority
            })

            if (result) {
                setState(old => ({...old, showMemberChangeAuthDialog: !old.showMemberChangeAuthDialog}))
                setLoader(old => ({...old, memberAuthorityChange: false}))
                notificationAlert('알림', '권한이 변경되었습니다.')
            }
        } catch (err) {
            setLoader(old => ({...old, memberAuthorityChange: false}))
            setState(old => ({...old, showMemberChangeAuthDialog: !old.showMemberChangeAuthDialog}))
            notificationAlert('오류', '멤버 권한 변경에 문제가 발생하였습니다.')
        }
    }


    const handleDeleteProjectMember = () => {
        setState(old => ({...old, showMemberChangeAuthDialog: !old.showMemberChangeAuthDialog}))
        Swal.fire({
            title: '정말 멤버를 삭제 하시겠습니까?',
            text: '멤버 삭제시 모든 멤버에 대한 모든 정보가 삭제됩니다.',
            confirmButtonText: '삭제',
            showCancelButton: true,
            cancelButtonText: '취소'
        }).then(async result => {
            if (result.isConfirmed) {
                const delResult = await deleteProjectMember({
                    projectId: state.projectId,
                    userId: state.memberChangeAuthority.id
                })

                if (delResult) {
                    notificationAlert('알림', '멤버가 삭제되었습니다.')
                }
            }
        }).catch(err => {
            if (err) {
                if (err.message) {
                    notificationAlert('오류', err.message)
                } else {
                    notificationAlert('오류', '프로젝트 멤버 삭제에 문제가 발생하였습니다.')
                }
            }
        })
    }

    return (
        <Dialog onClose={handleClose} open={state.showMemberChangeAuthDialog}>
            <DialogTitle>멤버 권한 변경</DialogTitle>
            <div className={'change_member_authority_dialog_main'}>
                <div className={'dialog_content'}>
                    <div className={'delete_member_btn'}>
                        <button onClick={handleDeleteProjectMember}>
                            멤버 삭제
                        </button>
                    </div>
                    <select onChange={event => setAuthority(event.target.value)} value={authority}>
                        <option value={'관리자'}>관리자</option>
                        <option value={'방제단장'}>방제단장</option>
                        <option value={'작업자'}>작업자</option>
                        <option value={'관전자'}>관전자</option>
                    </select>
                </div>
                <div className={'dialog_button_main'}>
                    {loader.memberAuthorityChange ?
                        <Loader height={45}/>
                        :
                        <>
                            <button onClick={handleChangeMemberAuthority}>권한 변경</button>
                            <button onClick={handleClose}>취소</button>
                        </>
                    }

                </div>
            </div>
        </Dialog>
    )
}


export default MemberAuthorityChangeDialog