import {Route, useNavigate} from "react-router-dom";
import React, {ReactNode, useEffect} from "react";
import {notificationAlert} from "./alert";
import {isLogin} from "./auth";


interface IProps {
    option: boolean,
    children?: React.ReactNode
}

export const RouteIf: React.FC<IProps> = ({option, children}) => {
    const navigate = useNavigate()
    useEffect(() => {
        const isAuth = isLogin();

        if (!isAuth) {
            if (option) {
                notificationAlert('오류', '로그인 후 이용가능한 서비스 입니다.')
                navigate('/login')
            }
        } else {
            if (!option) {
                notificationAlert('오류', '이미 로그인 되어 있습니다.');
                navigate('/')
            }
        }

    }, [option, children])

    return (
        <>
            {children}
        </>
    )
}
