import React, {useEffect, useRef, useState} from "react"
import DnsIcon from '@mui/icons-material/Dns';
import PersonIcon from '@mui/icons-material/Person';
import LeftSideSpace from "./LeftSideSpace";
import {getUserId} from "../../util/auth";
import {useRecoilState, useRecoilValue} from "recoil";
import {defaultWorkModel, sidebarState} from "./atoms";
import {getProjectList} from "../../repository/ProjectRepository";
import {notificationAlert} from "../../util/alert";
import ProjectList from "../project/ProjectList";
import classNames from "classnames";
import ProjectDetail from "../project/detail/ProjectDetail";
import {defaultProjectDetail, projectState} from "../project/atoms";
import {Unsubscribe} from "firebase/firestore";
import MyPageProfile from "../mypage/MyPageProfile";
import LogoutIcon from '@mui/icons-material/Logout';
import {loaderState} from "../common/loader/atoms";
import AdminSetting from "../adminSetting/AdminSetting";
import {mypageState} from "../mypage/atoms";
import {getUser} from "../../repository/AuthRepository";

const LeftSideBar: React.FC = () => {

    const [state, setState] = useRecoilState(sidebarState)
    const [loader, setLoader] = useRecoilState(loaderState)
    const unsub = useRef<Unsubscribe>()
    const [project, setProject] = useRecoilState(projectState)
    const [userInfo, setUserInfo] = useRecoilState(mypageState)


    const handleGetUser = async () => {
        try {
            const userId = getUserId()
            if (userId) {
                setLoader(old => ({...old, myPageDetail: true}))
                const result = await getUser(userId)
                if (result) {
                    setUserInfo(old => ({...old, user: result, newName: result.name}))
                    setLoader(old => ({...old, myPageDetail: false}))
                }
            }
        } catch (err) {
            if (err) {
                setLoader(old => ({...old, myPageDetail: false}))
                notificationAlert('오류', '유저 정보를 가져오는데 문제가 발생하였습니다.')
            }
        }
    }

    useEffect(() => {
        handleGetUser();
        const userId = getUserId()
        if (userId && (state.tabNum === 1)) {
            getUserProjectList(userId)
        }
        if (state.tabNum === 2) {
            if (unsub.current) {
                unsub.current()
            }
        }

        if (state.tabNum === 1) {
            setProject(old => ({
                ...old,
                projectWorks: [],
                filterProjectWorks: [],
                projectDetail: defaultProjectDetail,
                projectId: '',
                workerLatLng: {},
            }))
            setState(old => ({
                ...old,
                showRightPolygonDetail: false,
                drawerPolygonData: defaultWorkModel,
                showRightPolygonSearch: false,
                searchPolygonData: {
                    address: '',
                    polygon: [],
                    latLng: {latitude: 0, longitude: 0}
                }
            }))
        }

        return () => {
            if (unsub.current) {
                unsub.current()
            }
        }
    }, [state.tabNum])


    useEffect(() => {
        if (project.projectId.length !== 0) {
            setState(old => ({...old, tabNum: 2}))
        } else {
            setState(old => ({...old, tabNum: 1}))
        }

    }, [project.projectId])


    const getUserProjectList = (userId: string) => {
        try {
            setLoader(old => ({...old, projectList: true}))
            const unsubscribe = getProjectList(userId, projectList => {
                setState(old => ({...old, projectList: projectList}))
                setLoader(old => ({...old, projectList: old.projectList ? false : false}))
            })
            unsub.current = unsubscribe

        } catch (err: any) {
            notificationAlert('오류', err.message ?? "")
        }
    }

    const handleClickTab = (tabNum: number) => {
        setState(old => ({...old, leftTabNum: tabNum}))
    }

    const logout = () => {
        localStorage.removeItem('bsKy')
        window.location.href = '/'
    }

    return (
        <div className={'side_bar_container'}>
            <nav className={'left_side_bar_main'}>
                <div className={'nav_logo_main'}>
                    <h3>B</h3>
                </div>
                <div className={'nav_bar_item_list'}>
                    <ul>
                        <li
                            className={classNames({
                                'select': state.leftTabNum === 1
                            })}
                            onClick={() => handleClickTab(1)}>
                            <DnsIcon/>
                            프로젝트
                        </li>
                        <li
                            className={classNames({
                                'select': state.leftTabNum === 2
                            })}
                            onClick={() => handleClickTab(2)}>
                            <PersonIcon/>
                            마이페이지
                        </li>
                        {userInfo.user.userType === '관리자' &&
                            <li
                                className={classNames({
                                    'select': state.leftTabNum === 3
                                })}
                                onClick={() => handleClickTab(3)}>
                                <PersonIcon/>
                                관리자설정
                            </li>
                        }
                    </ul>
                </div>
                <div className={'logout_btn_main'}>
                    <button onClick={logout}>
                        <LogoutIcon/>
                        로그아웃
                    </button>
                </div>
            </nav>
            {state.leftTabNum === 1 &&
                <LeftSideSpace>
                    {state.tabNum === 1 && <ProjectList/>}
                    {state.tabNum === 2 && <ProjectDetail/>}
                </LeftSideSpace>
            }
            {state.leftTabNum === 2 &&
                <LeftSideSpace>
                    <MyPageProfile/>
                </LeftSideSpace>
            }
            {state.leftTabNum === 3 &&
                <LeftSideSpace>
                    <AdminSetting/>
                </LeftSideSpace>
            }

        </div>
    )
}

export default LeftSideBar