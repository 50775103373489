import React, {useState} from "react"
import {RecoilRoot} from 'recoil'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import LoginMain from "./component/login/LoginMain";
import Register from "./component/register/Register";
import {isLogin} from "./util/auth";
import {RouteIf} from "./util/RouteIf";
import MainConsole from "./component/main/MainConsole";
import {Libraries, LoadScript} from "@react-google-maps/api";
import {MapLoader} from "./component/main/MapLoader";
import FindPassword from "./component/findPassword/FindPassword";
import Medicine from "component/medicine/Medicine";
import MedicinePage from "component/medicine/Medicine";



const Router: React.FC = () => {

    const [mapLibrary] = useState<Libraries>(['places', 'geometry', 'drawing']);

    return (
        <RecoilRoot>
            <BrowserRouter>
                <LoadScript
                    loadingElement={<MapLoader/>}
                    id="script-loader"
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY ?? ""}
                    libraries={mapLibrary}
                    language="ko"
                    region="US"

                >
                    <Routes>
                        <Route path={"/"} element={isLogin() ?
                            <RouteIf option={true}>
                                <MainConsole/>
                            </RouteIf>
                            :
                            <RouteIf option={false}>
                                <LoginMain/>
                            </RouteIf>}/>
                        <Route path={"/register"} element={<Register/>}/>
                        <Route path={"/findPassword"} element={<FindPassword/>}/>
                        <Route path={"/medicine"} element={<MedicinePage/>}/>

                    </Routes>
                </LoadScript>
            </BrowserRouter>
        </RecoilRoot>
    )
}

export default Router
