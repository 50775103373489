import React, {useEffect, useRef, useState} from "react"
import Dialog from '@mui/material/Dialog';
import {useRecoilState} from "recoil";
import {projectState} from "../atoms";
import {notificationAlert} from "../../../util/alert";
import {createWork} from "../../../repository/ProjectRepository";
import CloseIcon from '@mui/icons-material/Close';
import {Col, Row} from "react-bootstrap";
import excelIcon from '../../../assets/icon/excel.svg';
import arrowIcon from '../../../assets/icon/arrow.svg';
import {CircularProgress} from "@mui/material";
import {getCropCode, isIterableArray} from "../../../util/common";
import * as XLSX from "xlsx";
import {ExcelInfo, ExcelJsonType, UploadExcel} from "../../../model/UploadExcel";
import {Timestamp} from "firebase/firestore";
import {WorkExcelModal} from "./WorkExcelModal";
import {downloadExcelSample} from "../../../repository/FunctionRepository";


interface IFail {
    index: number,
    message: string
}

const ProjectAddExcelDialog: React.FC = () => {

    const [state, setState] = useRecoilState(projectState)
    const [isFinish, setIsFinish] = useState<boolean>(false)
    const [loader, setLoader] = useState<boolean>(false)
    const [fileName, setFileName] = useState<string>("");
    const [jsonData, setJsonData] = useState<UploadExcel[]>([]);
    const [listLoader, setListLoader] = useState<boolean>(false);
    const inputFileRef = useRef<HTMLInputElement>(null);
    const [successIndex, setSuccessIndex] = useState<number[]>([]);
    const [failIndex, setFailIndex] = useState<IFail[]>([]);
    const [excelReview, setExcelReview] = useState<ExcelInfo>({
        address: "",
        errorMsg: "",
        farmerName: "",
        farmerPhone: "",
        index: 0,
        request: "",
        status: ""
    });
    const initState = () => {
        if (inputFileRef.current) {
            setJsonData([]);
            setFileName("");
            setFailIndex([]);
            setSuccessIndex([]);
            setIsFinish(false);
            setLoader(false)
            inputFileRef.current.value = ''
        }
    }


    useEffect(() => {
        if (state.showAddExcelDialog)
            initState()
    }, [state.showAddExcelDialog])


    const handleClose = () => {
        if (loader) {
            notificationAlert('알림', '작업이 모두 종료되지 않았습니다.')
            return
        }
        setState(old => ({...old, showAddExcelDialog: !old.showAddExcelDialog}))
    }

    const toggle = () => {
        setState(old => ({...old, showExcelResultDialog: !old.showExcelResultDialog}))
    }

    const uploadWorks = (e: React.FormEvent<HTMLInputElement>) => {
        //엑셀 파일 등록
        setListLoader(true);
        let files = (e.target as HTMLInputElement).files;
        if (files?.item(0)) {
            const file = files[0];

            if (!checkFileType(file)) {
                setListLoader(false);
                notificationAlert('오류', '엑셀 파일이 아닙니다. .xlsx 확장자로 입력해주세요.')
                return
            }
            const reader = new FileReader();
            reader.onload = (evt) => {
                const bstr = evt.target?.result
                const wb = XLSX.read(bstr, {type: "binary"})
                let jsonArr: UploadExcel[] = []

                if (isIterableArray(wb.SheetNames)) {
                    wb.SheetNames.forEach(wsName => {
                        const ws = wb.Sheets[wsName]
                        const datas = XLSX.utils.sheet_to_json(ws, {header: 0, range: 1})
                        const aCell = ws?.A2?.v
                        const bCell = ws?.B2?.v
                        const cCell = ws?.C2?.v
                        const dCell = ws?.D2?.v
                        const eCell = ws?.E2?.v
                        const titleCell = [aCell, bCell, cCell, dCell,eCell];
                        if (!((titleCell.includes("주소")) && (titleCell.includes("성명")) && (titleCell.includes("전화번호")) && (titleCell.includes("요청사항"))  && (titleCell.includes("농작물명")))) {
                            jsonArr = []
                            setListLoader(false);
                            notificationAlert('오류', `잘못된 양식에 엑셀 파일입니다. '${wsName}' 시트에 양식을 확인하여 주세요.`)
                            return
                        }


                        if (!isIterableArray(datas)) {
                            setListLoader(false);
                            initState();
                            return
                        }

                        datas.forEach((data, index) => {
                            const result = data as ExcelJsonType;
                            if (result["주소"] === "" || result["주소"] === undefined) return;
                            jsonArr.push({
                                index: index,
                                address: result["주소"] || '',
                                farmerName: result["성명"] || '',
                                farmerPhone: result["전화번호"] || '',
                                request: result["요청사항"] || '',
                                cropName: result["농작물명"] || ''
                            })
                        })
                    })
                }
                setJsonData(jsonArr);
                setFileName(file?.name);
                setListLoader(false);
            }

            reader.readAsBinaryString(file);

        }

    }

    const checkFileType = (file: File) => {
        const filename = file.name;
        const _lastDot = filename.lastIndexOf('.');
        const _fileExt = filename.substring(_lastDot, filename.length).toLowerCase();
        return _fileExt === '.xlsx';
    }

    const downloadExcel = async () => {
        const url = await downloadExcelSample();
        window.open(url);
    }

    const uploadExcel = async () => {
        //엑셀 작업 업로드
        if (!isIterableArray(jsonData)) {
            notificationAlert('알림', '엑셀 파일을 등록하여 주세요.')
            return
        }
        setLoader(true);
        for (const json of jsonData) {
            try {

                const result = await createWork({
                    projectId: state.projectId,
                    address: json.address,
                    farmerName: json.farmerName,
                    farmerPhone: String(json.farmerPhone),
                    workType: '드론',
                    workStatus: '예정',
                    enabled: true,
                    uploadFiles: [],
                    request: json.request,
                    created: Timestamp.now(),
                    cropCode: getCropCode(json.cropName),
                    cropName: json.cropName,
                    pnu: ''
                });
                if (result) {
                    setSuccessIndex(prevState => [...prevState, json.index]);
                }
            } catch (err: any) {

                if (err) {
                    if (err.message) {
                        if (err.message === "주소 인식에 실패했습니다. 주소를 확인해주세요.") {
                            setFailIndex(prevState => [...prevState, {
                                index: json.index,
                                message: "주소 인식에 실패했습니다. 주소를 확인해주세요."
                            }]);
                        } else if (err.message === "이미 등록된 주소입니다.") {
                            setFailIndex(prevState => [...prevState, {index: json.index, message: "이미 등록된 주소입니다."}]);
                        } else {
                            setFailIndex(prevState => [...prevState, {
                                index: json.index,
                                message: "서버에 문제가 발생했습니다. 잠시 후 이용해주세요."
                            }]);
                        }
                    } else {
                        setFailIndex(prevState => [...prevState, {
                            index: json.index,
                            message: "서버에 문제가 발생했습니다. 잠시 후 이용해주세요."
                        }]);
                    }
                }
            }
        }
        setLoader(false);
        setIsFinish(true);
        notificationAlert('알림', '작업추가가 모두 완료되었습니다.');
    }


    const handleSelectWork = (index: number, status: string) => {
        let excelReviewData: ExcelInfo = {
            ...jsonData[index],
            status: status,
        }
        if (status === "실패") {
            const findFailData = failIndex.filter(failData => failData.index === index);
            if (isIterableArray(findFailData)) {
                excelReviewData.errorMsg = findFailData[0].message;
            }

        }
        excelReviewData.status = status
        setExcelReview(excelReviewData);
        toggle();
    }


    return (
        <Dialog onClose={handleClose} open={state.showAddExcelDialog}>
            <div className='work_excel_wrapper'>
                <div>
                    <div className='work_excel_title'>
                        <span className={'fw-bold'}>엑셀 파일 등록</span>
                        <div>
                            <button className='down_excel_form_btn' style={{marginRight: '1rem'}}
                                    onClick={initState}>초기화
                            </button>
                            <button className='down_excel_form_btn' style={{marginRight: '1rem'}}
                                    onClick={downloadExcel}>양식 다운로드
                            </button>
                            <button onClick={handleClose} className={'close_side_bar_btn'}>
                                <CloseIcon/>
                            </button>
                        </div>

                    </div>

                    <div className='excel_input_wrapper' onClick={() => inputFileRef.current?.click()}>
                        <div className='ab_ct'>
                            {!isIterableArray(jsonData) ?
                                <>
                                    <img src={excelIcon} alt={'excel_icon'}/>
                                    <span className='mt_txt'>엑셀 파일을 등록해주세요.</span>
                                    <small className={'text-center'}>우측 상단에 양식을 확인하여 양식에 맞는 <br/> 엑셀 파일을 등록하여
                                        주세요.</small>
                                </>
                                :
                                <>
                                    <img src={excelIcon} alt={'excel_icon'}/>
                                    <span className='mt_txt'>{fileName}이 등록되었습니다.</span>
                                    <span>하단에 <strong>추가버튼</strong>을 눌러 추가하여 주세요.</span>
                                </>
                            }

                        </div>
                        <input style={{display: "none"}}
                               onChange={uploadWorks}
                               ref={inputFileRef} type={'file'}/>
                    </div>
                    {listLoader ?
                        <div className='excel_list_loader'>
                            <CircularProgress/>
                        </div>
                        :
                        <>
                            {isIterableArray(jsonData) &&
                                <div className='work_add_list_wrapper'>
                                    {jsonData.map((work, index) => {
                                        const addStatus = successIndex.some(successId => successId === index) ? '성공' :
                                            failIndex.some(failId => failId.index === index) ? '실패' : '대기중'
                                        return (
                                            <Row
                                                onClick={() => handleSelectWork(index, addStatus)}
                                                key={index}
                                                className={successIndex.some(successId => successId === index) ? 'work_add_item success' :
                                                    failIndex.some(failId => failId.index === index) ? 'work_add_item failed' : 'work_add_item suspend'}>
                                                <Col className='p-0 item_address_txt' xs={9}>
                                                    <span>{work?.address}</span>
                                                </Col>
                                                <Col className='p-0 flex_center' xs={2}>
                        <span className='work_status_txt fw-bold'>
                        {addStatus}
                        </span>
                                                </Col>
                                                <Col className='p-0 flex_center' xs={1}>
                                                    <img className='work_item_arrow' src={arrowIcon} alt={'arrow'}
                                                         width={10}/>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                    }
                                </div>
                            }
                        </>
                    }
                </div>
                <div className='excel_add_btn_wrapper'>
                    {isFinish ?
                        <button className='finish_btn' disabled={loader}>
                            <span>작업추가 완료</span>
                        </button>
                        :
                        <button disabled={loader} onClick={uploadExcel}>
                            {loader ?
                                <CircularProgress style={{color: 'white', maxWidth: '20px', maxHeight: '20px'}}/>
                                :
                                <span>작업 추가</span>
                            }

                        </button>
                    }

                </div>
                <WorkExcelModal excel={excelReview}/>
            </div>
        </Dialog>
    )
}


export default ProjectAddExcelDialog
