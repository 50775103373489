import React, {ReactNode} from "react"

type IProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    width: number,
    require: boolean,
    label: string,
    children: ReactNode
}


const ConsoleSelect: React.FC<IProps> = ({width, require, label,children, ...props}: IProps) => {
    return (
        <div style={{textAlign: 'left', marginBottom: '15px', width: `${width}%`}}>
            <label className="console_input">
            <span style={{display: "inline"}}>
                {require &&
                    <span style={{color: "red"}}>* </span>
                }
                <span>{label}</span>
            </span>
                {children}
            </label>
        </div>
    )
}

export default ConsoleSelect