import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {UserManage} from "./UserManage";
import {PriceManage} from "./PriceManage";
import WorkRecordAdmin from "./workRecord/WorkRecordAdmin";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `manage-tab-${index}`,
        'aria-controls': `manage-tabpanel-${index}`,
    };
}

export default function AdminSetting() {
    const [value, setValue] = useState(0);
    const [auth, setAuth] = useState(true);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // useEffect(() => {
    //     isAdminApp().then(async result => {
    //         if (result) {
    //             setAuth(true);
    //         } else {
    //             setAuth(true);
    //         }
    //     }).catch(async err => {
    //         setAuth(false);
    //     })
    // }, [])


    return (
        <Box style={{background: '#FFF'}} sx={{width: '100%'}}>
            {auth ?
                <>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={value}
                              variant="fullWidth"
                              onChange={handleChange}
                              aria-label="manage tap">
                            <Tab label="회원 관리" {...a11yProps(0)} />
                            <Tab label="요금 관리" {...a11yProps(1)} />
                            <Tab label="작업 기록" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <UserManage/>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <PriceManage/>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <WorkRecordAdmin/>
                    </TabPanel>
                </>
                :
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "100vh"
                }}>
                    <h4 className='home_color'>BLUE SKY</h4>
                    <span>관리자만 접근 가능한 기능 입니다.</span>

                </div>
            }
        </Box>
    );
}
