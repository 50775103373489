import React, {useState, useEffect} from "react"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import {useRecoilValue} from "recoil";
import {projectState} from "../atoms";
import {Member} from "../../../model/response/Member";
import {getMemberData} from "../../../util/project";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {getStatusColor, getWorkStatusToKor} from "../../../util/common";
import Paper from '@mui/material/Paper';


const WorkerStatsticsList: React.FC = () => {
    const tableRows = ['expected', 'progress', 'complete', 'stop', 'except', 'rework']
    const state = useRecoilValue(projectState)
    const [sortingMembers, setSortingMembers] = useState<Map<string, Member>>(new Map<string, Member>())

    useEffect(() => {
        let arrayMembers = Object.entries(state.projectDetail.members)

        setSortingMembers(new Map([...arrayMembers]))


    }, [state.projectDetail.members])

    return (
        <div className={'worker_stat_list_main'}>
            <span className={'stat_title'}>작업자별 통계</span>
            <ul className={'stat_list'}>
                {state.projectDetail.memberIds.map((memberId, index) => {
                    const userData = getMemberData(memberId, state.projectMembers);
                    let authority: string | undefined = '';
                    if (sortingMembers.size > 0) {
                        authority = sortingMembers.get(memberId)?.authority;
                    }

                    const workDatas = state.projectWorks.filter(work => (work.projectId === state.projectId) && (work.workerId === memberId));
                    const totalWorks = workDatas.filter(work => (work.workStatus === '완료') || (work.workStatus === '중단') || (work.workStatus === '제외'))
                    const totalArea = totalWorks.map(row => row.area).reduce((acc, cur, idx) => {
                        return acc += cur;
                    }, 0);

                    const totalCnt = totalWorks.length

                    return (
                        <li key={index} className={'worker_stat_item flex_row_between'}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<KeyboardArrowDownIcon/>}
                                    aria-controls="panel-content"
                                    id="panel-header"
                                >
                                    <div className={'flex_column'}>
                                        <div className={'flex_row'}>
                                            <div>
                                                <AccountCircleIcon className={'d-block'}/>
                                                <small>{authority}</small>
                                            </div>

                                            <div style={{marginLeft: '1rem'}}>
                                                <span className={'d-block'}>{userData.name}</span>
                                                <small>{userData.phone}</small>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TableContainer component={Paper} style={{marginTop: '1rem'}}>
                                        <Table size="small" aria-label="a dense table">
                                            <TableHead style={{backgroundColor: '#dedede'}}>
                                                <TableRow>
                                                    <TableCell align="center">상태</TableCell>
                                                    <TableCell align="center">면적</TableCell>
                                                    <TableCell align="center">개수</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {tableRows.map((row) => {
                                                        let statusWorks = [];
                                                        if (row === 'expected') {
                                                            statusWorks = workDatas.filter(wk => (wk.workStatus === '예정') || wk.workStatus === '재작업요청');
                                                        } else {
                                                            statusWorks = workDatas.filter(wk => wk.workStatus === getWorkStatusToKor(row));
                                                        }
                                                        const workArea = statusWorks.map(row => row.area).reduce((acc, cur, idx) => {
                                                            return acc += cur;
                                                        }, 0);
                                                        const workCnt = statusWorks.length
                                                        return (
                                                            <TableRow
                                                                key={row}
                                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                            >
                                                                <TableCell
                                                                    className='fw-bold'
                                                                    align="center">
                                                                    <div className='flex_center'>
                                                                        <div
                                                                            style={{backgroundColor: getStatusColor(getWorkStatusToKor(row))}}
                                                                            id={'status_color_circle'}/>
                                                                        {getWorkStatusToKor(row)}
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className='fw-bold'
                                                                           align="center">{workArea.toFixed(2)}평</TableCell>
                                                                <TableCell className='fw-bold'
                                                                           align="center">{workCnt}개</TableCell>

                                                            </TableRow>
                                                        )
                                                    }
                                                )}
                                                <TableRow
                                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                >
                                                    <TableCell className='fw-bold' align="center">총</TableCell>
                                                    <TableCell className='fw-bold'
                                                               align="center">{totalArea.toFixed(2)}평</TableCell>
                                                    <TableCell className='fw-bold'
                                                               align="center">{totalCnt}개</TableCell>

                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default WorkerStatsticsList