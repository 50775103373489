import React from "react"
import {useRecoilState} from "recoil";
import {findState} from "./atoms";
import {useNavigate} from "react-router-dom";
import Input from "./Input";
import {notificationAlert} from "../../util/alert";
import {resetPassword} from "../../repository/AuthRepository";


const FindPassword: React.FC = () => {

    const [state, setState] = useRecoilState(findState)
    const navigator = useNavigate()


    const handleResetPassword = async () => {
        try {
            const result = await resetPassword(state.phone)

            if (result) {
                notificationAlert('알림', '입력하신 핸드폰 번호로 임시 비밀번호가 전송되었습니다.')
                setTimeout(() => {
                    navigator('/')
                }, 1500)
            }
        } catch (err: any) {
            if (err) {
                if (err.message) {
                    notificationAlert('오류', err.message)
                } else {
                    notificationAlert('오류', '비밀번호 재설정에 문제가 발생하였습니다.')
                }
            }

        }
    }

    return (
        <div className={'login_main find_password_main'}>
            <div className='login_box'>
                <h3 id={'box_title'}>비밀번호 찾기</h3>
                <small>핸드폰 번호를 입력하신 후 입력하신 휴대폰 번호로 임시 비밀번호가 전송됩니다. 비밀번호는 꼭 변경하여 주시기 바랍니다.</small>
                <Input type={'text'} name={'phone'} placeholder={'핸드폰 번호를 입력하여 주세요'}/>
                <button onClick={handleResetPassword} disabled={state.phone === ''} className={'reset_pwd_btn'}>비밀번호
                    재설정
                </button>
            </div>
        </div>
    )
}


export default FindPassword