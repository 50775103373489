import {atom} from 'recoil'


interface IState {
    center: { lat: number, lng: number },
    zoom: number,
}

export const mapState = atom<IState>({
    key: 'mapState',
    default: {
        center: {lat: 37.575987740229, lng: 126.97677991349349},
        zoom: 9,
    }
})