import React, {useEffect, useState} from "react";
import {collection, getDocs, Timestamp, query, orderBy, updateDoc, doc} from "firebase/firestore";
import {firestore} from "../../firebase";
import {Col, Row} from "reactstrap";
import moment from "moment";
import {UserManageModal} from "./UserManageModal";
import {BillRecordModal} from "./BillRecordModal";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import CircularProgress from "@mui/material/CircularProgress";
import {isIterableArray} from "../../util/common";
import {notificationAlert} from "../../util/alert";
import {MapLoader} from "./MapLoader";

dayjs.extend(isSameOrBefore)


export const UserManage = () => {
    const [userList, setUserList] = useState([]);
    const [filterList, setFilterList] = useState([]);
    const [searchType, setSearchType] = useState('name');
    const [selectUser, setSelectUser] = useState({});
    const [modal, setModal] = useState(false);
    const [billModal, setBillModal] = useState(false);
    const [selectBillUser, setSelectBillUser] = useState({});
    const [keyList, setKeyList] = useState([{}]);
    const [loader, setLoader] = useState(false);
    const [userLoader, setUserLoader] = useState('');
    useEffect(() => {
        setLoader(true);

        getUsers().then(users => {
            if (isIterableArray(users)) {
                const agreementUser = users.filter(user => !user.expireDate);
                const mainUser = users.filter(user => {
                    if (user.expireDate) {
                        return isExpireDate(user.expireDate.toDate())
                    } else {
                        return false;
                    }
                });

                const expireUser = users.filter(user => {
                    if (user.expireDate) {
                        return !isExpireDate(user.expireDate.toDate())
                    } else {
                        return false;
                    }
                });

                const newKeyList = mainUser.map((user, index) => {
                    return {
                        id: user.id,
                        key: mainUser.length - index
                    }
                })
                setKeyList(newKeyList);
                const resultUserList = [...agreementUser, ...mainUser, ...expireUser]
                setUserList(resultUserList);
                setFilterList(resultUserList);
            }
            setLoader(false);
        }).catch(err => {
            if (err) {
                notificationAlert('오류','유저 목록을 가져오는데 문제가 발생하였습니다.')
                setLoader(false);
            }
        })
    }, [])

    const isExpireDate = (date) => {
        if (!date) return false;
        return dayjs().isSameOrBefore(dayjs(date), "date")
    }


    const getUsers = async () => {
        const q = query(collection(firestore, "users"), orderBy("expireDate", "desc"));
        const userDocs = await getDocs(q);
        return userDocs.docs.map(doc => ({id: doc.id, ...doc.data()}));
    }

    const handleSearchUser = (txt) => {
        if (txt.length === 0) {
            setFilterList(userList);
            return;
        }
        const newInstanceList = userList.filter((instance) =>
            instance[searchType].toLowerCase().includes(txt));
        setFilterList(newInstanceList);
    }

    const toggle = (user) => {
        setSelectUser(user);
        setModal(prevState => !prevState);
    }

    const billToggle = (user) => {
        setSelectBillUser(user)
        setBillModal(prevState => !prevState);
    }

    const changeExpireDate = (userId, date) => {
        const index = userList.findIndex(user => user.id === userId);
        const newUserList = userList;
        newUserList[index].expireDate = date;

        setUserList([...newUserList]);
    }

    const changeUserEnabled = (userId, enabled) => {
        const index = userList.findIndex(user => user.id === userId);
        const newUserList = userList;
        newUserList[index].enabled = !enabled;

        setUserList([...newUserList]);
    }


    const handleClickUseFulUser = async (userId, enabled) => {
        setUserLoader(userId);
        const userRef = doc(firestore, "users", userId);
        try {
            if (enabled) {
                await updateDoc(userRef, {
                    enabled: false
                });
            } else {
                await updateDoc(userRef, {
                    enabled: true
                });
            }
            changeUserEnabled(userId, enabled);
            setUserLoader('');

        } catch (err) {
            setUserLoader('');
            if (err) {
                alert("문제가 발생하였습니다.");
            }
        }
    }

    return (
        <>
            {loader ?
                <MapLoader/>
                :
                <div className='user_manage_wrapper'>
                    <Row className='search_box'>
                        <Col className='p-0' xs={3}>
                            <select onChange={event => setSearchType(event.target.value)}>
                                <option value={'name'}>이름</option>
                                <option value={'phone'}>전화번호</option>
                            </select>
                        </Col>
                        <Col className='p-0' xs={9}>
                            <input
                                style={{padding: '0 0.4rem'}}
                                onChange={event => handleSearchUser(event.target.value)}
                                type={'text'}/>
                        </Col>
                    </Row>
                    <div className='user_list_wrapper'>
                        {filterList.map((user, index) => {
                            const myCount = keyList.filter(key => key.id === user.id);
                            return (
                                <Row key={user.id} className='user_item'>
                                    <Col className='p-0' xs={8}>
                                        <div className='user_desc'>
                                            {isIterableArray(myCount) ?
                                                <span>{`(${myCount[0].key})`} {user.name}</span>
                                                :
                                                <span> {user.name}</span>
                                            }

                                            <small>{user.phone} | {user.userType} {user.nhAdmin && `| (${user.nhAdmin.departmentPhone})`}</small>
                                            {user.nhAdmin &&
                                                <small>{user.nhAdmin.nhname} | {user.nhAdmin.departmentName}</small>}
                                            <small>가입일
                                                : {moment.unix(user.created?.seconds).format('YYYY-MM-DD')}</small>
                                            {user.expireDate ?
                                                <>
                                                    {!isExpireDate(user.expireDate.toDate()) ?
                                                        <small style={{color: 'red'}}>
                                                            유효기간
                                                            : {moment.unix(user.expireDate?.seconds).format('YYYY-MM-DD')} 만료</small>
                                                        :
                                                        <small>유효기간
                                                            : {moment.unix(user.expireDate?.seconds).format('YYYY-MM-DD')}</small>
                                                    }

                                                </>
                                                :
                                                <small style={{color: '#3886ff'}}>가입승인 대기중</small>
                                            }
                                        </div>
                                    </Col>
                                    <Col className='p-0' xs={4}>
                                        <Row>
                                            <Col className='p-0' xs={6}>
                                                <div className='user_manage_btn_wrapper'>
                                                    {user.expireDate ?
                                                        <button onClick={() => toggle(user)}>연장</button>
                                                        :
                                                        <button onClick={() => toggle(user)}>승인</button>
                                                    }

                                                </div>
                                            </Col>
                                            <Col className='p-0' xs={6}>
                                                <div className='user_manage_btn_wrapper'>
                                                    <button onClick={() => billToggle(user)}>기록</button>
                                                </div>
                                            </Col>
                                            {user.expireDate &&
                                            <Col className='p-0' xs={12}>
                                                <div className={'user_manage_useful_btn_wrapper'}>
                                                    {userLoader === user.id ?
                                                        <button className={'start_user_btn'}>
                                                            <CircularProgress/>
                                                        </button>
                                                        :
                                                        <>
                                                            {user.enabled ?
                                                                <button
                                                                    onClick={() => handleClickUseFulUser(user.id, user.enabled)}
                                                                    className='stop_user_btn'>이용 중지</button>
                                                                :
                                                                <button
                                                                    onClick={() => handleClickUseFulUser(user.id, user.enabled)}
                                                                    className='start_user_btn'>이용 시작</button>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </Col>
                                            }
                                        </Row>

                                    </Col>

                                </Row>
                            )
                        })}
                    </div>
                    <UserManageModal modal={modal} user={selectUser} toggle={toggle}
                                     changeExpireDate={changeExpireDate}/>
                    <BillRecordModal modal={billModal} toggle={billToggle} user={selectBillUser}/>
                </div>
            }
        </>
    )
}
