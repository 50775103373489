export type WorkStatus = {
    [index: string]: ProjectArea
    progress: ProjectArea
    expected: ProjectArea
    complete: ProjectArea
    stop: ProjectArea
    except: ProjectArea
    rework: ProjectArea
}


export type ProjectArea = {
    area: number
    cnt: number
}

export const defaultWorkStatus: WorkStatus = {
    progress: {
        area: 0,
        cnt: 0
    },
    expected: {
        area: 0,
        cnt: 0
    },
    complete: {
        area: 0,
        cnt: 0
    },
    stop: {
        area: 0,
        cnt: 0
    },
    except: {
        area: 0,
        cnt: 0
    },
    rework: {
        area: 0,
        cnt: 0
    }
}


