import React, {useEffect, useRef, useState} from "react"
import SpaceBase from "../../sidebar/SpaceBase";
import CloseIcon from "@mui/icons-material/Close";
import {useRecoilState} from "recoil";
import {sidebarState} from "../../sidebar/atoms";
import {defaultProjectDetail, IWorkerLatLng, projectState} from "../atoms";
import {
    getProjectDetail,
    getProjectMembers,
    getProjectWorks
} from "../../../repository/ProjectRepository";
import {notificationAlert} from "../../../util/alert";
import {Unsubscribe} from "firebase/firestore";
import {getStatusColor, isIterableArray} from "../../../util/common";
import moment from 'moment'
import ProjectDetailTab from "./ProjectDetailTab";
import classNames from "classnames";
import {WorkResponse} from "../../../model/response/WorkResponse";
import DetailMapBox from "./mapBox/DetailMapBox";
import {loaderState} from "../../common/loader/atoms";
import Loader from "../../common/loader/Loader";


const ProjectDetail: React.FC = () => {

    const [sidebar, setSidebar] = useRecoilState(sidebarState)
    const [project, setProject] = useRecoilState(projectState)
    const [loader, setLoader] = useRecoilState(loaderState)
    const [filterTxt, setFilterTxt] = useState('');
    const [filterType, setFilterType] = useState("주소");
    const [filterWorker, setFilterWorker] = useState('')
    const [projectStatus, setProjectStatus] = useState<string>('전체')
    const [memberIdsState, setMemberIdsState] = useState<string[]>([])
    const unsub = useRef<Unsubscribe>()
    const worksUnsub = useRef<Unsubscribe>()

    useEffect(() => {

        if (sidebar.tabNum === 2) {
            getProject(project.projectId)
        }
        if (sidebar.tabNum === 1) {
            if (unsub.current && worksUnsub.current) {
                unsub.current()
                worksUnsub.current()
            }
        }

        return () => {
            if (unsub.current && worksUnsub.current) {
                unsub.current()
                worksUnsub.current()
            }
        }
    }, [sidebar.tabNum, project.projectId])


    useEffect(() => {
        if (JSON.stringify(memberIdsState) !== JSON.stringify(project.projectDetail.memberIds)) {
            if (isIterableArray(project.projectDetail.memberIds)) {
                setMemberIdsState(project.projectDetail.memberIds)
                getPrMembers(project.projectDetail.memberIds)
            }
        }

    }, [project.projectDetail.memberIds, memberIdsState])


    const getPrMembers = async (memberIds: string[]) => {
        try {
            if (!loader.projectDetail)
                setLoader(old => ({...old, projectDetail: true}))
            const projectMembers = await getProjectMembers(memberIds)
            setProject(old => ({...old, projectMembers: projectMembers}))
            setLoader(old => ({...old, projectDetail: false}))
        } catch (err) {
            notificationAlert('오류', '프로젝트 멤버 데이터를 가져오는데 문제가 발생하였습니다.')
        }
    }

    const getProject = (projectId: string) => {
        try {
            setLoader(old => ({...old, projectDetail: true}))
            const unsubscribe = getProjectDetail(projectId, projectData => {
                if (projectData) {
                    let workerLatLngBase: IWorkerLatLng = {}
                    projectData.memberIds.forEach(id => workerLatLngBase[id] = null)
                    setProject(old => ({...old, projectDetail: projectData, workerLatLng: workerLatLngBase}))
                }
            })
            unsub.current = unsubscribe

            const workUnsubscribe = getProjectWorks(projectId, async worksData => {
                if (worksData) {
                    setProject(old => ({...old, projectWorks: worksData, filterProjectWorks: worksData}))
                }
            })
            worksUnsub.current = workUnsubscribe
        } catch (err) {
            notificationAlert('오류', '프로젝트 정보를 가져오는데 문제가 발생하였습니다.')
        }
    }

    const handleBackTap = () => {
        setSidebar(old => ({...old, tabNum: 1, activeClickPolygon: false}))
        setProject(old => ({...old, projectId: ''}))
    }


    const handleFilterWorks = () => {

        let filterWorkList: WorkResponse[] = project.projectWorks

        if (projectStatus !== '전체') {
            filterWorkList = project.projectWorks.filter(work => work.workStatus === projectStatus)
        }

        if (filterTxt !== '') {
            if (filterType === "주소") {
                filterWorkList = filterWorkList.filter(work => {
                    return work.address.includes(filterTxt)
                })
            } else if (filterType === "성명") {
                filterWorkList = filterWorkList.filter(work => work.farmerName === filterTxt)
            } else {
                filterWorkList = filterWorkList.filter(work => work.farmerPhone === filterTxt)
            }
        }

        if (filterWorker !== '') {
            filterWorkList = filterWorkList.filter(work => work.workerId === filterWorker)
        }

        setProject(old => ({...old, filterProjectWorks: [...filterWorkList]}))

    }

    const handleInitFilter = () => {
        setProject(old => ({...old, filterProjectWorks: [...old.projectWorks]}))
    }


    return (
        <SpaceBase useSearchBar={true} title={project.projectDetail.name}>
            {loader.projectDetail ? <Loader height={500}/> :
                <div className={'project_detail_main'}>
                    <button onClick={handleBackTap} className={'close_side_bar_btn'}>
                        <CloseIcon/>
                    </button>
                    <DetailMapBox/>
                    {sidebar.showFilter &&
                        <div className={'work_filter_main'}>
                            <span>필터</span>
                            <div className='work_mode_filter_input'>
                                <select onChange={event => setFilterType(event.target.value)}>
                                    <option value={'주소'}>주소</option>
                                    <option value={'성명'}>성명</option>
                                    <option value={'전화번호'}>전화번호</option>
                                </select>
                                <input
                                    value={filterTxt}
                                    placeholder={'검색'}
                                    type={'text'}
                                    onChange={event => setFilterTxt(event.target.value)}/>
                            </div>
                            <div>
                                <label>작업 상태</label>
                                <div className={'flex_row_between work_status_list'}>
                             <span
                                 onClick={() => setProjectStatus('전체')}
                                 className={classNames({
                                     'active': projectStatus === '전체'
                                 })}>전체</span>
                                    <span
                                        onClick={() => setProjectStatus('예정')}
                                        className={classNames({
                                            'active': projectStatus === '예정'
                                        })}>예정</span>
                                    <span
                                        onClick={() => setProjectStatus('진행')}
                                        className={classNames({
                                            'active': projectStatus === '진행'
                                        })}>진행</span>
                                    <span
                                        onClick={() => setProjectStatus('중단')}
                                        className={classNames({
                                            'active': projectStatus === '중단'
                                        })}>중단</span>
                                    <span
                                        onClick={() => setProjectStatus('완료')}
                                        className={classNames({
                                            'active': projectStatus === '완료'
                                        })}>완료</span>
                                    <span
                                        onClick={() => setProjectStatus('제외')}
                                        className={classNames({
                                            'active': projectStatus === '제외'
                                        })}>제외</span>
                                    <span
                                        onClick={() => setProjectStatus('재작업요청')}
                                        className={classNames({
                                            'active': projectStatus === '재작업요청'
                                        })}>재작업요청</span>
                                </div>
                            </div>
                            {sidebar.activeWorkMode &&
                                <div>
                                    <label>작업자</label>
                                    <div className={'flex_row_between work_status_list'}>
                                            <span
                                                onClick={() => setFilterWorker('')}
                                                className={classNames({
                                                    'active': filterWorker === ''
                                                })}>미정</span>
                                        {project.projectMembers.map((member, index) => {
                                            return (
                                                <span
                                                    key={index}
                                                    onClick={() => setFilterWorker(member.userId)}
                                                    className={classNames({
                                                        'active': filterWorker === '재작업요청'
                                                    })}>{member.name}</span>
                                            )
                                        })}
                                    </div>
                                </div>
                            }

                            <div className={'work_filter_btn_main'}>
                                <button onClick={handleInitFilter}>초기화</button>
                                <button onClick={handleFilterWorks}>검색</button>
                            </div>
                        </div>
                    }
                    <div className={'project_detail_header'}>
                        <small
                            id={'work_status_info'}
                            style={{color: getStatusColor(project.projectDetail.status)}}>{project.projectDetail.status}</small>
                    </div>
                    <div className={'project_create_time'}>
                        <small id={'project_admin_name'}>{project.projectDetail.adminName}</small>
                        <small>
                            {moment.unix(project.projectDetail.created?.seconds).format('YYYY-MM-DD HH:mm:ss')}
                        </small>
                    </div>
                    <hr/>
                    <div className={'project_detail_content'}>
                        <ProjectDetailTab/>
                    </div>


                </div>
            }
        </SpaceBase>

    )
}


export default ProjectDetail