import React, {useState} from "react"
import {Col, Row} from "react-bootstrap";
import {useRecoilState} from "recoil";
import {registerState} from "./atoms";
import {notificationAlert} from "../../util/alert";
import Input from "./Input";
import {verifyPhone} from "../../repository/AuthRepository";

const AuthPhoneForm: React.FC = () => {
    const [state, setState] = useRecoilState(registerState)
    const [authNum, setAuthNum] = useState<string>('')
    const handleClickSendAuthNum = async () => {
        try {
            if (state.phone.length === 0) {
                notificationAlert('알림', '휴대폰 번호를 입력하여 주세요.')
                return
            }
            const seq = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);
            const result = await verifyPhone(state.phone, seq)

            if (result) {
                setAuthNum(seq)
                setState(old => ({...old, isAuthPhone: true,}))
            }
        } catch (err: any) {
            if (err) {
                if (err.message) {
                    notificationAlert('오류', err.message)
                } else {
                    notificationAlert('오류', '인증번호 전송에 문제가 발생하였습니다.')
                }
            }

        }
    }

    const handleClickVerifyPhone = async () => {
        try {
            if (state.userAuthNum.length === 0) {
                notificationAlert('알림', '인증번호를 입력하여 주세요.')
                return
            }

            if (state.userAuthNum === authNum) {
                setState(old => ({...old, isAuthPhone: false, isSuccessAuthPhone: true}))
            } else {
                notificationAlert('알림', '잘못된 인증번호 입니다. 다시 확인하여 주세요.')
            }

        } catch (err: any) {
            notificationAlert('오류', '인증번호 인증에 문제가 발생하였습니다.')
        }
    }

    return (
        <div className='register_auth_phone_main'>
            <Row className='auth_phone_form_wrapper'>
                <Col className={'p-0'} xs={8}>
                    <Input disabled={state.isSuccessAuthPhone} type="text" name="phone" placeholder="전화번호"/>
                </Col>
                <Col className={'p-0'} xs={4}>
                    <button
                        type={'button'}
                        style={(state.isAuthPhone || state.isSuccessAuthPhone) ? {opacity: 0.5} : {opacity: 1}}
                        disabled={state.isAuthPhone || state.isSuccessAuthPhone}
                        onClick={handleClickSendAuthNum}>인증번호 받기
                    </button>
                </Col>
            </Row>
            {state.isSuccessAuthPhone && <span style={{color: '#1a68d3'}}>휴대폰 인증이 완료되었습니다.</span>}
            <Row className={state.isAuthPhone ? 'auth_num_form_wrapper show' : 'auth_num_form_wrapper'}>
                <Col className={'p-0'} xs={8}>
                    <Input type="text" name="userAuthNum" placeholder="인증번호"/>
                </Col>
                <Col className={'p-0'} xs={4}>
                    <button type={'button'} onClick={handleClickVerifyPhone}>인증번호 등록</button>
                </Col>
            </Row>

        </div>
    )
}

export default AuthPhoneForm