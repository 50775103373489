import {atom} from 'recoil'
import {ProjectResponse} from "../../model/response/ProjectResponse";
import {WorkResponse} from "../../model/response/WorkResponse";
import {LatLng} from "../../model/LatLng";
import {Timestamp} from "firebase/firestore";
import {WorkRecordResponse} from "../../model/response/WorkRecordResponse";
import {UpdateWorkInfoRequest} from "../../model/request/UpdateWorkInfoRequest";
import {v1} from 'uuid'

export const defaultWorkModel = {
    workId: '',
    projectId: '',
    address: '',
    farmerName: '',
    farmerPhone: '',
    workerId: '',
    worker: '',
    workType: '',
    workStatus: '',
    request: '',
    latLng: {
        latitude: 0,
        longitude: 0
    },
    area: 0,
    polygon: [],
    uploadFiles: [],
    created: Timestamp.now()
}

export interface ISearchWork {
    address: string
    polygon: LatLng[]
    latLng: LatLng
}

interface IState {
    projectList: ProjectResponse[],
    tabNum: number,
    leftTabNum: number,
    showRightPolygonDetail: boolean,
    showRightPolygonSearch: boolean,
    showWorkStatusChangeDialog: boolean,
    showWorkModifiedDialog: boolean,
    activeClickPolygon: boolean,
    showFilter: boolean,
    activeWorkMode: boolean,
    activeAssign: boolean,
    drawerPolygonData: WorkResponse,
    searchPolygonData: ISearchWork,
    projectWorkRecords: WorkRecordResponse[],
    updateWorkInfoForm: UpdateWorkInfoRequest
}

export const sidebarState = atom<IState>({
    key: `sidebarState/${v1()}`,
    default: {
        projectList: [],
        tabNum: 1,
        leftTabNum: 1,
        showRightPolygonDetail: false,
        showRightPolygonSearch: false,
        showWorkStatusChangeDialog: false,
        showWorkModifiedDialog: false,
        activeClickPolygon: false,
        showFilter: false,
        activeWorkMode: false,
        activeAssign: false,
        drawerPolygonData: defaultWorkModel,
        searchPolygonData: {
            address: '',
            polygon: [],
            latLng: {latitude: 0, longitude: 0}
        },
        projectWorkRecords: [],
        updateWorkInfoForm: {
            workId: '',
            farmerName: '',
            farmerPhone: '',
            request: ''
        }
    }
})

