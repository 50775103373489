import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ProjectWorkTab from "./ProjectWorkTab";
import ProjectMemberTab from "./ProjectMemberTab";
import ProjectSettingTab from "./ProjectSettingTab";
import ProjectStatisticsTab from "./ProjectStatisticsTab";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`project-tabpanel-${index}`}
            aria-labelledby={`project-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `project-tab-${index}`,
        'aria-controls': `project-tabpanel-${index}`,
    };
}

export default function ProjectDetailTab() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} onChange={handleChange} aria-label="project detail tabs">
                    <Tab style={{minWidth: '80px'}} label="작업" {...a11yProps(0)} />
                    <Tab style={{minWidth: '80px'}} label="팀원" {...a11yProps(1)} />
                    <Tab style={{minWidth: '80px'}} label="통계" {...a11yProps(2)} />
                    <Tab style={{minWidth: '80px'}} label="설정" {...a11yProps(3)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <ProjectWorkTab/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ProjectMemberTab/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ProjectStatisticsTab/>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <ProjectSettingTab/>
            </TabPanel>
        </Box>
    );
}
