import React from "react"
import ProjectStatCircle from "./ProjectStatCircle";
import {projectWorkCountState} from "../atoms";
import {useRecoilValue} from "recoil";
import LinearProgress, {LinearProgressProps} from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import completeIcon from '../../../assets/image/stat/complete.svg'
import progressIcon from '../../../assets/image/stat/progress.svg'
import expectedIcon from '../../../assets/image/stat/expected.svg'
import stopIcon from '../../../assets/image/stat/stop.svg'
import exceptIcon from '../../../assets/image/stat/except.svg'
import reworkIcon from '../../../assets/image/stat/rework.svg'
import WorkerStatsticsList from "./WorkerStatsticsList";


function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
            <Box sx={{width: '100%', mr: 1}}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{minWidth: 35}}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const ProjectStatisticsTab: React.FC = () => {

    const {
        totalCount,
        expectedCount,
        progressCount,
        completeCount,
        stopCount,
        exceptCount,
        reworkCount,
        totalArea,
        projectStat
    } = useRecoilValue(projectWorkCountState);

    return (
        <div className={'project_statistics_tab_main project_tab_main'}>
            <ProjectStatCircle percentage={completeCount === 0 ? 0 : Math.round(completeCount / totalCount * 100)}/>
            <div className={'total_stat_box'}>
                <div className={'total_count'}>
                    <span>총 {totalCount}</span>
                    <span>개</span>
                </div>
                <div className={'total_area'}>
                    <span>총 {totalArea.toFixed(2)}</span>
                    <span>평</span>
                </div>
            </div>
            <div className={'project_stat_count_main'}>
                <div>
                    <span className={'stat_title'}>프로젝트 통계</span>
                    <div className={'stat_progress_item'}>
                        <img src={expectedIcon} alt={'stat_icon'}/>
                        <div className={'progress_info_bar'}>
                            <div className={'flex_row_between w_full'}>
                                <p>예정 <span>{expectedCount + reworkCount}개</span></p>
                                <p>
                                    <span>{projectStat.expected.area <= 0 ? 0 : (projectStat.expected.area + projectStat.rework.area).toFixed(0)}평</span>
                                </p>
                            </div>
                            <LinearProgressWithLabel
                                value={expectedCount === 0 ? 0 : expectedCount / totalCount * 100}/>
                        </div>
                    </div>
                    <div className={'stat_progress_item'}>
                        <img src={progressIcon} alt={'stat_icon'}/>
                        <div className={'progress_info_bar'}>
                            <div className={'flex_row_between w_full'}>
                                <p>진행 <span>{progressCount}개</span></p>
                                <p>
                                    <span>{projectStat.progress.area <= 0 ? 0 : projectStat.progress.area.toFixed(0)}평</span>
                                </p>
                            </div>
                            <LinearProgressWithLabel
                                value={progressCount === 0 ? 0 : progressCount / totalCount * 100}/>
                        </div>
                    </div>
                    <div className={'stat_progress_item'}>
                        <img src={completeIcon} alt={'stat_icon'}/>
                        <div className={'progress_info_bar'}>
                            <div className={'flex_row_between w_full'}>
                                <p>완료 <span>{completeCount}개</span></p>
                                <p>
                                    <span>{projectStat.complete.area <= 0 ? 0 : projectStat.complete.area.toFixed(0)}평</span>
                                </p>
                            </div>
                            <LinearProgressWithLabel
                                value={completeCount === 0 ? 0 : completeCount / totalCount * 100}/>
                        </div>
                    </div>
                    <div className={'stat_progress_item'}>
                        <img src={stopIcon} alt={'stat_icon'}/>
                        <div className={'progress_info_bar'}>
                            <div className={'flex_row_between w_full'}>
                                <p>중단 <span>{stopCount}개</span></p>
                                <p><span>{projectStat.stop.area <= 0 ? 0 : projectStat.stop.area.toFixed(0)}평</span></p>
                            </div>
                            <LinearProgressWithLabel value={stopCount === 0 ? 0 : stopCount / totalCount * 100}/>
                        </div>
                    </div>
                    <div className={'stat_progress_item'}>
                        <img src={exceptIcon} alt={'stat_icon'}/>
                        <div className={'progress_info_bar'}>
                            <div className={'flex_row_between w_full'}>
                                <p>제외 <span>{exceptCount}개</span></p>
                                <p><span>{projectStat.except.area <= 0 ? 0 : projectStat.except.area.toFixed(0)}평</span>
                                </p>
                            </div>
                            <LinearProgressWithLabel value={exceptCount === 0 ? 0 : exceptCount / totalCount * 100}/>
                        </div>
                    </div>
                    <div className={'stat_progress_item'}>
                        <img src={reworkIcon} alt={'stat_icon'}/>
                        <div className={'progress_info_bar'}>
                            <div className={'flex_row_between w_full'}>
                                <p>재작업요청 <span>{reworkCount}개</span></p>
                                <p><span>{projectStat.rework.area <= 0 ? 0 : projectStat.rework.area.toFixed(0)}평</span>
                                </p>
                            </div>
                            <LinearProgressWithLabel value={reworkCount === 0 ? 0 : reworkCount / totalCount * 100}/>
                        </div>
                    </div>
                </div>
            </div>
            <WorkerStatsticsList/>
        </div>
    )
}

export default ProjectStatisticsTab