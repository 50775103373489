import React, {ReactNode, useState} from "react"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import classNames from "classnames";
import {Debounce} from "../project/Debounce";
import {notificationAlert} from "../../util/alert";
import {geocodeByAddress, getLatLng} from 'react-google-places-autocomplete';
import {polygonToAddress} from "../../repository/FunctionRepository";
import {isIterableArray} from "../../util/common";
import {ISearchWork, sidebarState} from "./atoms";
import {LatLng} from "../../model/LatLng";
import {getAddressLatLng} from "../../util/project";
import {useRecoilState, useRecoilValue} from "recoil";
import {mapState} from "../main/atoms";
import {projectState} from "../project/atoms";

interface IProps {
    children: ReactNode,
    title: string,
    useSearchBar: boolean
}

const SpaceBase: React.FC<IProps> = ({children, title, useSearchBar}) => {

    const [collapse, setCollapse] = useState<boolean>(false)
    const project = useRecoilValue(projectState)
    const [state, setState] = useRecoilState(sidebarState)
    const [map, setMap] = useRecoilState(mapState)
    const handleClickCollapse = () => {
        setCollapse(prevState => !prevState)
    }
    const handleSearchLocation = async (address: string) => {
        try {
            const latLng = await getAddressLatLng(address)

            if (latLng) {
                const polygonResult = await polygonToAddress(address, latLng)
                if (polygonResult) {
                    const locationLatLng = polygonResult.latLng;

                    if (locationLatLng) {
                        const alreadyWork = project.projectWorks.filter(work => (work.latLng.latitude === locationLatLng.latitude) && (work.latLng.longitude === locationLatLng.longitude))

                        if (isIterableArray(alreadyWork)) {
                            setState(old => ({...old, showRightPolygonDetail: true, drawerPolygonData: alreadyWork[0]}))
                            setMap(old => ({
                                ...old,
                                center: {lat: locationLatLng.latitude, lng: locationLatLng.longitude}
                            }))
                            return true
                        }
                    }

                    let newPolygon: LatLng[] = []
                    if (isIterableArray(polygonResult.polygon)) {
                        newPolygon = polygonResult.polygon.map(pl => ({latitude: pl[1], longitude: pl[0]}))
                    }

                    const polygonData: ISearchWork = {
                        address: address,
                        polygon: newPolygon,
                        latLng: locationLatLng
                    }
                    setState(old => ({
                        ...old,
                        showRightPolygonDetail: false,
                        showRightPolygonSearch: true,
                        searchPolygonData: polygonData
                    }))
                    setMap(old => ({...old, center: {lat: locationLatLng.latitude, lng: locationLatLng.longitude}}))
                }
            } else {
                const polygonResult = await polygonToAddress(address)
                if (polygonResult) {
                    const locationLatLng = polygonResult.latLng;

                    if (locationLatLng) {
                        const alreadyWork = project.projectWorks.filter(work => work.latLng === locationLatLng)
                        if (isIterableArray(alreadyWork)) {
                            setState(old => ({...old, showRightPolygonDetail: true, drawerPolygonData: alreadyWork[0]}))
                            setMap(old => ({
                                ...old,
                                center: {lat: locationLatLng.latitude, lng: locationLatLng.longitude}
                            }))
                            return true
                        }
                    }

                    let newPolygon: LatLng[] = []
                    if (isIterableArray(polygonResult.polygon)) {
                        newPolygon = polygonResult.polygon.map(pl => ({latitude: pl[1], longitude: pl[0]}))
                    }

                    const polygonData: ISearchWork = {
                        address: address,
                        polygon: newPolygon,
                        latLng: locationLatLng
                    }
                    setState(old => ({
                        ...old,
                        showRightPolygonDetail: false,
                        showRightPolygonSearch: true,
                        searchPolygonData: polygonData
                    }))
                    setMap(old => ({...old, center: {lat: locationLatLng.latitude, lng: locationLatLng.longitude}}))
                }
            }
            return true

        } catch (err: any) {
            if (err) {
                if (err.message) {
                    notificationAlert('오류', err.message)
                } else {
                    notificationAlert('오류', '등록이 불가능한 주소 입니다.');
                }
            }
        }


    }
    return (
        <div className={classNames({
            'space_base_main': true,
            'collapse_side_bar': collapse
        })}>
            {useSearchBar && <Debounce handleSearchLocation={handleSearchLocation} type={'searchBar'}/>}
            <h4 id={'space_base_title'}>{title}</h4>
            <div className={'space_base_content'}>
                {children}
            </div>
            <div className={'collapse_btn_main'}>
                <button onClick={handleClickCollapse}>
                    <ArrowBackIosIcon/>
                </button>
            </div>
        </div>
    )
}

export default SpaceBase