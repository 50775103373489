import React, {useEffect, useState} from "react";
import {Col, Row} from "reactstrap";
import {collection, getDocs, orderBy, query, where} from "firebase/firestore";
import {firestore} from "../../../firebase";
import {UserWorkRecordModal} from "./UserWorkRecordModal";
import moment from "moment";
import {ProjectWorkRecordModal} from "./ProjectWorkRecordModal";
import {isIterableArray} from "../../../util/common";
import {notificationAlert} from "../../../util/alert";
import {MapLoader} from "../MapLoader";


const WorkRecordAdmin = () => {

    const [recordType, setRecordType] = useState('user');
    const [loader, setLoader] = useState(false);
    const [users, setUsers] = useState([]);
    const [projects, setProjects] = useState([]);
    const [selectUser, setSelectUser] = useState({});
    const [selectProject, setSelectProject] = useState({});
    const [userModal, setUserModal] = useState(false);
    const [projectModal, setProjectModal] = useState(false);
    const userToggle = (user) => {
        setSelectUser(user);
        setUserModal(prevState => !prevState);
    }

    const projectToggle = (project) => {
        setSelectProject(project);
        setProjectModal(prevState => !prevState);
    }


    const getUsers = async () => {
        const q = query(collection(firestore, "users"), orderBy("expireDate", "desc"));
        const userDocs = await getDocs(q);
        return userDocs.docs.map(doc => ({id: doc.id, ...doc.data()}));
    }
    const getProjects = async () => {
        const q = query(collection(firestore, "projects"), where("enabled", "==", true));
        const userDocs = await getDocs(q);
        return userDocs.docs.map(doc => ({id: doc.id, ...doc.data()}));
    }

    useEffect(() => {
        setLoader(true);
        if (recordType === 'user') {
            getUsers().then(users => {
                if (isIterableArray(users)) {
                    setUsers(users);
                    setLoader(false);
                }
            }).catch(err => {
                if (err) {
                    notificationAlert('오류','유저 작업 목록을 가져오는데 문제가 발생하였습니다.')
                    setLoader(false);
                }
            })
        } else {
            getProjects().then(projectResult => {
                if (isIterableArray(projectResult)) {
                    setProjects(projectResult);
                    setLoader(false);
                }
            })
        }
    }, [recordType])

    return (
        <>
            {loader ? <MapLoader/> :
                <div className={'work_record_admin_wrapper'}>
                    <Row className={'type_search_wrapper m-0'}>
                        <Col className={'p-0'} xs={12}>
                            <div className={'record_type_wrapper'}>
                                <button
                                    onClick={() => setRecordType('user')}
                                    className={recordType === 'user' ? 'active' : ''}>유저
                                </button>
                                <button
                                    onClick={() => setRecordType('project')}
                                    className={recordType === 'project' ? 'active' : ''}>프로젝트
                                </button>
                            </div>
                        </Col>
                        {/*<Col xs={11} className='record_search_wrapper'>*/}
                        {/*    <input*/}
                        {/*        style={{padding: '0 0.4rem'}}*/}
                        {/*        // onChange={event => handleSearchUser(event.target.value)}*/}
                        {/*        type={'text'}/>*/}
                        {/*</Col>*/}

                        <Col className={'p-0'} xs={12}>
                            <div className={'record_list_wrapper'}>
                                {recordType === 'user' ?
                                    <div>
                                        {users.map((userData, index) => {
                                            return (
                                                <Row key={index} className={'record_user_item m-0'}>
                                                    <Col xs={8}>
                                                        <span className={'d-block fw-bold'}> {userData.name}</span>
                                                        <small> {`${userData.phone} | ${userData.userType}`}</small>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <button onClick={() => userToggle(userData)}>작업 기록</button>
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                        }

                                    </div>
                                    :
                                    <div className={'record_list_wrapper'}>
                                        {projects.map((projectData, index) => {
                                            return (
                                                <Row key={index} className={'record_user_item m-0'}>
                                                    <Col xs={8}>
                                                        <span className={'d-block fw-bold'}> {projectData.name}</span>
                                                        <small> {`${moment.unix(projectData?.created?.seconds).format('YYYY-MM-DD')} | ${projectData?.memberIds.length} 명`}</small>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <button onClick={() => projectToggle(projectData)}>작업 기록
                                                        </button>
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                        }
                                    </div>

                                }
                            </div>
                        </Col>
                    </Row>
                    <ProjectWorkRecordModal toggle={projectToggle} modal={projectModal} project={selectProject}/>
                    <UserWorkRecordModal user={selectUser} modal={userModal} toggle={userToggle}/>
                </div>
            }
        </>
    )
}

export default WorkRecordAdmin