import React from 'react'
import {useRecoilState} from "recoil";
import {projectState} from "../atoms";
import ProjectWorkBox from "./ProjectWorkBox";
import {sidebarState} from "../../sidebar/atoms";
import {isIterableArray} from "../../../util/common";
import classNames from "classnames";


const ProjectWorkTab: React.FC = () => {

    const [state, setState] = useRecoilState(projectState)
    const [sidebar, setSidebar] = useRecoilState(sidebarState)
    const handleOpenExcelAddWorkDialog = () => {
        setState(old => ({...old, showAddExcelDialog: !old.showAddExcelDialog}))
    }

    const handleOpenAddProjectWorkDialog = () => {
        if (sidebar.showRightPolygonSearch) {
            setSidebar(old => ({
                ...old, showRightPolygonSearch: false
                , searchPolygonData: {
                    address: '',
                    polygon: [],
                    latLng: {
                        latitude: 0,
                        longitude: 0
                    }
                }
            }))
        }
        setState(old => ({...old, showAddProjectWorkDialog: !old.showAddProjectWorkDialog}))
    }

    const handleClickToggleClickPolygon = () => {
        setSidebar(old => ({...old, activeClickPolygon: !old.activeClickPolygon}))
    }

    return (
        <div className={'project_work_tab_main project_tab_main'}>
            <div className={classNames({
                'click_polygon_btn_wrapper': true,
                'active': sidebar.activeClickPolygon
            })}>
                <button onClick={handleClickToggleClickPolygon}>
                    {sidebar.activeClickPolygon ? '지도 작업추가 비활성화' : '지도 작업추가 활성화'}
                </button>
            </div>
            <div className={'work_add_btn_main flex_row_between'}>
                <button onClick={handleOpenExcelAddWorkDialog}>엑셀로 작업추가</button>
                <button onClick={handleOpenAddProjectWorkDialog}>직접 작업추가</button>
            </div>
            {isIterableArray(state.filterProjectWorks) ?
                <ul>
                    {state.filterProjectWorks.map((work, index) => {
                        return (
                            <li style={{cursor: 'pointer'}} key={index}>
                                <ProjectWorkBox work={work}/>
                            </li>
                        )
                    })}
                </ul>
                :
                <div className={'not_exist_work_list'}>
                    <span>작업이 존재하지 않습니다.</span>
                </div>
            }
        </div>
    )
}


export default ProjectWorkTab